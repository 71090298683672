import { BiodirectoryResearchSite, ResearchCenter, ResearchSite, Country, Pathogen, BiodirectoryReagent, BiodirectorySpecimen, BiodirectoryCombinedEntity, BiodirectoryContact, BiodirectorySourceType} from "../../biodirectory-form/scripts/biodirectory-form.objects";
import { IFilter, InitializeFilterDropdown } from '../../../../shared/scripts/dropdown-functions';
import { GetSelectedDropdownValues, SelectDropdownOption } from '../../../../shared/scripts/filtering';
import { plainToClass } from "class-transformer";
import { Unique } from "../../../../shared/scripts/array-functions";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../../shared/scripts/table-column-visibility";
import { FormatDate2 } from './../../../../shared/scripts/date-functions';
import { DoArraysOverlap } from '../../../../shared/scripts/array-functions';
import { BiodirectoryModal } from './biodirectory-dashboard.modal';

declare var $: any;
export class BiodirectoryDashboardPage implements IFilter {
    researchSiteList: BiodirectoryCombinedEntity[];
    pathogenList: Pathogen[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    selectedPathogenIds: string[] = [];
    modalController: BiodirectoryModal;

    constructor(sitesJson: any, pathogensJson: any) {
        $(() => {
            this.pathogenList = plainToClass(Pathogen, <Pathogen[]>pathogensJson);
            this.pathogenList.forEach(pathogen => pathogen.initialize());
            this.researchSiteList = plainToClass(BiodirectoryCombinedEntity, <BiodirectoryCombinedEntity[]>sitesJson);

            this.researchSiteList.forEach(site => {
                let siteSpecimens: BiodirectorySpecimen[] = [];
                site.BiodirectorySpecimens.forEach(specimen => {
                    let newSpecimen = BiodirectorySpecimen.createBiodirectorySpecimenFromObj(specimen);
                    siteSpecimens.push(newSpecimen);
                });
                site.BiodirectorySpecimens = siteSpecimens;

                let siteReagents: BiodirectoryReagent[] = [];
                site.BiodirectoryReagents.forEach(reagent => {
                    let newReagent = BiodirectoryReagent.createBiodirectoryReagentFromObj(reagent);
                    siteReagents.push(newReagent);
                });
                site.BiodirectoryReagents = siteReagents;
            })

            this.researchSiteList.forEach(site => {
                site.initialize();
                site.setPathogenString(this.pathogenList);
            });

            this.initializeFilters();
            this.initializeTable();
            this.modalController = new BiodirectoryModal(this.pathogenList);

        });
    }

    initializeFilters(): void {
        // Site Overview
        InitializeFilterDropdown("#site-rc-dropdown", Unique(this.researchSiteList.map(site => site.ResearchCenter.Name), true), this);
        InitializeFilterDropdown("#site-site-dropdown", Unique(this.researchSiteList.map(site => site.ResearchSite.Name), true), this);
        InitializeFilterDropdown("#site-country-dropdown", Unique(this.researchSiteList.map(site => site.Country.Name), true), this);
        InitializeFilterDropdown("#site-region-dropdown", Unique(this.researchSiteList.map(site => site.Country.Region), true), this);

        // Specimens + Reagents + Pathogens List Generation
        let pathogenIds: string[] = [];
        let specimenSources: string[] = [];
        let specimenTypes: string[] = [];
        let reagentTypes: string[] = [];
        let reagentSources: string[] = [];
        this.researchSiteList.forEach(site => {
            site.BiodirectorySpecimens.forEach(specimen => {
                if (!specimenSources.includes(specimen.SpecimenSource)) specimenSources.push(specimen.SpecimenSource);
                if (!specimenTypes.includes(specimen.SpecimenType)) specimenTypes.push(specimen.SpecimenType);
                specimen.PathogenIdArray.forEach(pathogenId => {
                    if (!pathogenIds.includes(pathogenId)) pathogenIds.push(pathogenId);
                })
            });

            site.BiodirectoryReagents.forEach(reagent => {
                if (!reagentSources.includes(reagent.ReagentType)) reagentSources.push(reagent.ReagentType);
                if (!reagentTypes.includes(reagent.ReagentSource)) reagentTypes.push(reagent.ReagentSource);
                reagent.PathogenIdArray.forEach(pathogenId => {
                    if (!pathogenIds.includes(pathogenId)) pathogenIds.push(pathogenId);
                })
            })
        });
        specimenSources.sort();
        specimenTypes.sort();
        reagentSources.sort();
        reagentTypes.sort();


        let foundPathogenList: Pathogen[] = [];
        pathogenIds.forEach(id => {
            let numId = parseInt(id);
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == numId);
            if (foundPathogen != undefined) {
                foundPathogenList.push(foundPathogen);
            }
        });
        foundPathogenList.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));

        let displayPathogenArray: any[] = [];
        foundPathogenList.forEach(pathogen => {
            let newDisplay = [pathogen.PathogenId.toString(), pathogen.VirusName];
            displayPathogenArray.push(newDisplay);
        })

       
        // Specimens
        InitializeFilterDropdown("#specimen-source-dropdown", specimenSources, this, undefined, false);
        InitializeFilterDropdown("#specimen-type-dropdown", specimenTypes, this, undefined, false);
        InitializeFilterDropdown("#specimen-pathogen-dropdown", [], this, displayPathogenArray, true);

        // Reagents
        InitializeFilterDropdown("#reagent-type-dropdown", reagentTypes, this, undefined, false);
        InitializeFilterDropdown("#reagent-source-dropdown", reagentSources, this, undefined, false);
        InitializeFilterDropdown("#reagent-pathogen-dropdown", [], this, displayPathogenArray, true);

        this.initializeFilterCarryOver();
    }

    initializeFilterCarryOver(): void {
        // Change specimen Pathogen Dropdown
        $("#specimen-pathogen-dropdown").on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            // Get Searched Pathogen Ids
            let searchedPathogenIds = GetSelectedDropdownValues("#specimen-pathogen-dropdown");
            this.selectedPathogenIds = searchedPathogenIds;
            // set the value for other pathogen dropdowns
            $("#reagent-pathogen-dropdown").val(this.selectedPathogenIds);
            $("#reagent-pathogen-dropdown").selectpicker("refresh");

            this.triggerFilter();
        });

        // Change reagent Pathogen Dropdown
        $("#reagent-pathogen-dropdown").on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            // Get Searched Pathogen Ids
            let searchedPathogenIds = GetSelectedDropdownValues("#reagent-pathogen-dropdown");
            this.selectedPathogenIds = searchedPathogenIds;

            // set the value for other pathogen dropdowns
            $("#specimen-pathogen-dropdown").val(this.selectedPathogenIds);
            $("#specimen-pathogen-dropdown").selectpicker("refresh");

            this.triggerFilter();
        });

        this.selectedPathogenIds = GetSelectedDropdownValues("#specimen-pathogen-dropdown");
    }

    initializeTabButtons(): void {
        $("#site-overview-tab").on('click', () => {
            $("#column-filter-dropdown").val(["Site Overview"]);
            $("#column-filter-dropdown").trigger("change");
        });

        $("#specimens-tab").on('click', () => {
            $("#column-filter-dropdown").val(["Specimen Information"]);
            $("#column-filter-dropdown").trigger("change");
        });

        $("#reagents-tab").on('click', () => {
            $("#column-filter-dropdown").val(["Reagent Information"]);
            $("#column-filter-dropdown").trigger("change");
        });
    }

    // Update Table when Filters are changed
    triggerFilter(): void {
        let filteredSites: BiodirectoryCombinedEntity[] = this.researchSiteList;

        // Site Overview
        filteredSites = filteredSites.filter(site => DoArraysOverlap([site.ResearchCenter.Name], GetSelectedDropdownValues("#site-rc-dropdown")));
        filteredSites = filteredSites.filter(site => DoArraysOverlap([site.ResearchSite.Name], GetSelectedDropdownValues("#site-site-dropdown")));
        filteredSites = filteredSites.filter(site => DoArraysOverlap([site.Country.Name], GetSelectedDropdownValues("#site-country-dropdown")));
        filteredSites = filteredSites.filter(site => DoArraysOverlap([site.Country.Region], GetSelectedDropdownValues("#site-region-dropdown")));

        // Specimens
        let selectedSpecimenSources: string[] = GetSelectedDropdownValues("#specimen-source-dropdown")
        if (selectedSpecimenSources.length > 0) {
            filteredSites = filteredSites.filter(site => {
                let sources = site.SpecimenSourceString.split(", ");
                return DoArraysOverlap(sources, GetSelectedDropdownValues("#specimen-source-dropdown"));
            });
        }
        let selectedSpecimenTypes: string[] = GetSelectedDropdownValues("#specimen-type-dropdown");
        if (selectedSpecimenTypes.length > 0) {
            filteredSites = filteredSites.filter(site => {
                let types = site.SpecimenTypeString.split(", ");
                return DoArraysOverlap(types, GetSelectedDropdownValues("#specimen-type-dropdown"));
            });
        }
       

        // Reagents 
        let selectedReagentTypes: string[] = GetSelectedDropdownValues("#reagent-type-dropdown");
        if (selectedReagentTypes.length > 0) {
            filteredSites = filteredSites.filter(site => {
                let types = site.ReagentTypeString.split(", ");
                return DoArraysOverlap(types, GetSelectedDropdownValues("#reagent-type-dropdown"));
            });
        }
        let selectedReagentSources: string[] = GetSelectedDropdownValues("#reagent-source-dropdown");
        if (selectedReagentSources.length > 0) {
            filteredSites = filteredSites.filter(site => {
                let sources = site.ReagentSourceString.split(", ");
                return DoArraysOverlap(sources, GetSelectedDropdownValues("#reagent-source-dropdown"));
            });
        }
        
        // Pathogens
        filteredSites = filteredSites.filter(site => {
            let pathogenIds = [];
            let specimenPathogens = site.BiodirectorySpecimens.map(specimen => specimen.PathogenIdArray);
            specimenPathogens.forEach(idArray => pathogenIds.push(...idArray));
            let reagentPathogens = site.BiodirectoryReagents.map(reagent => reagent.PathogenIdArray);
            reagentPathogens.forEach(idArray => pathogenIds.push(...idArray));
            pathogenIds = Unique(pathogenIds, false);

            return DoArraysOverlap(pathogenIds, this.selectedPathogenIds);
        });

        this.updateTable(filteredSites);
    }

    updateTable(matchingSites: BiodirectoryCombinedEntity[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingSites).draw();
        this.setSitesFoundLabel(matchingSites.length);
    }

    resetFilters(): void {
        let filterList: string[] = [
            "#site-rc-dropdown", "#site-site-dropdown", "#site-country-dropdown", "#site-region-dropdown",
            "#specimen-pathogen-dropdown", "#reagent-pathogen-dropdown"
        ]

        let emptyFilterList: string[] = [
            "#specimen-source-dropdown", "#specimen-type-dropdown",
            "#reagent-type-dropdown", "#reagent-source-dropdown"
        ]

        filterList.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        emptyFilterList.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('deselectAll');
            $(dropdownId).selectpicker('refresh');
        });
    }

    modalResetFilters(): void {
        let filterList: string[] = [
            "#modal-pathogens-dropdown"
        ]

        let emptyFilterList: string[] = [
            "#modal-specimen-source-dropdown", "#modal-specimen-type-dropdown",
            "#modal-reagent-type-dropdown", "#modal-reagent-source-dropdown"
        ]

        filterList.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        emptyFilterList.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('deselectAll');
            $(dropdownId).selectpicker('refresh');
        });
    }

    initializeTable(): void {

        this.dataTable = $('#biodirectory-table').DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            //"dom": '<"top-controls"<"left-section"<"search-bar"f>><"right-section"<"column-select">B>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            //responsive: false,
            scrollY: '80vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 1
            },
            language:
            {
                search: "Search",
            },
            columns: [
                { data: "DisplaySiteName", title: "Site Name", className: "text-left font-size12" },
                { data: "ResearchCenter.Name", title: "Research Center", className: "site-overview text-left font-size12" },
                { data: "Country.Name", title: "Country", className: "site-overview text-left font-size12" },
                { data: "Country.Region", title: "Region", className: "site-overview text-left font-size12" },

                { data: "SpecimenSourceString", title: "Specimen Sources", className: "specimens text-left font-size12" },
                { data: "SpecimenTypeString", title: "Specimen Types", className: "specimens text-left font-size12" },
                { data: "PathogenString", title: "Pathogens", className: "specimens text-left font-size12" },

                { data: "ReagentTypeString", title: "Reagent Types", className: "reagents text-left font-size12" },
                { data: "ReagentSourceString", title: "Reagent Sources", className: "reagents text-left font-size12" },
                { data: "PathogenString", title: "Pathogens", className: "reagents text-left font-size12" },

                { data: "BiodirectoryResearchSite", title: "Specimen POC", className: "poc-info text-left font-size12", render: function (data, type) { return `${data.SpecimenPOCFirstName} ${data.SpecimenPOCLastName} (${data.SpecimenPOCEmail})`} },
                { data: "BiodirectoryResearchSite", title: "Reagent POC", className: "poc-info text-left font-size12", render: function (data, type) { return `${data.ReagentPOCFirstName} ${data.ReagentPOCLastName} (${data.ReagentPOCEmail})` } },

                { data: "BiodirectoryResearchSite.MoreRecentDateDisplay", title: "Last Updated/Reviewed", className: "text-left font-size12 nowrap"}
            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                }
            ],
            data: this.researchSiteList,
            //order: [[5, 'desc'], [1, 'asc']],
            initComplete: (settings, json) => {
                //$("#hub-details-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3');

        $(".spacer").addClass('col-12 col-md-3'); 0
        $(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle mt-2 px-0');
        //$(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"sites-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController('#biodirectory-table', '#column-select', [
            new TableColumnGroup("Site Overview", true, [], "site-overview"),
            new TableColumnGroup("Specimen Information", false, [], "specimens"),
            new TableColumnGroup("Reagent Information", false, [], "reagents"),
            new TableColumnGroup("Point of Contact", false, [], "poc-info")
        ]);

        $('#biodirectory-table').on('search.dt', (e, settings) => {
            this.setSitesFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeTabButtons();
    }

    setSitesFoundLabel(count: number): void {
        switch (count) {
            case 0: $('#sites-found-count').text("No Sites Found"); break;
            case 1: $('#sites-found-count').text("1 Site Found"); break;
            default: $('#sites-found-count').text(count + " Sites Found");
        }
    }

    openSiteModal(siteId: number): void {
        let site: BiodirectoryCombinedEntity = this.researchSiteList.find(rs => rs.Id == siteId);
        if (site != undefined) {
            this.modalController.prepopulateModal(site);
            $("#siteModal").modal('show');
        }
    }

    showHelpModal(): void {
        $("#helpModal").modal('show');
    }
    
}