

export class EventEntry {

    Title: string;
    SortOrder: number;
    EventType: string;
    EventDate: string;
    Sponsor: string;
    Details: string;
    Language: string;
    AccessInfo: string;

    AccessInfoContent: string = "";
    SponsorFilter: string = "";

    constructor() { }

    initialize(): void {

        if (this.AccessInfo != undefined) {
            if (this.AccessInfo.startsWith("http")) {
                this.AccessInfoContent = "<a href='" + this.AccessInfo + "' target='_blank'><span class='nowrap'>Details<i class='ml-2 fa fa-external-link-alt link-icon' aria-hidden='true'></i></span></a>";
            }
            else if (this.AccessInfo.includes("@")) {
                this.AccessInfoContent = "<a href='mailto:" + this.AccessInfo + "'><span class='nowrap'>More Info<i class='ml-2 fa fa-envelope link-icon' aria-hidden='true'></i></span></a>";
            }
        }

        this.EventDate = "<span class='nowrap'>" + this.EventDate + "</span>";
        this.SponsorFilter = this.Sponsor.split("(").join("").split(")").join("");
    }
}