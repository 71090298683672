



export function InitializeFilterDropdown(selectId: string, values: string[], tableFilter: IFilter, compoundValues: any[] = undefined, selected: boolean = true): void {
    $(selectId).selectpicker({
        dropupAuto: false,
        selectedTextFormat: "count",

        countSelectedText: (numSelected, numTotal) => {
            if (numSelected == numTotal) {
                return "All";
            }
            else {
                return numSelected + " Selected";
            }
        }
    });

    if (compoundValues == undefined) {
        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, selected));
        });
    }
    else {
        compoundValues.forEach((element: any[]) => {
            $(selectId).append(new Option(element[1], element[0], false, selected));
        });
    }

    $(selectId).selectpicker("refresh");

    $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        tableFilter.triggerFilter();
    });
}

export function GetSelectedDropdownValues(selectId: string): any {
    return $(selectId).val();
}

export interface IFilter {
    triggerFilter(): void;
}