

export class UserExperienceEntry {
    Email: string;
    ExperienceJson: string;
    constructor() { }
}

export class UserExperience {

    TrainingDegrees: string[];
    TrainingDegreesOther: string;
    TrainingExperience: string[];
    TrainingExperienceOther: string;
    PathogenFamilies: string[];
    PathogenFamiliesOther: string;
    OtherPathogens: string[];
    OtherPathogensOther: string;
    StudyTypes: string[];
    DataScience: string[];
    AssayDevelopment: string[];
    AssayDevelopmentOther: string;
    ContainmentLab: string[];
    UsRegulations: string[];
    MinionAnalysis: string[];
    RawShortReadAnalysis: string[];
    StatisticalSequenceAnalysis: string[];
    OtherExpertise: string;

    constructor() {}

    initialize(): void {

        $("#expertise-form :input").each((index, element) => {
            let input: any = $(element);
            let inputId: string = input.attr("id");
            //console.log(input.attr("id"), input.is(":checked"));

            if (inputId && inputId.startsWith("text") && input.val() != "") {
                if (inputId == "textDegreeOther") { this.TrainingDegreesOther = input.val(); }
                else if (inputId == "textExperienceOther") { this.TrainingExperienceOther = input.val(); }
                else if (inputId == "textPathogenOther") { this.PathogenFamiliesOther = input.val(); }
                else if (inputId == "textOtherPathogenOther") { this.OtherPathogensOther = input.val(); }
                else if (inputId == "textAssayDevelopmentOther") { this.AssayDevelopmentOther = input.val(); }
                else if (inputId == "textOtherExpertise") { this.OtherExpertise = input.val(); }
            }
            else if (input.is(":checked")) {

                if (inputId.startsWith("degree")) {
                    if (!this.TrainingDegrees) this.TrainingDegrees = [];
                    this.TrainingDegrees.push(inputId);
                }
                else if (inputId.startsWith("experience")) {
                    if (!this.TrainingExperience) this.TrainingExperience = [];
                    this.TrainingExperience.push(inputId);
                }
                else if (inputId.startsWith("pathogen")) {
                    if (!this.PathogenFamilies) this.PathogenFamilies = [];
                    this.PathogenFamilies.push(inputId);
                }
                else if (inputId.startsWith("otherpathogen")) {
                    if (!this.OtherPathogens) this.OtherPathogens = [];
                    this.OtherPathogens.push(inputId);
                }
                else if (inputId.startsWith("studytype")) {
                    if (!this.StudyTypes) this.StudyTypes = [];
                    this.StudyTypes.push(inputId);
                }
                else if (inputId.startsWith("datascience")) {
                    if (!this.DataScience) this.DataScience = [];
                    this.DataScience.push(inputId);
                }
                else if (inputId.startsWith("assaydev")) {
                    if (!this.AssayDevelopment) this.AssayDevelopment = [];
                    this.AssayDevelopment.push(inputId);
                }
                else if (inputId.startsWith("contain")) {
                    if (!this.ContainmentLab) this.ContainmentLab = [];
                    this.ContainmentLab.push(inputId);
                }
                else if (inputId.startsWith("regulation")) {
                    if (!this.UsRegulations) this.UsRegulations = [];
                    this.UsRegulations.push(inputId);
                }
                else if (inputId.startsWith("minion")) {
                    if (!this.MinionAnalysis) this.MinionAnalysis = [];
                    this.MinionAnalysis.push(inputId);
                }
                else if (inputId.startsWith("rawshort")) {
                    if (!this.RawShortReadAnalysis) this.RawShortReadAnalysis = [];
                    this.RawShortReadAnalysis.push(inputId);
                }
                else if (inputId.startsWith("statsequence")) {
                    if (!this.StatisticalSequenceAnalysis) this.StatisticalSequenceAnalysis = [];
                    this.StatisticalSequenceAnalysis.push(inputId);
                }

                //console.log(input.attr("id"));
            }
        });
    }

    setupUI(): void {

        let textfields: string[] = [ "TrainingDegreesOther", "TrainingExperienceOther", "PathogenFamiliesOther", "OtherPathogensOther", "AssayDevelopmentOther", "OtherExpertise" ];
        let switches: string[] = ["TrainingDegrees", "TrainingExperience", "PathogenFamilies", "OtherPathogens", "StudyTypes", "DataScience", "AssayDevelopment", "ContainmentLab", "UsRegulations", "MinionAnalysis", "RawShortReadAnalysis","StatisticalSequenceAnalysis" ];

        textfields.forEach((field: string) => {
            if (this[field] != undefined) {
                if (field == "TrainingDegreesOther") { $("#textDegreeOther").val(this[field]); }
                else if (field == "TrainingExperienceOther") { $("#textExperienceOther").val(this[field]); }
                else if (field == "PathogenFamiliesOther") { $("#textPathogenOther").val(this[field]); }
                else if (field == "OtherPathogensOther") { $("#textOtherPathogenOther").val(this[field]); }
                else if (field == "AssayDevelopmentOther") { $("#textAssayDevelopmentOther").val(this[field]); }
                else if (field == "OtherExpertise") { $("#textOtherExpertise").val(this[field]); }
            }
        });

        switches.forEach((field: string) => {
            if (this[field] != undefined) {
                this[field].forEach((switchId: string) => {
                    $("#" + switchId).prop("checked", true);
                });
            }
        });
    }

    getTrainingDegreeString(): string {
        let output: string = "";
        if (this.TrainingDegrees == undefined && this.TrainingDegreesOther == undefined) return "";
        if (this.TrainingDegrees != undefined) {
            if (this.TrainingDegrees.includes("degree1")) output = this.addValue(output, "Bioinformatics Professional");
            if (this.TrainingDegrees.includes("degree2")) output = this.addValue(output, "Biosafety/Biosecurity Professional");
            if (this.TrainingDegrees.includes("degree3")) output = this.addValue(output, "Structural Biologist");
            if (this.TrainingDegrees.includes("degree4")) output = this.addValue(output, "Data Scientist/Computational Biologist");
            if (this.TrainingDegrees.includes("degree5")) output = this.addValue(output, "Ecologist");
            if (this.TrainingDegrees.includes("degree6")) output = this.addValue(output, "Entomologist");
            if (this.TrainingDegrees.includes("degree7")) output = this.addValue(output, "Epidemiologist");
            if (this.TrainingDegrees.includes("degree8")) output = this.addValue(output, "Geneticist");
            if (this.TrainingDegrees.includes("degree9")) output = this.addValue(output, "Immunologist");
            if (this.TrainingDegrees.includes("degree10")) output = this.addValue(output, "Legal/Agreements/Intellectual Property Professional");
            if (this.TrainingDegrees.includes("degree11")) output = this.addValue(output, "Medical Doctor");
            if (this.TrainingDegrees.includes("degree12")) output = this.addValue(output, "Microbiologist");
            if (this.TrainingDegrees.includes("degree13")) output = this.addValue(output, "Nurse");
            if (this.TrainingDegrees.includes("degree14")) output = this.addValue(output, "Public Health Professional");
            if (this.TrainingDegrees.includes("degree15")) output = this.addValue(output, "Statistical Geneticist");
            if (this.TrainingDegrees.includes("degree16")) output = this.addValue(output, "Statistician");
            if (this.TrainingDegrees.includes("degree17")) output = this.addValue(output, "Veterinarian");
            if (this.TrainingDegrees.includes("degree18")) output = this.addValue(output, "Virologist");
        }
        if (this.TrainingDegreesOther != undefined && this.TrainingDegreesOther.length > 0) output = this.addValue(output, this.TrainingDegreesOther);
        return output;
    }

    getTrainingExperienceString(): string {
        let output: string = "";
        if (this.TrainingExperience == undefined && this.TrainingExperienceOther == undefined) return "";
        if (this.TrainingExperience != undefined) {
            if (this.TrainingExperience.includes("experience1")) output = this.addValue(output, "Administrator or Project Director");
            if (this.TrainingExperience.includes("experience2")) output = this.addValue(output, "Data Manager");
            if (this.TrainingExperience.includes("experience3")) output = this.addValue(output, "Project/Program Manager");
            if (this.TrainingExperience.includes("experience4")) output = this.addValue(output, "Quality Assurance/Control Professional");
            if (this.TrainingExperience.includes("experience5")) output = this.addValue(output, "Technician");
        }
        if (this.TrainingExperienceOther != undefined && this.TrainingExperienceOther.length > 0) output = this.addValue(output, this.TrainingExperienceOther);
        return output;
    }

    getPathogenFamilyString(): string {
        let output: string = "";
        if (this.PathogenFamilies == undefined && this.PathogenFamiliesOther == undefined) return "";
        if (this.PathogenFamilies != undefined) {
            if (this.PathogenFamilies.includes("pathogen1")) output = this.addValue(output, "<i>Asfarviridae</i>");
            if (this.PathogenFamilies.includes("pathogen2")) output = this.addValue(output, "<i>Astroviridae</i>");
            if (this.PathogenFamilies.includes("pathogen3")) output = this.addValue(output, "<i>Birnaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen4")) output = this.addValue(output, "<i>Bunyaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen5")) output = this.addValue(output, "<i>Caliciviridae</i>");
            if (this.PathogenFamilies.includes("pathogen6")) output = this.addValue(output, "<i>Coronaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen7")) output = this.addValue(output, "<i>Filoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen8")) output = this.addValue(output, "<i>Flaviviridae</i>");
            if (this.PathogenFamilies.includes("pathogen9")) output = this.addValue(output, "<i>Herpesviridae</i>");
            if (this.PathogenFamilies.includes("pathogen10")) output = this.addValue(output, "<i>Matonaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen11")) output = this.addValue(output, "<i>Orthomyxoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen12")) output = this.addValue(output, "<i>Papillomaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen13")) output = this.addValue(output, "<i>Picornaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen14")) output = this.addValue(output, "<i>Paramyxoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen15")) output = this.addValue(output, "<i>Peribunyaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen16")) output = this.addValue(output, "<i>Pneumoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen17")) output = this.addValue(output, "<i>Poxviridae</i>");
            if (this.PathogenFamilies.includes("pathogen18")) output = this.addValue(output, "<i>Polyomaviridae</i>");
            if (this.PathogenFamilies.includes("pathogen19")) output = this.addValue(output, "<i>Reoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen20")) output = this.addValue(output, "<i>Retroviridae</i>");
            if (this.PathogenFamilies.includes("pathogen21")) output = this.addValue(output, "<i>Rhabdoviridae</i>");
            if (this.PathogenFamilies.includes("pathogen22")) output = this.addValue(output, "<i>Togaviridae</i>");
        }
        if (this.PathogenFamiliesOther != undefined && this.PathogenFamiliesOther.length > 0) output = this.addValue(output, this.PathogenFamiliesOther);
        return output;
    }

    getOtherPathogensString(): string {
        let output: string = "";
        if (this.OtherPathogens == undefined && this.OtherPathogensOther == undefined) return "";
        if (this.OtherPathogens != undefined) {
            if (this.OtherPathogens.includes("otherpathogen1")) output = this.addValue(output, "Bacteria");
            if (this.OtherPathogens.includes("otherpathogen2")) output = this.addValue(output, "Fungi");
            if (this.OtherPathogens.includes("otherpathogen3")) output = this.addValue(output, "Parasites");
            if (this.OtherPathogens.includes("otherpathogen4")) output = this.addValue(output, "Protozoa");
        }
        if (this.OtherPathogensOther != undefined && this.OtherPathogensOther.length > 0) output = this.addValue(output, this.OtherPathogensOther);
        return output;
    }

    getStudyTypesString(): string {
        let output: string = "";
        if (this.StudyTypes == undefined) return "";
        if (this.StudyTypes.includes("studytype1")) output = this.addValue(output, "Aquatic animals");
        if (this.StudyTypes.includes("studytype2")) output = this.addValue(output, "Humans (behavioral studies)");
        if (this.StudyTypes.includes("studytype3")) output = this.addValue(output, "Humans (clinical trials)");
        if (this.StudyTypes.includes("studytype4")) output = this.addValue(output, "Humans (serosurveys)");
        if (this.StudyTypes.includes("studytype5")) output = this.addValue(output, "Insects");
        if (this.StudyTypes.includes("studytype6")) output = this.addValue(output, "Livestock");
        if (this.StudyTypes.includes("studytype7")) output = this.addValue(output, "Wildlife");
        return output;
    }

    getDataScienceString(): string {
        let output: string = "";
        if (this.DataScience == undefined) return "";
        if (this.DataScience.includes("datascience1")) output = this.addValue(output, "Bioinformatics");
        if (this.DataScience.includes("datascience2")) output = this.addValue(output, "Biostatistics");
        if (this.DataScience.includes("datascience3")) output = this.addValue(output, "Epidemiological analysis");
        if (this.DataScience.includes("datascience4")) output = this.addValue(output, "Geospatial analyses");
        if (this.DataScience.includes("datascience5")) output = this.addValue(output, "Genomic/Sequence analysis");
        return output;
    }

    getAssayDevelopmentString(): string {
        let output: string = "";
        if (this.AssayDevelopment == undefined && this.AssayDevelopmentOther == undefined) return "";
        if (this.AssayDevelopment != undefined) {
            if (this.AssayDevelopment.includes("assaydev1")) output = this.addValue(output, "Antigen assay development and/or validation");
            if (this.AssayDevelopment.includes("assaydev2")) output = this.addValue(output, "Molecular assay development and/or validation");
            if (this.AssayDevelopment.includes("assaydev3")) output = this.addValue(output, "Serology assay development and/or validation");
        }
        if (this.AssayDevelopmentOther != undefined && this.AssayDevelopmentOther.length > 0) output = this.addValue(output, this.AssayDevelopmentOther);
        return output;
    }

    getContainmentLabString(): string {
        let output: string = "";
        if (this.ContainmentLab == undefined) return "";
        if (this.ContainmentLab.includes("contain1")) output = this.addValue(output, "BSL-2");
        if (this.ContainmentLab.includes("contain2")) output = this.addValue(output, "BSL-3");
        if (this.ContainmentLab.includes("contain3")) output = this.addValue(output, "BSL-4");
        return output;
    }

    getUsRegulationsString(): string {
        let output: string = "";
        if (this.UsRegulations == undefined) return "";
        if (this.UsRegulations.includes("regulation1")) output = this.addValue(output, "Select agent");
        if (this.UsRegulations.includes("regulation2")) output = this.addValue(output, "Dual Use Research of Concern (DURC)");
        if (this.UsRegulations.includes("regulation3")) output = this.addValue(output, "HHS P3CO Framework (potential pandemic pathogens)");
        return output;
    }

    getMinionAnalysisString(): string {
        let output: string = "";
        if (this.MinionAnalysis == undefined) return "";
        if (this.MinionAnalysis.includes("minion1")) output = this.addValue(output, "Basics of minION QC");
        if (this.MinionAnalysis.includes("minion2")) output = this.addValue(output, "Using CLC Genomics Workbench");
        if (this.MinionAnalysis.includes("minion3")) output = this.addValue(output, "Using NANOPACK");
        return output;
    }

    getRawShortReadAnalysisString(): string {
        let output: string = "";
        if (this.RawShortReadAnalysis == undefined) return "";
        if (this.RawShortReadAnalysis.includes("rawshort1")) output = this.addValue(output, "Demultiplexing, quality, and basics of short read QC");
        if (this.RawShortReadAnalysis.includes("rawshort2")) output = this.addValue(output, "Read trimming, read removal, and other QC topics");
        if (this.RawShortReadAnalysis.includes("rawshort3")) output = this.addValue(output, "Creating consensus sequences");
        if (this.RawShortReadAnalysis.includes("rawshort4")) output = this.addValue(output, "Aligning short reads to reference sequence (e.g. BWA)");
        if (this.RawShortReadAnalysis.includes("rawshort5")) output = this.addValue(output, "Variant calling (e.g., GATK, variant filtering, joint calling)");
        if (this.RawShortReadAnalysis.includes("rawshort6")) output = this.addValue(output, "De novo or reference-based genome assembly");
        if (this.RawShortReadAnalysis.includes("rawshort7")) output = this.addValue(output, "BLAST and taxon identification");
        if (this.RawShortReadAnalysis.includes("rawshort8")) output = this.addValue(output, "Annotating variant calls (e.g., identifying ORFs, functional regions)");
        if (this.RawShortReadAnalysis.includes("rawshort9")) output = this.addValue(output, "Phylogeny construction");
        if (this.RawShortReadAnalysis.includes("rawshort10")) output = this.addValue(output, "PacBio long read QC, haplotype construction");
        return output;
    }

    getStatisticalSequenceAnalysisString(): string {
        let output: string = "";
        if (this.StatisticalSequenceAnalysis == undefined) return "";
        if (this.StatisticalSequenceAnalysis.includes("statsequence1")) output = this.addValue(output, "Bulk RNAseq - differential expression (e.g., DESeq, dream, limma-voom)");
        if (this.StatisticalSequenceAnalysis.includes("statsequence2")) output = this.addValue(output, "Bulk RNAseq - mapping & quantification (e.g., RSEM, STAR, HISAT2)");
        if (this.StatisticalSequenceAnalysis.includes("statsequence3")) output = this.addValue(output, "Bulk RNAseq - pseudo-alignment (e.g., Salmon, Kallisto)");
        if (this.StatisticalSequenceAnalysis.includes("statsequence4")) output = this.addValue(output, "Metagenomics - ribosome subunit approaches (e.g., 16S, 18S)");
        if (this.StatisticalSequenceAnalysis.includes("statsequence5")) output = this.addValue(output, "Metagenomics - shotgun sequence approaches");
        if (this.StatisticalSequenceAnalysis.includes("statsequence6")) output = this.addValue(output, "Metagenomics - meta-transcriptomics approaches");
        if (this.StatisticalSequenceAnalysis.includes("statsequence7")) output = this.addValue(output, "Metagenomics - phylogenies & systematics");
        if (this.StatisticalSequenceAnalysis.includes("statsequence8")) output = this.addValue(output, "Statistical modeling - linear/logistic mixed models for genomics analysis");
        if (this.StatisticalSequenceAnalysis.includes("statsequence9")) output = this.addValue(output, "Enrichment testing - gene-based or SNP-based tests (e.g., GSEA, GO)");
        if (this.StatisticalSequenceAnalysis.includes("statsequence10")) output = this.addValue(output, "Phylogenetic analysis - ML approaches");
        if (this.StatisticalSequenceAnalysis.includes("statsequence11")) output = this.addValue(output, "Phylogenetic analysis - Bayesian approaches");
        if (this.StatisticalSequenceAnalysis.includes("statsequence12")) output = this.addValue(output, "Phylogenetic analysis - Phylodynamics & phylogeography");
        return output;
    }

    getHiddenFilter(): string {
        let output: string = "";
        output = this.getTrainingDegreeString() + " " + this.getTrainingExperienceString() + " " + this.getPathogenFamilyString() + " " + this.getOtherPathogensString() + " " + this.getStudyTypesString() + " " + this.getDataScienceString() + " " + this.getContainmentLabString() + " " + this.getUsRegulationsString() + " " + this.getMinionAnalysisString() + " " + this.getStatisticalSequenceAnalysisString() + " " + this.getRawShortReadAnalysisString();
        if (this.OtherExpertise != undefined) { output += " " + this.OtherExpertise; }
        return output;
    }

    addValue(output: string, value: string): string {
        if (output.length > 0) { output += ", "; }
        output += value;
        return output;
    }

    checkOtherEntries(): void {
        if (this.TrainingDegreesOther != undefined) { if (this.TrainingDegrees == undefined) { this.TrainingDegrees = []; } this.TrainingDegrees.push("degreeOther"); }
        if (this.TrainingExperienceOther != undefined) { if (this.TrainingExperience == undefined) { this.TrainingExperience = []; } this.TrainingExperience.push("experienceOther"); }
        if (this.PathogenFamiliesOther != undefined) { if (this.PathogenFamilies == undefined) { this.PathogenFamilies = []; } this.PathogenFamilies.push("pathogenOther"); }
        if (this.OtherPathogensOther != undefined) { if (this.OtherPathogens == undefined) { this.OtherPathogens = []; } this.OtherPathogens.push("otherpathogenOther"); }
        if (this.AssayDevelopmentOther != undefined) { if (this.AssayDevelopment == undefined) { this.AssayDevelopment = []; } this.AssayDevelopment.push("assaydevOther"); }
    }
}