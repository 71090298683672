import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { BiodirectoryContact } from '../scripts/biodirectory-form.objects';
import { plainToClass } from 'class-transformer';
export class ContactSelect implements IFilter {

    contactList: BiodirectoryContact[] = [];
    contactSelectId: string;

    contactBlock: JQuery<HTMLElement>;
    firstNameInput: JQuery<HTMLElement>;
    lastNameInput: JQuery<HTMLElement>;
    emailInput: JQuery<HTMLElement>;

    constructor(contactSelectId: string, contactList: BiodirectoryContact[], callbackFn: Function = () => { }) {

        this.contactList = contactList;
        this.contactSelectId = contactSelectId;
        this.contactBlock = $(this.contactSelectId).closest(".contact-selection-block");

        this.initializeContactDropdown();

        this.firstNameInput = this.contactBlock.find(".first-name-input");
        this.lastNameInput = this.contactBlock.find(".last-name-input");
        this.emailInput = this.contactBlock.find(".email-input");

        // optionally run a callback function after the selector is generated
        callbackFn();
       
    }

    initializeContactDropdown(): void {
        InitializeFilterDropdown(this.contactSelectId, [], this, this.contactList.map((contact: BiodirectoryContact) => [contact.DropdownDisplay, contact.Email]), false);

        /*let contactBlock = $(this.contactSelectId).closest(".contact-selection-block");*/
        this.contactBlock.find(".reset-contact-search-btn").on('click', () => {
            this.resetSearchBar();
        })

    }

    checkIfData(): boolean {
        if (this.firstNameInput.val() == "" && this.lastNameInput.val() == "" && this.emailInput.val() == "") {
            return false;
        }
        else {
            return true;
        }
    }

    contactValidation(): boolean {
        this.resetValidation();
        let isValid = true;

        if (this.firstNameInput.val() == "" || this.lastNameInput.val() == "" || this.emailInput.val() == "") {
            isValid = false;

            this.firstNameInput.val() == "" ? this.firstNameInput.addClass("is-invalid") : this.firstNameInput.addClass("is-valid");
            this.lastNameInput.val() == "" ? this.lastNameInput.addClass("is-invalid") : this.lastNameInput.addClass("is-valid");
            this.emailInput.val() == "" ? this.emailInput.addClass("is-invalid") : this.emailInput.addClass("is-valid");
            this.contactBlock.find(".contact-invalid-feedback").removeClass("d-none");
        }

        return isValid;

    }

    resetValidation(): void {

        this.firstNameInput.removeClass("is-valid"); this.firstNameInput.removeClass("is-invalid");
        this.lastNameInput.removeClass("is-valid"); this.lastNameInput.removeClass("is-invalid");
        this.emailInput.removeClass("is-valid"); this.emailInput.removeClass("is-invalid");
        this.contactBlock.find(".contact-invalid-feedback").addClass("d-none");
    }

    // Runs when the item is changed in the dropdown
    triggerFilter(): void {
        let searchValue = $(this.contactSelectId).val();

        if (searchValue == "" || searchValue == undefined) {
            this.resetValues();          
        }
        else {
            let foundContact: BiodirectoryContact = this.contactList.find(contact => contact.Email == searchValue);

            if (foundContact == undefined) {
                this.resetValues();
            }
            else {
                this.setValues(foundContact);
            }
        }

    }

    resetValues(): void {
        this.resetValidation();

        this.firstNameInput.val("");
        this.lastNameInput.val("");
        this.emailInput.val("");
    }

    setValues(contact: BiodirectoryContact): void {
        this.resetValidation();

        this.firstNameInput.val(contact.FirstName);
        this.lastNameInput.val(contact.LastName);
        this.emailInput.val(contact.Email);
    }

    getAddedContact(): BiodirectoryContact {
        let addedContact: BiodirectoryContact = new BiodirectoryContact(
            this.firstNameInput.val().toString(),
            this.lastNameInput.val().toString(),
            this.emailInput.val().toString(),
        );

        return addedContact;
    }

    resetSearchBar(): void {
        $(this.contactSelectId).val("").trigger("change");
    }

    

}
