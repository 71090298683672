import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { Unique } from '../../../../shared/scripts/array-functions';
import { BiodirectorySourceType, Pathogen, BiodirectoryCombinedEntity, BiodirectorySpecimen, BiodirectoryReagent, BiodirectoryResearchSite } from '../scripts/biodirectory-form.objects';
import { plainToClass } from 'class-transformer';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import { Path } from 'leaflet';
import { Site } from '../../../../general/network-map/scripts/entities';

declare var $: any;
export class BiodirectoryUpdateTool implements IFilter {
    pathogenList: Pathogen[] = [];
    familyList: string[] = [];

    // store information
    currentBiodirectorySite: BiodirectoryCombinedEntity = null;
    currentSpecimens: BiodirectorySpecimen[] = [];
    targetSpecimen: BiodirectorySpecimen = null;
    currentReagents: BiodirectoryReagent[] = [];
    targetReagent: BiodirectoryReagent = null;
    currentUse: string = null;

    // Source Selection Tool
    sourceSelectId: string;
    sourceList: BiodirectorySourceType[] = [];
    currentSourceIds: number[] = [];

    // Pathogen Search Tool
    searchedPathogen: Pathogen = null;
    searchBlock: JQuery<HTMLElement>;
    tableBlock: JQuery<HTMLElement>;
    formBlock: JQuery<HTMLElement>;
    addFamilyBtn: JQuery<HTMLElement>;
    addPathogenBtn: JQuery<HTMLElement>;
    pathogenSearchSelectId: string;
    pathogenFamilySelectId: string;
    pathogenNameSelectId: string;
    pathogenTableId: string;
    pathogenTable: any;
    addedPathogens: Pathogen[] = [];

    // Source <-> Pathogen Mapping Tool
    mappingTableId: string;
    mappingTable: any;
    mappingBlock: JQuery<HTMLElement>;

    constructor(use: string, sourceSelectId: string, pathogenSearchSelectId: string, pathogenFamilySelectId: string, pathogenNameSelectId: string, pathogenTableId: string, mappingTableId: string, sourceList: BiodirectorySourceType[], pathogenList: Pathogen[], callbackFn: Function = () => { }) {
        if (use == "specimen") {
            this.currentUse = "specimen";
        }
        if (use == "reagent") {
            this.currentUse = "reagent";
        }

        this.sourceSelectId = sourceSelectId;
        this.sourceList = sourceList;

        this.pathogenList = pathogenList.filter(pathogen => pathogen.VirusName != "NA"); // No Families
        this.pathogenList.forEach(pathogen => {
            if (!this.familyList.includes(pathogen.Family)) {
                this.familyList.push(pathogen.Family);
            }
        });
        this.familyList.sort();

        this.pathogenSearchSelectId = pathogenSearchSelectId;
        this.pathogenFamilySelectId = pathogenFamilySelectId;
        this.pathogenNameSelectId = pathogenNameSelectId;
        this.pathogenTableId = pathogenTableId;

        this.searchBlock = $(this.pathogenSearchSelectId).closest(".search-section-block");
        this.formBlock = $(this.pathogenFamilySelectId).closest(".add-contact-block");
        this.tableBlock = $(this.pathogenTableId).closest(".contact-block-table");

        this.mappingTableId = mappingTableId;
        this.mappingBlock = $(this.mappingTableId).closest(".mapping-block");

        this.initializeDropdowns();
        this.initializeBtns();
        this.initializePathogenTable();
        this.initializeMappingTable();
        this.initializeMappingSourceSelect()

        // optionally run a callback function after the selector is generated
        callbackFn();
       
    }

    initializeDropdowns(): void {
        // Source/Type Dropdown
        InitializeFilterDropdown(this.sourceSelectId, [], this, this.sourceList.map((sourceType: BiodirectorySourceType) => [sourceType.DisplayName, sourceType.SourceId]).sort((a: any[], b: any[]) => a[0] - b[0]), false);

    }

    initializeBtns(): void {
        this.addFamilyBtn = $(this.formBlock).find(".add-family-btn");
        this.addPathogenBtn = $(this.formBlock).find(".add-pathogen-btn");

        $(this.addFamilyBtn).on('click', () => {
            // Get Family
            let searchedFamily = GetSelectedDropdownValues(this.pathogenFamilySelectId);
            let foundFamily = this.familyList.find(family => family == searchedFamily);
            if (foundFamily == undefined) return;

            // Get Pathogens in Family
            let foundPathogens = this.pathogenList.filter(pathogen => pathogen.Family == foundFamily);
            foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));

            // Add Pathogens to List of Added Pathogens (no duplicates)
            foundPathogens.forEach(foundPathogen => {
                if (this.addedPathogens.find(pathogen => pathogen.PathogenId == foundPathogen.PathogenId) == undefined) {
                    this.addedPathogens.push(foundPathogen);
                }
            });

            // Update Added Pathogens Table
            this.updatePathogenTable();
            this.updatePathogenMappingTable();
        });

        $(this.addPathogenBtn).on('click', () => {
            // Get Pathogen
            let searchedPathogen = GetSelectedDropdownValues(this.pathogenNameSelectId);
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == searchedPathogen);
            if (foundPathogen == undefined) return;

            // Add Pathogen to List of Added Pathogens (no duplicates)
            if (this.addedPathogens.find(pathogen => pathogen.PathogenId == foundPathogen.PathogenId) == undefined) {
                this.addedPathogens.push(foundPathogen);
            }         

            // Update Added Pathogens Table
            this.updatePathogenTable();
            this.updatePathogenMappingTable();
        })

        // Search for Pathogen
        $(this.pathogenSearchSelectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            // Get Searched Pathogen (Id)
            let searchedPathogen = GetSelectedDropdownValues(this.pathogenSearchSelectId);
            
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == searchedPathogen);
            this.searchedPathogen = foundPathogen;
            if (foundPathogen != undefined) {
                // Set Pathogen Family Dropdown
                let foundFamily = this.familyList.find(family => family == foundPathogen.Family);
                if (foundFamily != undefined) {
                    $(this.pathogenFamilySelectId).val(foundFamily).trigger("change");
                }
            }
            else {
                // No Pathogen Selected, Reset Family
                $(this.pathogenFamilySelectId).val("");
                $(this.pathogenFamilySelectId).selectpicker("refresh").trigger("change");
            }

        });

        // Change Pathogen Family
        $(this.pathogenFamilySelectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            // Get Selected Family
            let searchedFamily = GetSelectedDropdownValues(this.pathogenFamilySelectId);

            // Clear existing options
            $(this.pathogenNameSelectId).empty();

            // Create Dropdown options for Pathogen Name
            let foundFamily = this.familyList.find(family => family == searchedFamily);
            if (foundFamily != undefined) {

                let foundPathogens = this.pathogenList.filter(pathogen => pathogen.Family == foundFamily);
                foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));

                foundPathogens.forEach(pathogen => {
                    $(this.pathogenNameSelectId).append(`<option value="${pathogen.PathogenId}">${pathogen.VirusName}</option>`)
                });

                $(this.addFamilyBtn).removeClass("disabled");
            }
            else {
                $(this.addFamilyBtn).addClass("disabled");
            }

            $(this.pathogenNameSelectId).selectpicker("refresh");
            // If there is a searched pathogen, set it as the value in Pathogen Name
            if (this.searchedPathogen != null) {
                $(this.pathogenNameSelectId).val(this.searchedPathogen.PathogenId);
                this.searchedPathogen = null;
            }
            $(this.pathogenNameSelectId).trigger("change");
        });

        // Change Pathogen Name
        $(this.pathogenNameSelectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {

            // Get Selected Pathogen
            let searchedPathogen = GetSelectedDropdownValues(this.pathogenNameSelectId);

            // Create Dropdown options for Pathogen Name
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == searchedPathogen);
            if (foundPathogen != undefined) {
                $(this.addPathogenBtn).removeClass("disabled");
            }
            else {
                $(this.addPathogenBtn).addClass("disabled");
            }
        });


        $(this.searchBlock).find(".reset-pathogen-search-btn").on('click', () => {
            this.resetSearchBar();
        });

        $(this.mappingBlock).find(".delete-source-type-btn").on('click', () => {
            if (this.currentUse == "specimen" && this.targetSpecimen != null) {

                // Remove Source/Type from select
                let foundSource = this.sourceList.find(source => source.Source == this.targetSpecimen.SpecimenSource && source.Type == this.targetSpecimen.SpecimenType);
                if (foundSource != undefined) {
                    let foundSourceIndex = this.currentSourceIds.findIndex(id => id == foundSource.SourceId)
                    if (foundSourceIndex >= 0) {
                        this.currentSourceIds.splice(foundSourceIndex, 1);
                        let stringSourceIds: string[] = this.currentSourceIds.map(id => id.toString());
                        $(this.sourceSelectId).val(stringSourceIds);
                        $(this.sourceSelectId).selectpicker("refresh");
                    }
                }

                // Remove from current Specimens
                let foundIndex = this.currentSpecimens.findIndex(specimen => specimen.SpecimenSource == this.targetSpecimen.SpecimenSource && specimen.SpecimenType == this.targetSpecimen.SpecimenType);
                if (foundIndex >= 0) {
                    this.currentSpecimens.splice(foundIndex, 1);
                    this.deleteSpecimen(this.targetSpecimen); // Delete specimen from database (if its in there)
                }

                // Update the source Dropdown
                this.triggerFilter(); // update list source/types
                this.updatePathogenMappingTable();
            }
            else if (this.currentUse == "reagent" && this.targetReagent != null) {

                // Remove Source/Type from select
                let foundSource = this.sourceList.find(source => source.Source == this.targetReagent.ReagentSource && source.Type == this.targetReagent.ReagentType);
                if (foundSource != undefined) {
                    let foundSourceIndex = this.currentSourceIds.findIndex(id => id == foundSource.SourceId)
                    if (foundSourceIndex >= 0) {
                        this.currentSourceIds.splice(foundSourceIndex, 1);
                        let stringSourceIds: string[] = this.currentSourceIds.map(id => id.toString());
                        $(this.sourceSelectId).val(stringSourceIds);
                        $(this.sourceSelectId).selectpicker("refresh");
                    }
                }

                // Remove from current Reagents
                let foundIndex = this.currentReagents.findIndex(reagent => reagent.ReagentSource == this.targetReagent.ReagentSource && reagent.ReagentType == this.targetReagent.ReagentType);
                if (foundIndex >= 0) {
                    this.currentReagents.splice(foundIndex, 1);
                    this.deleteReagent(this.targetReagent); // Delete specimen from database (if its in there)
                }

                // Update the source Dropdown
                this.triggerFilter(); // update list source/types
                this.updatePathogenMappingTable();
            }
        })
    }

    initializePathogenTable(): void {
        let table = $(this.pathogenTableId).DataTable({
            "dom": '<"top-controls"<"count-section"><"search-section"<"search-bar"f>>>rtip',
            autoWidth: false,
            info: false,
            paging: true,
            pageLength: 8,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            columns: [
                { data: "VirusName", title: "Name", className: "text-left font-size12" },
                { data: "Family", title: "Family", className: "text-left font-size12" },
                { title: "", className: "text-center delete-pathogen-btn", defaultContent: `<a><i class="fa-solid fa-trash"></i></a>` },
            ],
            language: {
                emptyTable: 'No Added Pathogens',
                search: ""
            },
            data: this.addedPathogens

        });
        this.pathogenTable = table;

        $(".top-controls").addClass('row justify-content-between align-items-center');

        $(this.tableBlock).find(".count-section").addClass("col-6").html(`<label>Added Pathogens: <span class="added-pathogens-count"></span></label>`)
        $(this.tableBlock).find(".search-section").addClass('col-6');
        $(this.tableBlock).find(".search-bar").addClass('d-flex justify-content-end vertical-align-top align-content-start');

        /*$(this.pathogenTableId).on('search.dt', (e, settings) => {
            this.setAddedPathogenCountLabel(this.pathogenTable.rows({ search: 'applied' }).count());
        });*/

        //on delete pathogen button click
        $(`${this.pathogenTableId} tbody`).on('click', '.delete-pathogen-btn', (event) => {
            // Get Item from Row
            var tr = $(event.currentTarget).closest('tr');
            var row = this.pathogenTable.row(tr);
            let data: Pathogen = row.data();

            // Find Index in Added Pathogens
            let foundIndex = this.addedPathogens.findIndex(pathogen => pathogen.PathogenId == data.PathogenId);
            if (foundIndex >= 0) {
                this.addedPathogens.splice(foundIndex, 1);

                // Remove Pathogen from any current specimens/reagents
                this.removePathogenFromCurrentItems(data.PathogenId);

                // Update Table
                this.updatePathogenTable();
                this.updateSourceList();
                this.updatePathogenMappingTable();
            }
        });

        this.setAddedPathogenCountLabel(this.addedPathogens.length);
    }

    initializeMappingTable(): void {
        let table = $(this.mappingTableId).DataTable({
            "dom": '<"top-controls"<"count-section"><"search-section"<"search-bar"f>>>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            columns: [
                { title: "", orderable: false, width: "15px", className: "text-center pathogen-select-option", data: { _: "IsSelected", display: "SelectorDisplay" } },
                { data: "VirusName", title: "Name", className: "text-left font-size12" },
                { data: "Family", title: "Family", className: "text-left font-size12" },
            ],
            order: [[1, 'asc']],
            language: {
                emptyTable: 'Please Select a Source/Type',
                search: ""
            },
            data: []

        });
        this.mappingTable = table;

        $(".top-controls").addClass('row justify-content-between align-items-center');

        $(this.mappingBlock).find(".count-section").addClass("col-6").html(`<label class="form-element-heading">Associated Pathogens: <span class="associated-pathogen-count"></span></label>`)
        $(this.mappingBlock).find(".search-section").addClass('col-6');
        $(this.mappingBlock).find(".search-bar").addClass('d-flex justify-content-end vertical-align-top align-content-start');

        /*$(this.pathogenTableId).on('search.dt', (e, settings) => {
            this.setAssociatedPathogenCountLabel(this.mappingTable.rows({ search: 'applied' }).count());
        });*/

        //on td.details-control removed
        $(`${this.mappingTableId} tbody`).on('click', 'td', (event) => {
            let td = $(event.currentTarget);
            if (td.hasClass("pathogen-select-option")) {
                this.updateSelector(td.find(".form-check-input").first());
                return;
            };
        });

        this.setAssociatedPathogenCountLabel(this.addedPathogens.length);
    }

    initializeMappingSourceSelect(): void {
        $(this.mappingBlock).find(".source-type-list").on('click', '.mapping-source-item', (event) => {
            $(this.mappingBlock).find(".mapping-source-item").removeClass("active");

            if (this.currentUse == "specimen") {
                // If there is a current target, save the content
                if (this.targetSpecimen != null) {
                    this.saveSpecimen(this.targetSpecimen);
                }

                // get index in currentSpecimens
                let specimenIndex = $(event.currentTarget).data("index");
                let foundSpecimen = this.currentSpecimens[specimenIndex];
                $(event.currentTarget).addClass("active");

                // Set the foundSpecimen as the target
                this.targetSpecimen = foundSpecimen;

                // Update the Mapping Table for new target
                this.updatePathogenMappingTable();
                $(this.mappingBlock).find(".delete-source-type-btn").removeClass("disabled");
            }
            else if (this.currentUse == "reagent") {
                // If there is a current target, save the content
                if (this.targetReagent != null) {
                    this.saveReagent(this.targetReagent);
                }

                // get index in currentReagents
                let reagentIndex = $(event.currentTarget).data("index");
                let foundReagent = this.currentReagents[reagentIndex];
                $(event.currentTarget).addClass("active");

                // Set the foundReagent as the target
                this.targetReagent = foundReagent;

                // Update the Mapping Table for new target
                this.updatePathogenMappingTable();
                $(this.mappingBlock).find(".delete-source-type-btn").removeClass("disabled");
            }
            
        });
    }

    resetAll(): void {
        this.currentBiodirectorySite = null;
        this.targetSpecimen = null;
        this.currentSpecimens = [];
        this.currentReagents = [];
        this.targetReagent = null;
        this.currentSourceIds = [];

        // Reset Sources
        this.resetDropdowns();

        // Reset Pathogen Select Tool
        this.resetSearchBar();
        this.searchedPathogen = null;
        this.addedPathogens = [];
        this.updatePathogenTable();
        this.setAddedPathogenCountLabel(this.addedPathogens.length);

        // Reset Mapping Tool
        this.updateSourceList();
        this.updatePathogenMappingTable();
        this.setAssociatedPathogenCountLabel(this.addedPathogens.length);
    }

    // Commands to Run when Source Selection is Changed
    triggerFilter(): void {

        // de-select any items in the mapping section
        this.targetSpecimen = null;
        this.targetReagent = null;

        // Get Selected Source Ids
        let sourceIds: string[] = GetSelectedDropdownValues(this.sourceSelectId);

        // If source Id isn't included in current source Id array, add it
        let addedSources: BiodirectorySourceType[] = [];
        sourceIds.forEach(sourceId => {
            let numId: number = parseInt(sourceId);

            if (!this.currentSourceIds.includes(numId)) {
                this.currentSourceIds.push(numId);
                let foundSource: BiodirectorySourceType = this.sourceList.find(source => source.SourceId == numId);
                if (foundSource != undefined) {
                    addedSources.push(foundSource);
                }
            }
        })

        // if there are existing source Ids that are not present in source selection, potentially remove them
        let potentialRemoveIds: number[] = [];
        this.currentSourceIds.forEach(sourceId => {
            if (!sourceIds.includes(sourceId.toString())) {
                potentialRemoveIds.push(sourceId);
            }
        });

        // Check if remove Ids have mapped Pathogen Data, if no, remove them
        let removeIds: number[] = [];
        if (this.currentUse == "specimen") {
            potentialRemoveIds.forEach(sourceId => {
                let foundSource = this.sourceList.find(source => source.SourceId == sourceId);
                if (foundSource != undefined) {
                    let foundSpecimen = this.currentSpecimens.find(specimen => specimen.SpecimenSource == foundSource.Source && specimen.SpecimenType == foundSource.Type);
                    if (foundSpecimen == undefined) {
                        removeIds.push(sourceId);
                    }
                    else if (foundSpecimen.PathogenIds.length == 0) {
                        removeIds.push(sourceId);
                    }
                }
            })

            // Remove from current source Ids, and remove current specimens 
            removeIds.forEach(removeSourceId => {
                let foundIndex = this.currentSourceIds.findIndex(id => id == removeSourceId);
                if (foundIndex >= 0) {
                    this.currentSourceIds.splice(foundIndex, 1);
                }

                let foundSource = this.sourceList.find(source => source.SourceId == removeSourceId);
                if (foundSource != undefined) {
                    let foundSpecimenIndex = this.currentSpecimens.findIndex(specimen => specimen.SpecimenSource == foundSource.Source && specimen.SpecimenType == foundSource.Type);

                    if (foundSpecimenIndex >= 0) {
                        this.deleteSpecimen(this.currentSpecimens[foundSpecimenIndex]);
                        this.currentSpecimens.splice(foundSpecimenIndex, 1);
                    }
                }
            })
        }
        else if (this.currentUse == "reagent") {
            potentialRemoveIds.forEach(sourceId => {
                let foundSource = this.sourceList.find(source => source.SourceId == sourceId);
                if (foundSource != undefined) {
                    let foundReagent = this.currentReagents.find(reagent => reagent.ReagentSource == foundSource.Source && reagent.ReagentType == foundSource.Type);
                    if (foundReagent == undefined) {
                        removeIds.push(sourceId);
                    }
                    else if (foundReagent.PathogenIds.length == 0) {
                        removeIds.push(sourceId);
                    }
                }
            })

            // Remove from current source Ids, and remove current reagents 
            removeIds.forEach(removeSourceId => {
                let foundIndex = this.currentSourceIds.findIndex(id => id == removeSourceId);
                if (foundIndex >= 0) {
                    this.currentSourceIds.splice(foundIndex, 1);
                }

                let foundSource = this.sourceList.find(source => source.SourceId == removeSourceId);
                if (foundSource != undefined) {
                    let foundReagentIndex = this.currentReagents.findIndex(reagnet => reagnet.ReagentSource == foundSource.Source && reagnet.ReagentType == foundSource.Type);

                    if (foundReagentIndex >= 0) {
                        this.deleteReagent(this.currentReagents[foundReagentIndex]);
                        this.currentReagents.splice(foundReagentIndex, 1);
                    }
                }
            })
        }

        // Create new specimen entries
        if (this.currentUse == "specimen") {
            addedSources.forEach(source => {
                this.currentSpecimens.push(BiodirectorySpecimen.createBiodirectorySpecimen(this.currentBiodirectorySite.Id, source.Source, source.Type))
            })
        }
        else if (this.currentUse == "reagent") {
            addedSources.forEach(source => {
                this.currentReagents.push(BiodirectoryReagent.createBiodirectoryReagent(this.currentBiodirectorySite.Id, source.Source, source.Type))
            })
        }

        // Update Mapping Table
        this.updateSourceList();

    }

    resetSearchBar(): void {
        $(this.pathogenSearchSelectId).val("").trigger("change");
        $(this.addFamilyBtn).addClass("disabled");
        $(this.addPathogenBtn).addClass("disabled");
    }

    setAddedPathogenCountLabel(count: number): void {
        $(this.tableBlock).find(".added-pathogens-count").text(count);
    }

    setAssociatedPathogenCountLabel(count: number): void {
        $(this.mappingBlock).find(".associated-pathogen-count").text(count);
    }

    adjustTableColumns(): void {
        this.pathogenTable.columns.adjust().draw();
        this.mappingTable.columns.adjust().draw();
    }

    updatePathogenTable(): void {
        this.pathogenTable.clear();
        this.pathogenTable.rows.add(this.addedPathogens);
        this.pathogenTable.draw();

        this.setAddedPathogenCountLabel(this.addedPathogens.length);

        if (this.currentUse == "specimen" && this.currentBiodirectorySite != null) {
            let pathogenIdArray: string[] = this.addedPathogens.map(pathogen => pathogen.PathogenId.toString());
            let pathogenIdString = pathogenIdArray.join("|");
            this.currentBiodirectorySite.BiodirectoryResearchSite.SpecimenPathogenListIds = pathogenIdString;

            // Update list of added pathogens in database
            this.updatePathogenIds(this.currentBiodirectorySite.BiodirectoryResearchSite);
        }
        else if (this.currentUse == "reagent" && this.currentBiodirectorySite != null) {
            let pathogenIdArray: string[] = this.addedPathogens.map(pathogen => pathogen.PathogenId.toString());
            let pathogenIdString = pathogenIdArray.join("|");
            this.currentBiodirectorySite.BiodirectoryResearchSite.ReagentPathogenListIds = pathogenIdString;

            // Update list of added pathogens in database
            this.updatePathogenIds(this.currentBiodirectorySite.BiodirectoryResearchSite);
        }
      
    }

    updatePathogenMappingTable(): void {
        this.addedPathogens.forEach(pathogen => {
            if (this.currentUse == "specimen") {
                if (this.targetSpecimen != null) {
                    if (this.targetSpecimen.PathogenIdArray.includes(pathogen.PathogenId.toString())) {
                        pathogen.updateSelect(true);
                    }
                    else {
                        pathogen.updateSelect(false);
                    }
                }
                else {
                    pathogen.updateSelect(false);
                }
            }
            else if (this.currentUse == "reagent") {
                if (this.targetReagent != null) {
                    if (this.targetReagent.PathogenIdArray.includes(pathogen.PathogenId.toString())) {
                        pathogen.updateSelect(true);
                    }
                    else {
                        pathogen.updateSelect(false);
                    }
                }
                else {
                    pathogen.updateSelect(false);
                }
            }
        })

        this.mappingTable.clear();
        if (this.currentUse == "specimen") {
            if (this.targetSpecimen == null) {
                this.mappingTable.rows.add([]);
                this.setAssociatedPathogenCountLabel(0);
            }
            else {
                this.mappingTable.rows.add(this.addedPathogens);
                this.setAssociatedPathogenCountLabel(this.addedPathogens.filter(pathogen => pathogen.IsSelected).length)
            }
        }
        else if (this.currentUse == "reagent") {
            if (this.targetReagent == null) {
                this.mappingTable.rows.add([]);
                this.setAssociatedPathogenCountLabel(0);
            }
            else {
                this.mappingTable.rows.add(this.addedPathogens);
                this.setAssociatedPathogenCountLabel(this.addedPathogens.filter(pathogen => pathogen.IsSelected).length)
            }
        }  
        this.mappingTable.draw();
    }

    updateSourceList(): void {
        $(this.mappingBlock).find(".source-type-list").empty();
        $(this.mappingBlock).find(".delete-source-type-btn").addClass("disabled");

        let content = "";

        if (this.currentUse == "specimen") {
            for (let i = 0; i < this.currentSpecimens.length; i++) {
                let rowContent = `
                <div class="card">
                    <div class="card-header mapping-source-item" data-index="${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center">
                                        <span class="expand-text d-block pl-2">${this.currentSpecimens[i].SpecimenSource} (${this.currentSpecimens[i].SpecimenType})</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-exclamation-circle warning-icon fa-xl ${this.currentSpecimens[i].PathogenIdArray.length == 0 ? "" : "d-none"}"></i>
                            </div>
                        </div>
                    </div>
                </div>
                `
                content += rowContent;
            }
        }
        else if (this.currentUse == "reagent") {
            for (let i = 0; i < this.currentReagents.length; i++) {
                let rowContent = `
                <div class="card">
                    <div class="card-header mapping-source-item" data-index="${i}">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <div class="header-text">
                                    <a class="collapsed text-decoration-none d-flex align-items-center">
                                        <span class="expand-text d-block pl-2">${this.currentReagents[i].ReagentSource} (${this.currentReagents[i].ReagentType})</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-exclamation-circle warning-icon fa-xl ${this.currentReagents[i].PathogenIdArray.length == 0 ? "" : "d-none"}"></i>
                            </div>
                        </div>
                    </div>
                </div>
                `
                content += rowContent;
            }
        }
        $(this.mappingBlock).find(".source-type-list").html(content);
    }

    updateSelector(checkbox): void {
        let tr = $(checkbox).closest('tr');
        let row = this.mappingTable.row(tr);
        let currentData: Pathogen = row.data();
        let isChecked = !currentData.IsSelected;
        currentData.updateSelect(isChecked);

        if (this.currentUse == "specimen" && this.targetSpecimen != null) {
            if (isChecked) {
                this.targetSpecimen.PathogenIdArray.push(currentData.PathogenId.toString());
            }
            else {
                let foundIndex = this.targetSpecimen.PathogenIdArray.findIndex(id => id == currentData.PathogenId.toString());
                if (foundIndex >= 0) {
                    this.targetSpecimen.PathogenIdArray.splice(foundIndex, 1);
                }
            }

            // Update warning icon on source selection 
            let foundIndex = this.currentSpecimens.findIndex(specimen => specimen.SpecimenSource == this.targetSpecimen.SpecimenSource && specimen.SpecimenType == this.targetSpecimen.SpecimenType);
            if (foundIndex >= 0) {
                let warningIcon = $(this.mappingBlock).find(`[data-index='${foundIndex}']`).find(".warning-icon");

                if (this.targetSpecimen.PathogenIdArray.length > 0) {
                    $(warningIcon).addClass("d-none");
                }
                else {
                    $(warningIcon).removeClass("d-none");
                }
            }

            // Update core object with associated pathogens
            this.targetSpecimen.PathogenIds = this.targetSpecimen.PathogenIdArray.join("|");
        }
        else if (this.currentUse == "reagent" && this.targetReagent != null) {
            if (isChecked) {
                this.targetReagent.PathogenIdArray.push(currentData.PathogenId.toString());
            }
            else {
                let foundIndex = this.targetReagent.PathogenIdArray.findIndex(id => id == currentData.PathogenId.toString());
                if (foundIndex >= 0) {
                    this.targetReagent.PathogenIdArray.splice(foundIndex, 1);
                }
            }

            // Update warning icon on source selection 
            let foundIndex = this.currentReagents.findIndex(reagent => reagent.ReagentSource == this.targetReagent.ReagentSource && reagent.ReagentType == this.targetReagent.ReagentType);
            if (foundIndex >= 0) {
                let warningIcon = $(this.mappingBlock).find(`[data-index='${foundIndex}']`).find(".warning-icon");

                if (this.targetReagent.PathogenIdArray.length > 0) {
                    $(warningIcon).addClass("d-none");
                }
                else {
                    $(warningIcon).removeClass("d-none");
                }
            }

            // Update core object with associated pathogens
            this.targetReagent.PathogenIds = this.targetReagent.PathogenIdArray.join("|");
        }
        row.data(currentData);
        this.setAssociatedPathogenCountLabel(this.addedPathogens.filter(pathogen => pathogen.IsSelected).length)
    }

    resetDropdowns(): void {
        $(this.sourceSelectId).selectpicker('deselectAll');
        $(this.sourceSelectId).selectpicker('refresh');
    }

    prepopulateBiodirectoryInformation(site: BiodirectoryCombinedEntity): void {
        // Set current Biodirectory Site
        this.currentBiodirectorySite = site;

        // Set Current Specimens/Reagents
        this.currentSpecimens = site.BiodirectorySpecimens;
        this.currentReagents = site.BiodirectoryReagents;

        // Set current source Ids
        let associatedPathogenIdArray: string[] = [];
        if (this.currentUse == "specimen") {
            let targetSourceIds = [];
            this.currentSpecimens.forEach(specimen => {
                let foundSourceType = this.sourceList.find(source => source.Source == specimen.SpecimenSource && source.Type == specimen.SpecimenType);
                if (foundSourceType != undefined) {
                    if (!targetSourceIds.includes(foundSourceType.SourceId)) {
                        targetSourceIds.push(foundSourceType.SourceId);
                    }
                }

                if (specimen.PathogenIds != "" && specimen.PathogenIds != null && specimen.PathogenIds != undefined) {
                    let pathogenIdArray = specimen.PathogenIds.split("|");
                    associatedPathogenIdArray.push(...pathogenIdArray);
                }

            })
            associatedPathogenIdArray = Unique(associatedPathogenIdArray, true);
            this.currentSourceIds = targetSourceIds;
        }
        else if (this.currentUse == "reagent") {
            let targetSourceIds = [];
            this.currentReagents.forEach(reagent => {
                let foundSourceType = this.sourceList.find(source => source.Source == reagent.ReagentSource && source.Type == reagent.ReagentType);
                if (foundSourceType != undefined) {
                    if (!targetSourceIds.includes(foundSourceType.SourceId)) {
                        targetSourceIds.push(foundSourceType.SourceId);
                    }
                }

                if (reagent.PathogenIds != "" && reagent.PathogenIds != null && reagent.PathogenIds != undefined) {
                    let pathogenIdArray = reagent.PathogenIds.split("|");
                    associatedPathogenIdArray.push(...pathogenIdArray);
                }
            })
            associatedPathogenIdArray = Unique(associatedPathogenIdArray, true);
            this.currentSourceIds = targetSourceIds;
        }

        // Update Source Dropdown
        let stringSourceIds: string[] = this.currentSourceIds.map(id => id.toString());
        $(this.sourceSelectId).val(stringSourceIds);
        $(this.sourceSelectId).selectpicker("refresh");
        this.updateSourceList();

        // Set Added Pathogens
        let addedPathogenIdArray: string[] = [];
        if (this.currentUse == "specimen") {
            if (this.currentBiodirectorySite.BiodirectoryResearchSite.SpecimenPathogenListIds != null) {
                addedPathogenIdArray = this.currentBiodirectorySite.BiodirectoryResearchSite.SpecimenPathogenListIds.split("|");
                associatedPathogenIdArray.forEach(id => {
                    if (!addedPathogenIdArray.includes(id)) {
                        addedPathogenIdArray.push(id);
                    }
                });
            }
        }
        else if (this.currentUse == "reagent") {
            if (this.currentBiodirectorySite.BiodirectoryResearchSite.ReagentPathogenListIds != null) {
                addedPathogenIdArray = this.currentBiodirectorySite.BiodirectoryResearchSite.ReagentPathogenListIds.split("|");
                associatedPathogenIdArray.forEach(id => {
                    if (!addedPathogenIdArray.includes(id)) {
                        addedPathogenIdArray.push(id);
                    }
                });
            }
        }

        let addedPathogens: Pathogen[] = [];
        addedPathogenIdArray.forEach(pathogenId => {
            let foundPathogen: Pathogen = this.pathogenList.find(pathogen => pathogen.PathogenId.toString() == pathogenId);
            if (foundPathogen != undefined) {
                // don't add duplicates
                if (addedPathogens.findIndex(pathogen => pathogen.PathogenId == foundPathogen.PathogenId) < 0) {
                    addedPathogens.push(foundPathogen)
                }
            }
        });

        this.addedPathogens = addedPathogens;
        this.updatePathogenTable();
        this.updatePathogenMappingTable();
    }

    saveSpecimen(specimen: BiodirectorySpecimen): void {
        this.currentBiodirectorySite.BiodirectoryResearchSite.LastModifiedDate = new Date();

        this.currentBiodirectorySite.BiodirectorySpecimens.forEach(specimen => {
            specimen.PathogenIds = specimen.PathogenIdArray.join("|");
        });

        specimen.PathogenIds = specimen.PathogenIdArray.join("|");

        specimen.LastModifiedDate = new Date();

        let onSuccess = (response: any) => {
            // response is the specimen
            let updatedSpecimen: BiodirectorySpecimen = BiodirectorySpecimen.createBiodirectorySpecimenFromObj(response);
            updatedSpecimen.initialize();

            // update the specimens
            let foundSpecimenIndex = this.currentSpecimens.findIndex(specimen => specimen.SpecimenSource == updatedSpecimen.SpecimenSource && specimen.SpecimenType == updatedSpecimen.SpecimenType);
            if (foundSpecimenIndex >= 0) {
                this.currentSpecimens.splice(foundSpecimenIndex, 1, updatedSpecimen);
            }
        }

        let onError = (response: any) => {
            console.log(response);
            alert("An unexpected error occurred. Please try again.");
        }

        this.updateBiodirectorySpecimen(specimen, onSuccess, onError);
    }

    saveReagent(reagent: BiodirectoryReagent): void {
        this.currentBiodirectorySite.BiodirectoryResearchSite.LastModifiedDate = new Date();

        this.currentBiodirectorySite.BiodirectoryReagents.forEach(reagent => {
            reagent.PathogenIds = reagent.PathogenIdArray.join("|");
        });

        reagent.PathogenIds = reagent.PathogenIdArray.join("|");

        reagent.LastModifiedDate = new Date();

        let onSuccess = (response: any) => {
            // response is the specimen
            let updatedReagent: BiodirectoryReagent = BiodirectoryReagent.createBiodirectoryReagentFromObj(response);
            updatedReagent.initialize();

            // update the specimens
            let foundReagentIndex = this.currentReagents.findIndex(reagent => reagent.ReagentSource == updatedReagent.ReagentSource && reagent.ReagentType == updatedReagent.ReagentType);
            if (foundReagentIndex >= 0) {
                this.currentReagents.splice(foundReagentIndex, 1, updatedReagent);
            }
        }

        let onError = (response: any) => {
            console.log(response);
            alert("An unexpected error occurred. Please try again.");
        }

        this.updateBiodirectoryReagent(reagent, onSuccess, onError);
    }

    saveSiteAndSpecimens(): void {
        this.currentBiodirectorySite.BiodirectoryResearchSite.LastModifiedDate = new Date();
        this.currentBiodirectorySite.BiodirectorySpecimens.forEach(specimen => specimen.LastModifiedDate = new Date());
        this.updateBiodirectorySiteAndSpecimen(this.currentBiodirectorySite);
    }

    saveSiteAndReagents(): void {
        this.currentBiodirectorySite.BiodirectoryResearchSite.LastModifiedDate = new Date();
        this.currentBiodirectorySite.BiodirectoryReagents.forEach(reagent => reagent.LastModifiedDate = new Date());
        this.updateBiodirectorySiteAndReagent(this.currentBiodirectorySite);
    }

    getTargetBiodirectorySite(): BiodirectoryCombinedEntity {
        return this.currentBiodirectorySite;
    }

    removePathogenFromCurrentItems(pathogenId: number): void {
        if (this.currentUse == "specimen") {
            this.currentSpecimens.forEach(specimen => {
                if (specimen.PathogenIdArray.includes(pathogenId.toString())) {
                    let foundIndex = specimen.PathogenIdArray.findIndex(id => id == pathogenId.toString());
                    specimen.PathogenIdArray.splice(foundIndex, 1);
                    specimen.PathogenIds = specimen.PathogenIdArray.join("|");
                }
            })
        }
        else if (this.currentUse == "reagent") {
            this.currentReagents.forEach(reagent => {
                if (reagent.PathogenIdArray.includes(pathogenId.toString())) {
                    let foundIndex = reagent.PathogenIdArray.findIndex(id => id == pathogenId.toString());
                    reagent.PathogenIdArray.splice(foundIndex, 1);
                    reagent.PathogenIds = reagent.PathogenIdArray.join("|");
                }
            })
        }
    }

    updatePathogenIds(site: BiodirectoryResearchSite, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectoryInformation',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(site) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    updateBiodirectorySpecimen(specimen: BiodirectorySpecimen, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectorySpecimen',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(specimen) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    updateBiodirectorySiteAndSpecimen(siteInfo: BiodirectoryCombinedEntity, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectorySiteAndSpecimen',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(siteInfo) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    deleteSpecimen(specimen: BiodirectorySpecimen, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=DeleteSpecimen',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(specimen) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    updateBiodirectoryReagent(reagent: BiodirectoryReagent, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectoryReagent',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(reagent) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    updateBiodirectorySiteAndReagent(siteInfo: BiodirectoryCombinedEntity, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectorySiteAndReagent',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(siteInfo) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    deleteReagent(reagent: BiodirectoryReagent, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=DeleteReagent',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(reagent) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }
}
