import { Resource } from "./resource-library.entities";
import { TableColumnVisibilityController, TableColumnGroup } from "../../shared/scripts/table-column-visibility";
import { plainToClass } from "class-transformer";
import { Unique } from "../../shared/scripts/array-functions";
import { RenderDateYMD } from "../../shared/scripts/table-functions";


export class ResourceLibraryPage {
    resources: Resource[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    filterDropdownIds: string[];

    constructor(data: any) {
        $(() => {

            this.resources = plainToClass(Resource, <Resource[]>data);
            this.resources = this.resources.map((resource: Resource) => {
                resource.initialize();
                return resource;
            });

            this.filterDropdownIds = ['#category-dropdown', '#topic-dropdown', '#format-dropdown', '#language-dropdown', '#pathogen-dropdown', '#expertise-dropdown', '#source-dropdown'];
            this.initializeFilters();
            //console.log(this.stakeholders);

            this.initializeTable();
            this.setResourcesFoundLabel(this.resources.length);

        });
    }

    initializeFilters(): void {
        this.initializeDropdown('#category-dropdown', Unique(this.resources.map((resource: Resource) => resource.Category), true, ["Not Defined"]));
        this.initializeDropdown('#topic-dropdown', Unique(this.resources.map((resource: Resource) => resource.Topic), true, ["Not Defined"]));
        this.initializeDropdown('#format-dropdown', Unique(this.resources.map((resource: Resource) => resource.Format), true, ["Not Defined"]));
        this.initializeDropdown('#expertise-dropdown', Unique(this.resources.map((resource: Resource) => resource.ExpertiseLevel), true, ["Not Defined"]));
        this.initializeDropdown('#source-dropdown', Unique(this.resources.map((resource: Resource) => resource.Source), true, ["Not Defined"]));

        let values: string[] = [];
        this.resources.forEach((resource: Resource) => { values.push(...resource.VirusList); });
        this.initializeDropdown('#pathogen-dropdown', Unique(values, true, ["Not Defined"]));

        values = [];
        this.resources.forEach((resource: Resource) => { values.push(...resource.LanguageList); });
        this.initializeDropdown('#language-dropdown', Unique(values, true, ["Not Defined"]));
    }

    //could be moved to utility - like in RECOVER-CT codebase
    initializeDropdown(selectId: string, values: string[]): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, true));
        });
        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterResources();
        });
    }


    initializeTable(): void {
        this.dataTable = $('#resources-table').DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            //"dom": '<"top-controls"<"left-section"<"search-bar"f>><"right-section"<"column-select">B>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            //responsive: false,
            scrollY: '68vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 1
            },
            language:
            {
                search: "Search",
            },
            columns: [
                { data: { _: "Name", sort: "Name" }, title: "Resource Name", className: "text-left font-size12 min-width-250" },
                //{ data: "UrlDisplay", title: "URL", className: "material-access text-left font-size12 min-width-50" },
                //{ data: "FilenameDisplay", title: "File", className: "material-access text-left font-size12 min-width-50" },
                { data: "ResourceAccess", title: "Resource", className: "material-access text-left font-size12 min-width-50" },
                { data: "Category", title: "Resource Type", className: "category-topic text-left font-size12 min-width-200" },
                { data: "Topic", title: "Topic", className: "category-topic text-left font-size12 min-width-200" },
                { data: "Format", title: "Format", className: "category-topic text-left font-size12" },
                { data: "Description", title: "Description", className: "details text-left font-size11 about-col min-width-400" },
                { data: "CourseStructure", title: "Structure", className: "details text-left font-size12" },
                { data: "TrainingDates", title: "Training Dates", className: "details text-left font-size12" },
                { data: "LanguageDisplay", title: "Language(s)", className: "details text-left font-size12" },
                { data: "Source", title: "CREID Source", className: "source-poc text-left font-size12" },
                { data: "OtherSource", title: "Other Source", className: "source-poc text-left font-size12" },
                { data: "PrimaryContributorName", title: "Primary Contributor<br>(Name)", className: "source-poc text-left font-size12" },
                { data: "PrimaryContributorAffiliation", title: "Primary Contributor<br>(Affiliation)", className: "source-poc text-left font-size12" },
                { data: "PrimaryContributorEmailDisplay", title: "Primary Contributor<br>(Email)", className: "source-poc text-left font-size12" },
                { data: "VirusDisplay", title: "<span class='nowrap'>Pathogen(s)</span>", className: "virus-info text-left font-size12" },
                { data: "DiseaseDisplay", title: "<span class='nowrap'>Disease(s)</span>", className: "virus-info text-left font-size12" },
                { data: "FamilyDisplay", title: "<span class='nowrap'>Virus Families</span>", className: "virus-info text-left font-size12" },
                { data: "ModifiedDate", title: "<span class='nowrap'>Modified Date</span>", className: "modified-date text-left font-size12", width: '100px', render: RenderDateYMD  }
            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                }
            ],
            data: this.resources,
            //order: [[5, 'desc'], [1, 'asc']],
            initComplete: (settings, json) => {
                //$("#hub-details-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3');

        $(".spacer").addClass('col-12 col-md-3'); 
        $(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle mt-2');
        //$(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"sites-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController('#resources-table', '#column-select', [
            new TableColumnGroup("Resource Type, Topic, & Format", true, [], "category-topic"),
            new TableColumnGroup("Access to Materials", true, [], "material-access"),
            new TableColumnGroup("Detail", false, [], "details"),
            new TableColumnGroup("Source & Point of Contact", false, [], "source-poc"),
            new TableColumnGroup("Pathogen", false, [], "virus-info"),
            new TableColumnGroup("Modified Date", true, [], "modified-date"),
        ]);

        $('#resources-table').on('search.dt', (e, settings) => {
            this.setResourcesFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

    }

    filterResources(): void {

        let matches: Resource[] = this.resources.filter((resource: Resource) => {
            return resource.matchesFilter(this.getSelectedDropdownValues('#category-dropdown'), this.getSelectedDropdownValues('#topic-dropdown'), this.getSelectedDropdownValues('#format-dropdown'), this.getSelectedDropdownValues('#source-dropdown'), this.getSelectedDropdownValues('#language-dropdown'), this.getSelectedDropdownValues('#pathogen-dropdown'), this.getSelectedDropdownValues('#expertise-dropdown'));
        });

        this.updateTable(matches);
    }

    getSelectedDropdownValues(selectId: string): any {
        return $(selectId).val();
    }

    //isFilterApplied(selectId: string): boolean {
    //    var selected = $(selectId).val();
    //    var count: number = $(selectId + ' option').length;
    //    return (<any>selected).length != count;
    //}


    updateTable(matchingResources: Resource[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingResources).draw();
        this.setResourcesFoundLabel(matchingResources.length);
    }

    resetFilters(): void {

        this.filterDropdownIds.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();
        this.updateTable(this.resources);
    }

    setResourcesFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#resources-found-count').text("No Resources Found"); break;
            case 1: $('#resources-found-count').text("1 Resource Found"); break;
            default: $('#resources-found-count').text(count + " Resources Found");
        }
    }

    showHelpModal(): void {
        $('#resources-help-modal').modal('show');
    }

    submitNewResource(): void {

        window.open("https://forms.office.com/r/JShBUwhxP1", "_blank");

    }
}