import { plainToClass } from "class-transformer";
import { Stakeholder } from "./stakeholders.entities";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../shared/scripts/table-column-visibility";
import { Unique, Split } from "../../../shared/scripts/array-functions";


export class StakeholdersPage {

    stakeholders: Stakeholder[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    filterDropdownIds: string[];

    constructor(data: any) {
        $(() => {

            this.stakeholders = plainToClass(Stakeholder, <Stakeholder[]>data);
            this.stakeholders = this.stakeholders.map((stakeholder: Stakeholder) => {
                stakeholder.initialize();
                return stakeholder;
            });

            this.filterDropdownIds = ['#stakeholder-type-dropdown', '#organization-type-dropdown', '#geographic-focus-dropdown', '#pathogen-focus-dropdown'];
            this.initializeFilters();
            //console.log(this.stakeholders);

            this.initializeTable();
            this.setStakeholdersFoundLabel(this.stakeholders.length);

        });
    }

    initializeFilters(): void {
        this.initializeDropdown('#stakeholder-type-dropdown', Unique(this.stakeholders.map((stakeholder: Stakeholder) => stakeholder.StakeholderType), true, ["Unknown"]));
        this.initializeDropdown('#organization-type-dropdown', Unique(this.stakeholders.map((stakeholder: Stakeholder) => stakeholder.OrganizationType), true, ["Unknown"]));

        let values: string[] = [];
        this.stakeholders.forEach((stakeholder: Stakeholder) => { values.push(...stakeholder.GeographicFocusList); });
        this.initializeDropdown('#geographic-focus-dropdown', Unique(values, true, ["Not Defined"]));

        values = [];
        this.stakeholders.forEach((stakeholder: Stakeholder) => { values.push(...stakeholder.VirusesList); });
        this.initializeDropdown('#pathogen-focus-dropdown', Unique(values, true, ["Not Defined"]));

    }

    initializeDropdown(selectId: string, values: string[]): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, true));
        });
        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterStakeholders();
        });
    }


    initializeTable(): void {
        this.dataTable = $('#stakeholders-table').DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            //"dom": '<"top-controls"<"left-section"<"search-bar"f>><"right-section"<"column-select">B>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            //responsive: false,
            scrollY: '68vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 1
            },
            language:
            {
                search: "Search",
            },
            columns: [
                { data: { _: "StakeholderName", sort: "StakeholderName" }, title: "Stakeholder Name", className: "text-left font-size12 min-width-250" },
                { data: "StakeholderType", title: "Stakeholder Type", className: "about text-left font-size12" },
                { data: "OrganizationType", title: "Organization Type", className: "about text-left font-size12" },
                { data: "GeographicFocusDisplay", title: "Geographic Focus", className: "about text-left font-size12" },
                { data: "About", title: "Description", className: "about text-left font-size11 about-col min-width-400" },
                { data: "VirusesDisplay", title: "Virus Focus", className: "about text-left font-size12" },
                { data: "DiseasesDisplay", title: "<span class='nowrap'>Disease Focus</span>", className: "about text-left font-size12" },
                { data: "StakeholderOffering", title: "Stakeholder Offering", className: "about text-left font-size12 min-width-150" },
                { data: "Notes", title: "Notes", className: "about text-left font-size12 min-width-200" },
                { data: "URLDisplay", title: "URL", className: "contact text-left font-size12" },
                { data: "StakeholderContactName", title: "Stakeholder Contact (Name)", className: "contact text-left font-size12 min-width-200" },
                { data: "StakeholderContactEmailDisplay", title: "Stakeholder Contact (Email)", className: "contact text-left font-size12" },
                { data: "POC", title: "CC/DMID Point of Contact", className: "contact text-left font-size12 min-width-150" },
                { data: "AgreementInPlace", title: "Agreement in Place", className: "engage text-left font-size12 min-width-100" },
                { data: "PresenttoSC", title: "Present to SC", className: "engage text-left font-size12 min-width-100" },
                { data: "AttendAnnualMeeting", title: "Attend Annual Meeting", className: "engage text-left font-size12 min-width-100" },
                { data: "AttendStakeholderForum", title: "Attend Stakeholder Forum", className: "engage text-left font-size12 min-width-100" },
                { data: "PresentatScientificSeries", title: "Present at Scientific Series", className: "engage text-left font-size12 min-width-100" },
                { data: "AttendScientificSeries", title: "Attend Scientific Series", className: "engage text-left font-size12 min-width-100" },
                { data: "PresentatWGMeetings", title: "Present at WG Meetings", className: "engage text-left font-size12 min-width-100" },
                { data: "InvitedGuesttoWG", title: "Invited Guest to WG", className: "engage text-left font-size12 min-width-100" },
                { data: "MemberofWG", title: "Member of WG", className: "engage text-left font-size12 min-width-100" },
                { data: "ReceiveQuarterlyUpdates", title: "Receive Quarterly Updates", className: "engage text-left font-size12 min-width-100" },
                { data: "BenefitsForm", title: "Have Benefits Form", className: "document text-left font-size12 min-width-100" },
                { data: "StakeholderAgreedDate", title: "Stakeholder Agreed Date", className: "document text-left font-size12 min-width-100" },
                { data: "SCAgreedDate", title: "SC Agreed Date", className: "document text-left font-size12 min-width-100" },
                { data: "Families", title: "Virus Family", visible: false },
            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                }
            ],
            data: this.stakeholders,
            //order: [[5, 'desc'], [1, 'asc']],
            initComplete: (settings, json) => {
                //$("#hub-details-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3');

        $(".spacer").addClass('col-12 col-md-3'); 0
        $(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle mt-2');
        //$(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"sites-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController('#stakeholders-table', '#column-select', [
            new TableColumnGroup("About", true, [], "about"),
            new TableColumnGroup("Contact Info", false, [], "contact"),
            new TableColumnGroup("Network Engagement", false, [], "engage"),
            new TableColumnGroup("Documentation", false, [], "document"),
        ]);

        $('#stakeholders-table').on('search.dt', (e, settings) => {
            this.setStakeholdersFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

    }

    filterStakeholders(): void {

        let matches: Stakeholder[] = this.stakeholders.filter((stakeholder: Stakeholder) => {
            return stakeholder.matchesFilter(this.getSelectedDropdownValues('#stakeholder-type-dropdown'), this.getSelectedDropdownValues('#organization-type-dropdown'), this.getSelectedDropdownValues('#geographic-focus-dropdown'), this.getSelectedDropdownValues('#pathogen-focus-dropdown'));
        });

        this.updateTable(matches);
    }

    getSelectedDropdownValues(selectId: string): any {
        return $(selectId).val();
    }

    //isFilterApplied(selectId: string): boolean {
    //    var selected = $(selectId).val();
    //    var count: number = $(selectId + ' option').length;
    //    return (<any>selected).length != count;
    //}


    updateTable(matchingStakeholders: Stakeholder[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingStakeholders).draw();
        this.setStakeholdersFoundLabel(matchingStakeholders.length);
    }

    resetFilters(): void {

        this.filterDropdownIds.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();
        this.updateTable(this.stakeholders);
    }

    setStakeholdersFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#stakeholders-found-count').text("No Stakeholders Found"); break;
            case 1: $('#stakeholders-found-count').text("1 Stakeholder Found"); break;
            default: $('#stakeholders-found-count').text(count + " Stakeholders Found");
        }
    }

    showHelpModal(): void {
        $('#stakeholder-help-modal').modal('show');
    }

    showRulesOfEngagement(): void {
        var modal = $('#view-document-modal');
        modal.find('#filename').html('CREID Network Stakeholder Rules of Engagement');
        modal.find('#document-iframe').attr('src', 'https://creid-documents.s3.amazonaws.com/stakeholders/Rules%20of%20Engagement_v10.pdf?AWSAccessKeyId=AKIA4POBIQOPDQEZDFZD&Expires=1744769496&Signature=b8yz4tTSr1uTOoZc1GYpVDsizgg%3D');
        modal.modal('show');
    }
}