import { OrrEvent, OrrKeyPerson, OrrKeyAsset, OrrFinancial, OrrTigerTeam, OrrPartner, OrrKeyAssetMapping} from "./orr-tracker.objects";
import { plainToClass } from "class-transformer";
import { Unique } from "../../../shared/scripts/array-functions";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../shared/scripts/table-column-visibility";
import { DocumentTree } from "../../../shared/components/document-tree/document-tree";
import { FormatDate2 } from './../../../shared/scripts/date-functions';


export class OrrTrackerPage {

    orrEvents: OrrEvent[]
    dataTable: any;
    filterDropdownIds: string[];
    tableColumnController: TableColumnVisibilityController;
    keyPersonnelTable: any;
    financialsTable: any;
    tigerTeamTable: any;
    partnersTable: any;
    allFiles: OrrKeyAsset[] = [];
    keyAssetsFiles: OrrKeyAsset[] = [];
    allCountryFiles: OrrKeyAsset[] = []; 
    baseCommunicationDocuments: OrrKeyAsset[] = [];
    constructor(data: any, documents: any, countryDocuments: any, communicationDocuments: any) {
        $(() => {
            this.orrEvents = plainToClass(OrrEvent, <OrrEvent[]>data);
            this.orrEvents.sort((a: OrrEvent, b: OrrEvent) => a.ReportDate < b.ReportDate ? 1 : -1);
            this.orrEvents.map((data: OrrEvent) => {
                data.initialize();
                return data;
            });

            //console.log(this.orrEvents);

            try {
                this.allFiles = plainToClass(OrrKeyAsset, <OrrKeyAsset[]>documents);
                this.allCountryFiles = plainToClass(OrrKeyAsset, <OrrKeyAsset[]>countryDocuments);
                this.baseCommunicationDocuments = plainToClass(OrrKeyAsset, <OrrKeyAsset[]>communicationDocuments);
            }
            catch {
                console.log("Error converting the documents to class");
                console.log(documents);
                console.log(countryDocuments);
                console.log(communicationDocuments);
            }

            this.filterDropdownIds = ["#pathogen-dropdown", "#country-dropdown", "#stage-dropdown", "#center-dropdown"]
            this.initializeFilters();
            this.initializeTableInteractions();

            this.initializeTable();
            this.setEventsFoundLabel(this.orrEvents.length);

            // For Multiple Modals
            $('body').on('hidden.bs.modal', function () {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                }
            });

        });
    }

    initializeFilters(): void {
        // Dropdowns
        let pathogenList: string[] = [];
        let countryList: string[] = [];
        let centerList: string[] = [];
        this.orrEvents.map((orrEvent: OrrEvent) => {
            orrEvent.Pathogens.forEach((pathogen) => pathogenList.push(pathogen.Virus));
            orrEvent.Countries.forEach((country) => countryList.push(country.CountryName));
            orrEvent.ResearchCenters.forEach((center) => centerList.push(center.Name));
        })
        pathogenList = Unique(pathogenList, true);
        countryList = Unique(countryList, true);
        centerList = Unique(centerList, true);

        this.initializeDropdown('#pathogen-dropdown', pathogenList);
        this.initializeDropdown('#country-dropdown', countryList);
        this.initializeDropdown('#stage-dropdown', Unique(this.orrEvents.map((orrEvent: OrrEvent) => orrEvent.Status), true));
        this.initializeDropdown('#center-dropdown', centerList);

        // Report Dates
        $("#report-after-input").val("2020-01-01");
        $("#report-before-input").val(this.getStringDate(new Date()));

        $("#report-after-input").change(() => this.filterOutbreakEvents())
        $("#report-before-input").change(() => this.filterOutbreakEvents())
    }

    initializeTable(): void {
        this.dataTable = $('#orr-tracker-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"column-select">fB>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Search",
            },
            columns: [
                { data: "OutbreakId", className: "outbreak-id outbreakId-col text-left font-size11 text-nowrap"}, //0
                { data: "Status", className: "status text-left font-size12" }, //7
                { data: "DisplayPathogens", className: "pathogens text-left font-size12", width: "200px" }, //2
                { data: "DisplayCountries", className: "countries text-left font-size12", width: "200px" }, //3
                { data: { _: "ReportDate", display: "DisplayReportDate" }, className: "report-date reportDate-col text-left font-size12 text-nowrap"}, //4
                { data: "DisplayResearchCenters", className: "research-centers text-left font-size12", width: "200px" }, //6
                { data: { _: "ClosedDate", display: "DisplayClosedDate" }, className: "closed-date closedDate-col text-left font-size12 text-nowrap"}, //5
                { data: "ViewDetailsBtn", className: "view-details text-left font-size12" }, //1 
            ],
            order: [4],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                }],
            data: this.orrEvents,
            initComplete: (settings, json) => {
                $("#orr-tracker-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass('row px-3 align-items-center');
        $(".column-select").addClass('col-12 col-md-3 px-0 mr-3');
        $(".column-select").empty().html('<div id="column-select"></div>');
        $(".dt-buttons").addClass("ml-auto align-self-end mb-1");

        this.tableColumnController = new TableColumnVisibilityController('#orr-tracker-table', '#column-select', [
            new TableColumnGroup("Outbreak Id", true, [], "outbreak-id"),
            new TableColumnGroup("View Details", true, [], "view-details"),
            new TableColumnGroup("Pathogen", true, [], "pathogens"),
            new TableColumnGroup("Country", true, [], "countries"),
            new TableColumnGroup("Report Date", true, [], "report-date"),
            new TableColumnGroup("Closed Date", true, [], "closed-date"),
            new TableColumnGroup("CREID Center", true, [], "research-centers"),
            new TableColumnGroup("CREID Stage", true, [], "status")
        ]);


        $('#orr-tracker-table').on('search.dt', (e, settings) => {
            this.setEventsFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        $('#orr-tracker-table tbody').on('click', 'tr', (event) => {
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);
            let orrEvent: OrrEvent = row.data();
            this.generateModal(orrEvent);
        });

        // Modal Clicks
        $(".modal-section-heading a").click(function () { $(this).children().first().toggleClass("show-content") });
    }

    initializeDropdown(selectId: string, values: string[]): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, true));
        });
        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterOutbreakEvents();
        });
    }

    filterOutbreakEvents(): void {

        let matchingEvents: OrrEvent[] = [];

        let eventMatches: boolean;

        this.orrEvents.forEach((orrEvent: OrrEvent) => {

            eventMatches = true; //must match all filters


            if (!orrEvent.matchesFilter(this.getSelectedDropdownValues('#pathogen-dropdown'), this.getSelectedDropdownValues('#country-dropdown'), this.getSelectedDropdownValues('#stage-dropdown'), this.getSelectedDropdownValues('#center-dropdown'), $("#report-before-input").val(), $("#report-after-input").val())) {
                eventMatches = false;
            }
         

            if (eventMatches) { matchingEvents.push(orrEvent); }

        });

        this.updateTable(matchingEvents);
    }

    getSelectedDropdownValues(selectId: string): any {
        //return $(selectId).selectpicker('val');
        return $(selectId).val();
    }

    updateTable(matchingEvents: OrrEvent[]): void {
        this.dataTable.clear();
        this.dataTable.rows.add(matchingEvents).draw();
        this.setEventsFoundLabel(matchingEvents.length);
    }

    resetFilters(): void {

        this.filterDropdownIds.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();

        $("#report-after-input").val("2020-01-01");

        $("#report-before-input").val(this.getStringDate(new Date()));

        this.updateTable(this.orrEvents);
    }

    setEventsFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#outbreaks-found-count').text("No Outbreak Events Found"); break;
            case 1: $('#outbreaks-found-count').text("1 Outbreak Event Found"); break;
            default: $('#outbreaks-found-count').text(count + " Outbreak Events Found");
        }
    }

    getStringDate(date: Date): string {
        var date = new Date();

        var day = ("0" + date.getDate()).slice(-2);
        var month = ("0" + (date.getMonth() + 1)).slice(-2);

        let returnDate = date.getFullYear() + "-" + month + "-" + day;

        return returnDate.toString();
    }

    generateModal(orrEvent: OrrEvent): void {
        // Title + Heading
        $("#modal-title").text(orrEvent.OutbreakId);
        $("#modal-last-updated").text(FormatDate2(orrEvent.LastUpdated));
        $("#modal-outbreak-id").text(orrEvent.OutbreakId);
        $("#modal-title-pathogen").text(orrEvent.DisplayPathogens);
        $("#modal-title-country").text(orrEvent.DisplayCountries);

        // CREID Stage
        $(".modal-stage-button-container").removeClass("active");
        let stageString = orrEvent.Status.toLowerCase();
        let stageId = "#modal-" + stageString;
        $(stageId).addClass("active");

        // Modal Section Headings
        $(".modal-section-heading a span").addClass("show-content");
        $(".modal-section-content").addClass("show");

        // Sit Rep
        if (orrEvent.SitutationReport != "") {
            $("#modal-sit-rep").html(orrEvent.SitutationReport);
        }
        else {
            $("#modal-sit-rep").text("No Situation Report associated with this outbreak.");
        }

        // Key Personnel
        this.initializeKeyPersonnelTable(orrEvent);

        //Outbreak Details
        $("#modal-details-outbreak-id").text(orrEvent.OutbreakId);
        $("#modal-details-pathogen").text(orrEvent.DisplayPathogens);
        $("#modal-details-country").text(orrEvent.DisplayCountries);
        $("#modal-details-rc").text(orrEvent.DisplayResearchCenters);
        $("#modal-details-report-date").text(orrEvent.DisplayReportDate);
        $("#modal-details-closed-date").text(orrEvent.DisplayClosedDate);

        // Key Assets
        this.initializeKeyAssets(orrEvent);

        // External Resources
        this.initializeExternalResources(orrEvent);

        // Collaboration Opportunities
        if (orrEvent.CollaborationOpportunities != "") {
            $("#modal-collab-opportunities").removeClass("text-center");
            $("#modal-collab-opportunities").html(orrEvent.CollaborationOpportunities);
        }
        else {
            $("#modal-collab-opportunities").addClass("text-center");
            $("#modal-collab-opportunities").text("No Collaboration Opportunities/Requests associated with this outbreak.")
        }

        // Estimated Financial Support
        this.initializeFinancialSupportTable(orrEvent);

        // Tiger Team
        this.initializeTigerTeamTable(orrEvent);

        // Partners + Stakeholders
        this.initializePartnersTable(orrEvent);

        $("#view-outbreak-modal").modal('show');
    }

    initializeTableInteractions() {
        $('#key-personnel-table').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.keyPersonnelTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createPersonnelDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $('#financial-support-table').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.financialsTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createFinancialDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $('#tiger-team-table').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.tigerTeamTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createTigerTeamRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $('#partners-table').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.partnersTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createPartnerRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });
    }

    initializeKeyPersonnelTable(orrEvent: OrrEvent): void {
        // remove previous table if it already exists
        if (this.keyPersonnelTable != undefined) this.keyPersonnelTable.destroy();

        this.keyPersonnelTable = $('#key-personnel-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls">prti',
            autoWidth: false,
            info: false,
            paging: true,
            pageLength: 5,
            search: false,
            scrollX: false,
            "language": {
                "emptyTable": "No Key Personnel associated with this outbreak."
            },
            //scrollY: '70vh',
            orderCellsTop: true,
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '20px' }, //0
                { data: "DisplayOrganization", className: "text-left font-size12" }, //1
            ],
            data: orrEvent.OrrKeyPersonnel,
            initComplete: (settings, json) => {
                $("#key-personnel-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        }); 

        if (orrEvent.OrrKeyPersonnel.length <= 5) {
            $("#key-personnel-table_paginate").addClass("d-none");
        }
    }

    createPersonnelDetailRow(data: OrrKeyPerson): string {

        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; width:100%">';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold" style="width:100px"><i>Name</i></td>' +
            '<td class="text-left font-size12">' + data.DisplayFullName + '</td>' +
            '</tr>';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Email</i></td>' +
            '<td class="text-left font-size12">' + `<a href="mailto:${data.Email}">${data.Email}</a>` + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Organization</i></td>' +
            '<td class="text-left font-size12">' + data.Organization + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Research Center</i></td>' +
            '<td class="text-left font-size12">' + `${data.ResearchCenter ? data.ResearchCenter.Name : ''}` + '</td>' +
            '</tr>';
        
        detailRow += "</table>"
        return detailRow
    }

    initializeKeyAssets(orrEvent: OrrEvent) {
        this.keyAssetsFiles = [];
        let existingRootFolders: OrrKeyAssetMapping[] = [];

        try {
            this.allFiles.forEach((file) => {
                if (file.id.startsWith(`documents/${orrEvent.OutbreakId}/`)) {
                    this.keyAssetsFiles.push(new OrrKeyAsset().initialize(file, orrEvent.OutbreakId));
                }
            });
        }
        catch (err) {
            console.log("Error Creating Key Asset File Array");
            console.log(err);
            console.log("All ORR Files from S3:");
            console.log(this.allFiles);
        }
        

        // check to see what root folders are present
        try {
            this.keyAssetsFiles.forEach(file => {
                if (file.parent == "#" && file.type == 0) {
                    let folderName = file.id.split("/")[2];

                    if (!existingRootFolders.find(folder => folder.folderName == folderName)) {
                        existingRootFolders.push(new OrrKeyAssetMapping(folderName, file.id))
                    }
                }
            })
        }
        catch (err) {
            console.log("Error Remapping Folder Names");
            console.log(err);
            console.log("Key Asset Files for Outbreak:");
            console.log(this.keyAssetsFiles);
        }
        

        // include any country related files
        try {
            orrEvent.Countries.forEach(country => {
                this.allCountryFiles.forEach(file => {

                    if (file.id.startsWith(`documents/_country-documents/${country.CountryAbbreviation}/`)) {
                        let newAsset = new OrrKeyAsset().initialize(file, orrEvent.OutbreakId, existingRootFolders);

                        // if the asset is a new root folder, add it to the list of existing root folders
                        if (newAsset.type == 0 && newAsset.parent == "#") {
                            let folderName = newAsset.id.split("/")[3];

                            if (!existingRootFolders.find(folder => folder.folderName == folderName)) {
                                existingRootFolders.push(new OrrKeyAssetMapping(folderName, file.id))
                            }
                        }

                        this.keyAssetsFiles.push(newAsset);
                    }
                })
            });
        }
        catch (err) {
            console.log("Error Getting Relevant Country Files");
            console.log(err);
            console.log("All Country Files from S3:");
            console.log(this.allCountryFiles);
        }

        // include communication document files
        try {
            // add communication resources folder if it is not present
            if (!existingRootFolders.find(folder => folder.folderName == "Communication Resources")) {
                let newCommFolder = new OrrKeyAsset();
                newCommFolder.icon = "fas fa-folder";
                newCommFolder.id = `documents/${orrEvent.OutbreakId}/Communication Resources`;
                newCommFolder.link = "";
                newCommFolder.parent = "#";
                newCommFolder.state = {
                    disabled: false,
                    opened: false,
                    selected: false
                }
                newCommFolder.text = "Communication Resources";
                newCommFolder.type = 0;
                existingRootFolders.push(new OrrKeyAssetMapping("Communication Resources", newCommFolder.id));
                this.keyAssetsFiles.push(newCommFolder);
            }

            this.baseCommunicationDocuments.forEach(file => {
                let newAsset = new OrrKeyAsset().initialize(file, orrEvent.OutbreakId, existingRootFolders);
                if (newAsset.type != 0) {
                    newAsset.parent = `documents/${orrEvent.OutbreakId}/Communication Resources`;
                }

                this.keyAssetsFiles.push(newAsset);
            });
        }
        catch (err) {
            console.log("Error Getting Relevant Communication Documents");
            console.log(err);
            console.log("All Communication Documents:");
            console.log(this.baseCommunicationDocuments);
        }

        // Remove any items that are missing parents (moved country documents)
        let filteredKeyAssetFiles: OrrKeyAsset[] = [];
        this.keyAssetsFiles.forEach(file => {
            let foundMatchIndex = this.keyAssetsFiles.findIndex(item => item.id == file.parent);
            if (foundMatchIndex >= 0 || file.parent == "#") {
                filteredKeyAssetFiles.push(file);
            }
        });
        this.keyAssetsFiles = filteredKeyAssetFiles;

        // Sort Key Assets
        this.keyAssetsFiles.sort((x1, x2) => x1.text.localeCompare(x2.text));

        try {
            $(".jstree").remove();
            $(".jstree-container").append(`<div id="jstree-${orrEvent.OutbreakId}" class="jstree"></div>`)
            if (this.keyAssetsFiles.length > 0) {
                new DocumentTree(this.keyAssetsFiles, `jstree-${orrEvent.OutbreakId}`, false);
            }
            else {
                let noKeyAssetsHtml = `<p>No Key Assets associated with this outbreak.</p>`;
                $(`#jstree-${orrEvent.OutbreakId}`).html(noKeyAssetsHtml).addClass("text-center");
            }
        }
        catch (err) {
            console.log("Error With Generating Document Tree");
            console.log(err);
            console.log("Key Asset Files for Outbreak:");
            console.log(this.keyAssetsFiles);
        }

        
    }

    initializeExternalResources(orrEvent: OrrEvent) {
        $("#modal-external-resources").empty();

        if (orrEvent.OrrExternalResources.length > 0) {
            let exResource = "<ul class='ml-2 mb-0'>";

            orrEvent.OrrExternalResources.forEach((resource) => {
                exResource += `<li class="mb-3"><a href="${resource.Url}" target="_blank">${resource.DisplayText}</a></li>`
            })

            exResource += "</ul>"

            $("#modal-external-resources").html(exResource);
        }
        else {
            let noResourcesHtml = "<p class='text-center'>No external resources associated with this outbreak.</p>"
            $("#modal-external-resources").html(noResourcesHtml);
        }
    }

    initializeFinancialSupportTable(orrEvent: OrrEvent) {
        // remove previous table if it already exists
        if (this.financialsTable != undefined) this.financialsTable.destroy();

        this.financialsTable = $('#financial-support-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls">prti',
            autoWidth: false,
            info: false,
            paging: true,
            pageLength: 5,
            search: false,
            scrollX: false,
            "language": {
                "emptyTable": "No Estimated Financial Support associated with this outbreak."
            },
            //scrollY: '70vh',
            orderCellsTop: true,
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '20px' }, //0
                { data: "DisplayName", className: "text-left font-size12" },
                { data: "DisplayAmount", className: "text-left font-size12" },//1
            ],
            data: orrEvent.OrrFinancials,
            initComplete: (settings, json) => {
                $("#financial-support-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        }); 

        if (orrEvent.OrrFinancials.length <= 5) {
            $("#financial-support-table_paginate").addClass("d-none");
        }
    }

    createFinancialDetailRow(data: OrrFinancial) {
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; width:100%">';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold" style="width:125px"><i>Research Center</i></td>' +
            '<td class="text-left font-size12">' + `${data.ResearchCenter ? data.ResearchCenter.Name : ''}` + '</td>' +
            '</tr>';

        if (data.SubFundingSource != "" && data.SubFundingSource != undefined) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12 font-weight-bold"><i>Funding Source</i></td>' +
                '<td class="text-left font-size12">' + `${data.FundingSource} (${data.SubFundingSource})` + '</td>' +
                '</tr>';
        } else {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12 font-weight-bold"><i>Funding Source</i></td>' +
                '<td class="text-left font-size12">' + data.FundingSource + '</td>' +
                '</tr>';
        }

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Amount</i></td>' +
            '<td class="text-left font-size12">' + data.DisplayAmount + '</td>' +
            '</tr>';

        detailRow += "</table>"
        return detailRow
    }

    initializeTigerTeamTable(orrEvent: OrrEvent) {
        // remove previous table if it already exists
        if (this.tigerTeamTable != undefined) this.tigerTeamTable.destroy();

        this.tigerTeamTable = $('#tiger-team-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls">prti',
            autoWidth: false,
            info: false,
            paging: true,
            pageLength: 5,
            search: false,
            scrollX: false,
            "language": {
                "emptyTable": "No Tiger Team associated with this outbreak."
            },
            //scrollY: '70vh',
            orderCellsTop: true,
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '20px' }, //0
                { data: "DisplayFullName", className: "text-left font-size12" },//1
            ],
            data: orrEvent.OrrTigerTeam,
            initComplete: (settings, json) => {
                $("#tiger-team-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        if (orrEvent.OrrTigerTeam.length <= 5) {
            $("#tiger-team-table_paginate").addClass("d-none");
        }
    }

    createTigerTeamRow(data: OrrTigerTeam) {
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; width:100%">';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold" style="width:100px"><i>Name</i></td>' +
            '<td class="text-left font-size12">' + data.DisplayFullName + '</td>' +
            '</tr>';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Email</i></td>' +
            '<td class="text-left font-size12">' + `<a href="mailto:${data.Email}">${data.Email}</a>` + '</td>' +
            '</tr>';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Organization</i></td>' +
            '<td class="text-left font-size12">' + data.Organization + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Research Center</i></td>' +
            '<td class="text-left font-size12">' + data.RCAffiliation + '</td>' +
            '</tr>';

        detailRow += "</table>"
        return detailRow
    }

    initializePartnersTable(orrEvent: OrrEvent) {
        // remove previous table if it already exists
        if (this.partnersTable != undefined) this.partnersTable.destroy();

        this.partnersTable = $('#partners-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls">prti',
            autoWidth: false,
            info: false,
            paging: true,
            pageLength: 5,
            search: false,
            scrollX: false,
            "language": {
                "emptyTable": "No Partners/Stakeholders associated with this outbreak."
            },
            //scrollY: '70vh',
            orderCellsTop: true,
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '20px' }, //0
                { data: "DisplayOrganization", className: "text-left font-size12" },//1
            ],
            data: orrEvent.OrrPartners,
            initComplete: (settings, json) => {
                $("#partners-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        }); 

        if (orrEvent.OrrPartners.length <= 5) {
            $("#partners-table_paginate").addClass("d-none");
        }
    }

    createPartnerRow(data: OrrPartner) {
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; width:100%">';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Organization</i></td>' +
            '<td class="text-left font-size12">' + data.Organization + '</td>' +
            '</tr>';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold" style="width:100px"><i>Name</i></td>' +
            '<td class="text-left font-size12">' + data.DisplayFullName + '</td>' +
            '</tr>';


        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 font-weight-bold"><i>Email</i></td>' +
            '<td class="text-left font-size12">' + `<a href="mailto:${data.Email}">${data.Email}</a>` + '</td>' +
            '</tr>';

        detailRow += "</table>"
        return detailRow
    }

    showHelpModal(): void {
        $("#outbreak-help-modal").modal('show');
    }
}