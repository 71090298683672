import { plainToClass } from "class-transformer";
import { UserDirectoryEntity } from "./directory.objects";
import { UserExperience, UserExperienceEntry } from "../../../auth/pages/profile/scripts/expertise.objects";
import { Unique, Split } from "../../../shared/scripts/array-functions";
import { ExpertiseArea } from "../../inventory/expertise/expertise.objects";


export class DirectoryPage {

    users: UserDirectoryEntity[];
    visibleUserEmails: string;
    dataTable: any;

    constructor(userData: any, userExpertiseData: any, expertiseAreasData: any) {
        $(() => {

            this.users = plainToClass(UserDirectoryEntity, <UserDirectoryEntity[]>userData);

            let expertiseList: UserExperienceEntry[] = plainToClass(UserExperienceEntry, <UserExperienceEntry[]>userExpertiseData);
            expertiseList.map((entry: UserExperienceEntry) => {
                let user: UserDirectoryEntity = this.users.find((user: UserDirectoryEntity) => user.Email == entry.Email);
                if (user != undefined) {
                    user.Expertise = plainToClass(UserExperience, <UserExperience>JSON.parse(entry.ExperienceJson));
                    user.HiddenFilter = user.Expertise.getHiddenFilter();
                    if (user.Expertise != undefined) { user.Expertise.checkOtherEntries(); }
                    //console.log(user);
                }
            });

            this.users = this.users.map((user: UserDirectoryEntity) => {
                user.initialize();
                user.setMembershipForOverallDirectory();
                return user;
            })

            let expertiseAreas: ExpertiseArea[] = plainToClass(ExpertiseArea, <ExpertiseArea[]>expertiseAreasData);
            this.initializeFilters(expertiseAreas);

            this.setupTabWatcher();
            //console.log(this.users);

            this.initializeTable();
        });
    }

    initializeFilters(expertiseAreas: ExpertiseArea[]): void {

        let values: string[] = [];
        this.users.forEach((user: UserDirectoryEntity) => { values.push(...Split(user.Network, ";<br>")); });
        this.initializeDropdown('#membership-member-dropdown', Unique(values, true, []));
        this.initializeDropdown('#membership-wg-dropdown', Unique(["Network Leadership", "Steering Committee (Members)", "Steering Committee (Others)", "Working Group Facilitator (Members)", "Working Group Facilitator (Others)", "Biorepository CoP (Members)", "Biorepository CoP (Others)", "Capacity Building WG (Members)", "Capacity Building WG (Others)", "Data Capture CoP (Members)", "Data Capture CoP (Others)", "Lab Assays CoP (Members)", "Lab Assays CoP (Others)", "Outbreak Research Response WG (Members)", "Outbreak Research Response WG (Others)", "Pilot Research Program (Members)", "Pilot Research Program (Others)", "Project Management CoP (Members)", "Project Management CoP (Others)" ,"RAD CoP (Members)", "RAD CoP (Others)"], false), undefined, false);

        this.initializeDropdown('#country-dropdown', Unique(this.users.map((user: UserDirectoryEntity) => user.Country), true, ["Not Available"]));

        this.initializeDropdown('#expertise-trainedas-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("degree")), false);
        this.initializeDropdown('#expertise-workas-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("experience")), false);
        this.initializeDropdown('#expertise-pathogen-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("pathogen")), false);
        this.initializeDropdown('#expertise-otherpathogens-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("otherpathogen")), false);
        this.initializeDropdown('#expertise-animaltypes-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("studytype")), false);
        this.initializeDropdown('#expertise-datascience-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("datascience")), false);
        this.initializeDropdown('#expertise-minion-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("minion")), false);
        this.initializeDropdown('#expertise-rawshort-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("rawshort")), false);
        this.initializeDropdown('#expertise-sequenceanalysis-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("statsequence")), false);
        this.initializeDropdown('#expertise-assay-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("assaydev")), false);
        this.initializeDropdown('#expertise-containment-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("contain")), false);
        this.initializeDropdown('#expertise-regulations-dropdown', undefined, expertiseAreas.filter((area: ExpertiseArea) => area.Id.startsWith("regulation")), false);

    }

    initializeDropdown(selectId: string, values: string[], compoundValues: ExpertiseArea[] = undefined, selected: boolean = true): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        if (compoundValues == undefined) {
            values.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, selected));
            });
        }
        else {
            compoundValues.forEach((element: ExpertiseArea) => {
                $(selectId).append(new Option(element.Expertise, element.Id, false, selected));
            });
        }

        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterUserDirectory();
        });
    }

    setupTabWatcher(): void {
        $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
            if (e.currentTarget.id == "membership-tab") {
                ['#membership-member-dropdown', '#membership-wg-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "expertise-general-tab") {
                ['#expertise-trainedas-dropdown', '#expertise-workas-dropdown', '#expertise-pathogen-dropdown', '#expertise-otherpathogens-dropdown', '#expertise-animaltypes-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "expertise-data-lab-tab") {
                ['#expertise-datascience-dropdown', '#expertise-minion-dropdown', '#expertise-rawshort-dropdown', '#expertise-sequenceanalysis-dropdown', '#expertise-assay-dropdown', '#expertise-containment-dropdown', '#expertise-regulations-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
        });
    }

    initializeTable(): void {
        let table = $('#directory-table').DataTable({
            //"dom": '<"top-controls"<"filtering-section"><"search-section"<"search-bar"fB>>>rtip',
            "dom": '<"top-controls"<"search-section"<"search-bar"fB>>>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '12px' },
                { data: "DisplayName", className: "text-left pl-0" },
                { data: "Network", className: "text-left", width: '15%' },
                { data: "ProjectRole", className: "text-left", width: '15%' },
                { data: "Organization", className: "text-left", width: '15%' },
                { data: "Email", className: "text-left", width: '15%' },
                { data: "Membership", visible: false },
                { data: "HiddenFilter", visible: false },
            ],        
            columnDefs: [{ //to sort by last name first
                targets: 1,
                data: "DisplayName",
                render: function (data, type, full, meta) {
                    if (type == 'sort') {
                        data = full.SortName;
                    }
                    return data;
                }
            }],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                },
                {
                    text: '<i class="fas fa-envelope"></i>',
                    titleAttr: 'Send Email',
                    action: (e, dt, node, config) => {
                        //console.log(this.visibleUserEmails);
                        //window.location.href = "mailto:" + this.visibleUserEmails;

                        //const temp = document.createElement("input");
                        //temp.setAttribute("value", this.visibleUserEmails);
                        //document.body.appendChild(temp);
                        //temp.select();
                        //document.execCommand("copy");
                        //document.body.removeChild(temp);

                        navigator.clipboard.writeText(this.visibleUserEmails).then(
                            () => {
                                $('#directory-email-modal').modal('show');
                                $('#modal-email-list').text(this.visibleUserEmails);
                            });


                    }
                }
            ],
            createdRow: (row, data: UserDirectoryEntity, index) => {
                if (data.Expertise == undefined) {
                    var td = $(row).find("td:first");
                    td.removeClass('details-control');
                }
            },
            data: this.users

        });
        this.dataTable = table;

        $(".top-controls").addClass('row');

        $(".search-section").addClass('col-12 mt-3');
        $(".search-bar").addClass('d-flex justify-content-end vertical-align-top align-content-start');
        //$(".search-section").append('<div class=\"table-message mt-3 mb-2 mb-md-0 text-right\"><span id=\"people-found-count\"></span></div>')

        $('#directory-table').on('search.dt', (e, settings) => {
            this.createEmailList(this.dataTable.rows({ search: 'applied' }).data());
            this.setPeopleFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
        this.createEmailList(this.users);
        this.setPeopleFoundLabel(this.users.length);

        //on td.details-control click
        $('#directory-table tbody').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);
            let data: UserDirectoryEntity = row.data();

            if (data.Expertise != undefined) {
                if (row.child.isShown()) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                }
                else {
                    // Open this row
                    row.child(this.createDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });
    }

    updateTable(matchingUsers: UserDirectoryEntity[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingUsers).draw();
        this.setPeopleFoundLabel(matchingUsers.length);
        this.createEmailList(matchingUsers);
    }

    filterUserDirectory(): void {

        let matchingUsers: UserDirectoryEntity[] = [];

        let userMatches: boolean;
        this.users.forEach((user: UserDirectoryEntity) => {

            userMatches = true; //must match all filters

            if (!user.matchesFilter(this.getSelectedDropdownValues('#membership-member-dropdown'), this.getSelectedDropdownValues('#membership-wg-dropdown'), this.getSelectedDropdownValues('#country-dropdown'),
                this.getSelectedDropdownValues('#expertise-trainedas-dropdown'), this.getSelectedDropdownValues('#expertise-workas-dropdown'),
                this.getSelectedDropdownValues('#expertise-pathogen-dropdown'), this.getSelectedDropdownValues('#expertise-otherpathogens-dropdown'),
                this.getSelectedDropdownValues('#expertise-animaltypes-dropdown'), this.getSelectedDropdownValues('#expertise-datascience-dropdown'),
                this.getSelectedDropdownValues('#expertise-minion-dropdown'), this.getSelectedDropdownValues('#expertise-rawshort-dropdown'),
                this.getSelectedDropdownValues('#expertise-sequenceanalysis-dropdown'), this.getSelectedDropdownValues('#expertise-assay-dropdown'),
                this.getSelectedDropdownValues('#expertise-containment-dropdown'), this.getSelectedDropdownValues('#expertise-regulations-dropdown'))) {
                userMatches = false;
            }

            if (userMatches) { matchingUsers.push(user); }
        });

        this.updateTable(matchingUsers);
    }

    getSelectedDropdownValues(selectId: string): any {
        //return $(selectId).selectpicker('val');
        return $(selectId).val();
    }

    setPeopleFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#people-found-count').text("Nobody Found"); break;
            case 1: $('#people-found-count').text("1 Person Found"); break;
            default: $('#people-found-count').text(count + " People Found");
        }
    }

    createEmailList(matchingUsers: UserDirectoryEntity[]): void {
        this.visibleUserEmails = matchingUsers.map((user: UserDirectoryEntity) => user.Email).join("; ");
        //console.log(this.visibleUserEmails);
    }

    showHelpModal(): void {
        $('#directory-help-modal').modal('show');
    }

    createDetailRow(data: UserDirectoryEntity): string {
        // `data` is the original data object for the row
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px; margin-left:50px;">';

        let value: string = data.Expertise.getTrainingDegreeString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Trained to work as a(n):</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getTrainingExperienceString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>2 or more years experience as a(n):</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getPathogenFamilyString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with pathogen families:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getOtherPathogensString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with other pathogens:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getStudyTypesString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise designing/conducting studies involving:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getDataScienceString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise in data science:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getAssayDevelopmentString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with assay development:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getContainmentLabString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with containment laboratory work:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getUsRegulationsString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with US Regulations:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getMinionAnalysisString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with minion/gridION analysis:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getRawShortReadAnalysisString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with raw short read sequencing analysis:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        value = data.Expertise.getStatisticalSequenceAnalysisString();
        if (value.length > 0) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Expertise with statistical methods for sequence analysis:</i></td>' +
                '<td class="text-left">' + value + '</td>' +
                '</tr>';
        }

        if (data.Expertise.OtherExpertise != undefined) {
            detailRow += '<tr class="detail-row"> ' +
                '<td class="text-left font-size12"><i>Other Expertise:</i></td>' +
                '<td class="text-left">' + data.Expertise.OtherExpertise + '</td>' +
                '</tr>';
        }

        detailRow += '</table>';

        return detailRow;
    }

    resetFilters(): void {

        $('#membership-member-dropdown').selectpicker('selectAll');
        $('#membership-member-dropdown').selectpicker('refresh');

        let deselectAllControls: string[] = ['#membership-wg-dropdown', '#expertise-trainedas-dropdown', '#expertise-workas-dropdown', '#expertise-pathogen-dropdown', '#expertise-otherpathogens-dropdown', '#expertise-animaltypes-dropdown', '#expertise-datascience-dropdown', '#expertise-minion-dropdown', '#expertise-rawshort-dropdown', '#expertise-sequenceanalysis-dropdown', '#expertise-assay-dropdown', '#expertise-containment-dropdown', '#expertise-regulations-dropdown'];
        deselectAllControls.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('deselectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();

        this.updateTable(this.users);
    }

}

