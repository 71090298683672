

export function FormatDate(date): string {

    if (date !== undefined && date !== "") {
        var myDate = new Date(date);
        var month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ][myDate.getMonth()];
        var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
        return str;
    }
    return "";
}

export function FormatDate2(date): string {

    if (date !== undefined && date !== "") {
        var year = date.split('-')[0];
        var monthNum = date.split('-')[1];
        var day = date.split('-')[2];

        var month = [
            "",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ][parseInt(monthNum)];

        var str = day + " " + month + " " + year;
        return str;
    }
    return "";
}

export function getDisplayDate(date: Date): string {
    if (date == null) {
        return "";
    }

    let convertDate = new Date(date);

    let year = convertDate.getFullYear();

    let month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ][convertDate.getMonth()];

    let day = convertDate.getDate().toString();

    return `${month} ${day}, ${year}`
}