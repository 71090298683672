import { LabAssay } from "./lab-assays.objects";
import { plainToClass } from "class-transformer";
import { SplitAndJoin, DoArraysOverlap } from "../../../shared/scripts/array-functions";


export class LabAssaysPage {

    labAssays: LabAssay[];
    dataTable: any;

    constructor(data: any) {
        $(() => {

            this.labAssays = plainToClass(LabAssay, <LabAssay[]>data);
            this.labAssays.map((assay: LabAssay) => {
                assay.initialize();
                return assay;
            });

            //console.log(this.labAssays);

            this.initializeTable();
            this.setAssaysFoundLabel(this.labAssays.length);
        });
    }

    initializeTable(): void {

        this.dataTable = $('#lab-assays-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"reset-section">Bf>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Filter",
                buttons: {
                    copyTitle: 'Lab Assays Data Copied',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }
            },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' }, //0
                { data: "Name", className: "text-left font-size12 pl-0" }, //1
                { data: "GeneralApproachDisplay", className: "text-left font-size12",width: '18%' }, //2
                { data: "VirusesDisplay", className: "text-left font-size12", width: '18%' }, //3
                { data: "SpecimenSourcesDisplay", className: "text-left font-size12", width: '12%' }, //4
                { data: "ResearchCenter", className: "text-left font-size12", width: '10%' }, //5
                { data: "Site", className: "text-left font-size12", width: '12%' }, //6
                { data: "Region", className: "text-left font-size12", width: '10%' }, //7
                { data: "Viruses", title: 'Viruses', visible: false }, //8
                { data: "SpecimenSources", title: 'SpecimenSources', visible: false }, //9
                { data: "GeneralApproachFilter", visible: false }, //10
                { data: "SpecimenSourcesFilter", visible: false }, //11
                { data: "VirusesFilter", visible: false }, //12
                { data: "Purpose", title: 'Purpose', visible: false }, //13
                { data: "Source", title: 'Source', visible: false }, //14
                { data: "DevelopmentStage", title: 'DevelopmentState', visible: false }, // 15
                { data: "AdaptedOutbreak", title: "AdaptedToOutbreak", visible: false }, //16
                { data: "SpecimenTypes", title: 'SpecimenTypes', visible: false }, //17
                { data: "BiosafetyLevel", title: 'BiosafetyLevel', visible: false }, //18
                { data: "RequireEquipment", title: "RequireSpecializedEquipment", visible: false }, //19
                { data: "EquipmentDescription", title: 'EquipmentDescription', visible: false }, //20
                { data: "RequireReagents", title: 'RequiresReagents', visible: false }, //21
                { data: "ReagentSource", title: 'ReagentSource', visible: false }, //22
                { data: "ReagentsDescription", title: 'ReagentDescription', visible: false }, //23
                { data: "Repositories", title: 'Repositories', visible: false }, //24
                { data: "ProvideTraining", title: "CanProvideTraining", visible: false }, //25
                { data: "ExperienceNeeded", title: "ExperienceNeeded1to5", visible: false }, //26
                { data: "ContactName", title: 'ContactName', visible: false }, //27
                { data: "ContactEmail", title: 'ContactEmail', visible: false }, //28
                { data: "SurgeCapacity", title: 'HasSurgeCapacity', visible: false }, //29
                { data: "Diseases", title: 'Diseases', visible: false }, //30
                { data: "Families", title: 'Families', visible: false }, //31
                { data: "GeneralApproach", title: 'GeneralApproach', visible: false }, //32
                //{ data: "Publications", visible: false }, //30
            ],
            buttons: [
                {
                    extend: 'copy',
                    text: '<i class="fas fa-copy"></i>',
                    titleAttr: 'Copy',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [1, 2, 8, 9, 5, 6, 7, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
                    },
                    title: ''
                }
                , {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [1, 2, 8, 9, 5, 6, 7, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
                    }
                },
            ],
            data: this.labAssays,
            //order: [[4, 'desc'], [7, 'desc']],
            order: [[1, 'asc'], [5, 'asc']],
            initComplete: (settings, json) => {
                $("#lab-assays-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        let resetSectionDiv: string =
            "<div>" +
            "<button type=\"button\" class=\"btn btn-sm btn-outline-secondary font-size12\" onclick=\"page.resetFilters()\">Reset Filters</button>" +
            "</div>"
            ;
        $(".reset-section").empty().html(resetSectionDiv);
        $(".reset-section").css('display', 'inline-block');
        $(".reset-section").css('float', 'left');
        $(".reset-section").css('margin-top', '5px');

        //on td.details-control removed
        $('#lab-assays-table tbody').on('click', 'tr', (event) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(event.currentTarget);
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                if (row.data() != undefined) {
                    // Open this row
                    row.child(this.createDetailRow(row.data())).show();
                    tr.addClass('shown');
                }
            }
        });

        $('#lab-assays-table').on('search.dt', (e, settings) => {
            this.setAssaysFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeFilters();
    }
    
    initializeFilters(): void {

        for (let index = 2; index <= 7; ++index) { //skip details and title column

            let uniqueValues: string[] = [];
            let delimiter: string = "";

            let filterIndex: number = index; 
            let dropdownSourceIndex: number = index;

            if (index == 2) { //General Approach
                dropdownSourceIndex = 32;  filterIndex = 10; delimiter = "|";
            }
            else if (index == 3) { //Pathogens
                dropdownSourceIndex = 8; filterIndex = 12; delimiter = "|";
            }
            else if (index == 4) { //Species Type
                dropdownSourceIndex = 9; filterIndex = 11; delimiter = "|";
            }

            this.dataTable.column(dropdownSourceIndex).data().unique().map((data: string) => {
                let parts: string[] = [];
                if (delimiter != "") { parts = data.split(delimiter); }
                else { parts[0] = data; }

                parts.map((part: string) => {
                    part = part.trim();
                    if (part.length > 0 && !uniqueValues.includes(part)) {
                        uniqueValues.push(part);
                    }
                });
            });

            uniqueValues = uniqueValues.sort((a: string, b: string) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            let selectId: string = '#filter-dropdown-' + index;

            $(selectId).selectpicker({
                dropupAuto: false,
                selectedTextFormat: "count",

                countSelectedText: (numSelected, numTotal) => {
                    if (numSelected == numTotal) {
                        return "All";
                    }
                    else {
                        return numSelected + " Selected";
                    }
                }
            });

            uniqueValues.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, true));
            });
            $(selectId).selectpicker("refresh");


            $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                let values: string[] = <string[]>($(selectId).val());
                //console.log("changed", values);

                let search: string = "";
                if (values.length > 0) {
                    //if (index == 4 || index == 5 || index == 6) { //more strict match
                        search = "(\\b" + values.join("\\b)|(\\b") + "\\b)";
                    //}
                    //else {
                    //    search = "^(" + values.join("|") + ")$";
                    //}
                }
                else { search = "NOTHING-MATCHED-1234567890"; }

                search = search.split("(").join("").split(")").join("");

                //if (filterIndex == 2) { //general approach
                //    filterIndex = 10;
                //}

                //search = $.fn.dataTable.util.escapeRegex(search)
                //search = search.split("[").join("\\[");
                //search = search.split("]").join("\\]");
                //console.log(search);

                this.triggerFilter();

                /*this.dataTable.column(filterIndex)
                    .search(search, true, false)
                    .draw();*/
            });

        }
    }

    createDetailRow(data: LabAssay): string {

        // `data` is the original data object for the row
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" class="detail-table">';

        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Assay, Procedure or Protocol Name</i></td>' +
        //    '<td class="text-left">' + data.Name + '</td>' +
        //    '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Purpose</i></td>' +
            '<td class="text-left">' + data.Purpose + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Assay Source</i></td>' +
            '<td class="text-left">' + data.Source + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Current Assay Development Stage</i></td>' +
            '<td class="text-left">' + data.DevelopmentStage + '</td>' +
            '</tr>';

        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Specific to one or multiple pathogens/agents?</i></td>' +
        //    '<td class="text-left">' + data.OneOrManyPathogens + '</td>' +
        //    '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Diseases</i></td>' +
            '<td class="text-left">' + SplitAndJoin(data.Diseases, "|", ", ", false) + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Virus Families</i></td>' +
            '<td class="text-left">' + SplitAndJoin(data.Families, "|", ", ", false) + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Adapted to Outbreak Response</i></td>' +
            '<td class="text-left">' + data.AdaptedOutbreak + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Specimen Types</i></td>' +
            '<td class="text-left">' + data.SpecimenTypesDisplay + '</td>' +
            '</tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Required Biosafety Level</i></td>' +
            '<td class="text-left">' + data.BiosafetyLevel + '</td>' +
            '</tr>';

        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Require compliance with US CDC Federal Select Agent Program (or equivalent)?</i></td>' +
        //    '<td class="text-left">' + data.CdcCompliant + '</td>' +
        //    '</tr>';

        //if (data.CdcCompliant == "Yes") {
        //    detailRow += '<tr class="detail-row"> ' +
        //        '<td class="text-left font-size12 label-cell"><i>Select Agent(s)</i></td>' +
        //        '<td class="text-left">' + SplitAndJoin(data.CdcAgents, "|", ", ", false) + '</td>' +
        //        '</tr>';
        //}

        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Require specialized/atypical technical training?</i></td>' +
        //    '<td class="text-left">' + data.RequireTraining;
        //if (data.RequireTraining == "Yes") { detailRow += ' (' + data.TrainingDescription + ')'; }
        //detailRow += '</td></tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Require specialized major equipment/instrumentation?</i></td>' +
            '<td class="text-left">' + data.RequireEquipment;
        if (data.RequireEquipment == "Yes") { detailRow += ' (' + data.EquipmentDescription + ')'; }
        detailRow += '</td></tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Require critical specialized reagents or control/reference/standard material?</i></td>' +
            '<td class="text-left">' + data.RequireReagents;
        if (data.RequireReagents == "Yes") {
            let detailSource: string = "";
            if (data.ReagentSource != "") { detailSource += data.ReagentSource; }
            if (data.Repositories != "") { detailSource += ((detailSource.length > 0) ? ", " : "") + data.Repositories; }
            detailRow += ' (' + data.ReagentsDescription + ')';
        }
        detailRow += '</td></tr>';

        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Able to provide material to CREID network</i></td>' +
        //    '<td class="text-left">' + data.ProvideMaterial + '</td>' +
        //    '</tr>';
        //if (data.ProvideMaterial == "Yes") {
        //    detailRow += '<tr class="detail-row"> ' +
        //        '<td class="text-left font-size12 label-cell"><i>Amount of material that can be shared</i></td>' +
        //        '<td class="text-left">' + data.HowMuchMaterial + '</td>' +
        //        '</tr>';
        //}

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Can provide training to transfer assay/procedure to other CREID laboratories</i></td>' +
            '<td class="text-left">' + data.ProvideTraining + '</td>' +
            '</tr>';
        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Format of results/data report</i></td>' +
        //    '<td class="text-left">' + SplitAndJoin(data.ResultsFileFormat, "|", ", ", false) + '</td>' +
        //    '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Technical experience level needed to perform this assay, procedure, or protocol (1-Lowest to 5-Highest)</i></td>' +
            '<td class="text-left">' + data.ExperienceNeeded + '</td>' +
            '</tr>';
        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Average number of samples that can be completed in 1 week</i></td>' +
        //    '<td class="text-left">' + data.AvgSamplesPerWeek + '</td>' +
        //    '</tr>';
        //detailRow += '<tr class="detail-row"> ' +
        //    '<td class="text-left font-size12 label-cell"><i>Max number of samples that can be completed in 1 week</i></td>' +
        //    '<td class="text-left">' + data.MaxSamplesPerWeek + '</td>' +
        //    '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Have surge capacity</i></td>' +
            '<td class="text-left">' + data.SurgeCapacity + '</td>' +
            '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Expert Contact</i></td>' +
            '<td class="text-left">' + data.ContactName + ' (' + data.ContactEmail + ')</td>' +
            '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Country</i></td>' +
            '<td class="text-left">' + data.Country + '</td>' +
            '</tr>';
        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Protocol Document</i></td>' +
            '<td class="text-left">';

        if (data.UploadedDocument != "") {
            if (data.UploadedDocument.includes(".xlsx?")) {
                detailRow += "<span class='ml-1'><a href='" + data.UploadedDocument + "')' class='link'><i class='fas fa-file-excel mr-1'></i>Shared Document</a></span>";
            }
            else {
                detailRow += "<span class='ml-1'><a onclick='page.showDocument(&quot;" + escape(data.Name) + "&quot;,&quot;" + data.UploadedDocument + "&quot;)' class='link'><i class='fas fa-file-pdf mr-1'></i>Shared Document</a></span>";
            }
        }
        else {
            detailRow += "<i>Not Provided</i>";
        }
        detailRow += '</td></tr>';

        detailRow += '<tr class="detail-row"> ' +
            '<td class="text-left font-size12 label-cell"><i>Key Publications</i></td>' +
            '<td class="text-left">';
        if (data.PublicationsList.length > 0) {
            data.PublicationsList.forEach((pubId: string) => {
                detailRow += '<a href="https://pubmed.ncbi.nlm.nih.gov/' + pubId + '" target="_blank" class="butn small no-z mr-2 mb-2"><span>PubMed Record <i class="fa fa-external-link-alt link-icon" aria-hidden="true"></i></span></a>';
            });
        }
        else {
            detailRow += "<i>Not Provided</i>"
        }
        detailRow += "</td></tr>";

        //if (data.Source == "pubmed") {
        //    detailRow += '<tr class="detail-row">' +
        //        '<td></td>' +
        //        '<td class="text-right"><a href="' + data.Link + '" target="_blank" class="butn small"><span>PubMed Record <i class="fa fa-external-link-alt link-icon" aria-hidden="true"</i></span></a></td>' +
        //        '</tr>';
        //}
        //else if (data.Source == "local") {
        //    detailRow += '<tr class="detail-row">' +
        //        '<td></td>' +
        //        '<td class="text-right"><a href="' + data.Link + '" target="_blank" class="butn small"><span>View Publication</span></a></td>' +
        //        '</tr>';
        //}


        detailRow += '</table>';

        return detailRow;
    }
    
    //showAcronymsDialog(): void {
    //    $('#acronyms-modal').modal('show');
    //}

    resetFilters(): void {
        let filterIndex: number;
        for (let index = 6; index >= 2; --index) { //skip details and title column

            $('#filter-dropdown-' + index).selectpicker('selectAll');
            $('#filter-dropdown-' + index).selectpicker("refresh");

            filterIndex = index;
            if(index == 3) filterIndex = 12; //pathogens
            else if(index == 4) filterIndex = 11; //species
            this.dataTable.column(filterIndex).search('').draw();
        }
        this.dataTable.search('').draw();
        this.updateTable(this.labAssays);
    }

    setAssaysFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#assays-found-count').text("Nothing Found"); break;
            case 1: $('#assays-found-count').text("1 Found"); break;
            default: $('#assays-found-count').text(count + " Found");
        }
    }

    showDocument(name: string, url: string): void {

        //console.log(name, filename);

        var modal = $('#view-document-modal');
        modal.find('#filename').html(unescape(name));

        modal.find('#document-iframe').attr('src', url);

        //console.log(data.node.original.link);

        modal.modal('show');

    }

    submitNewAssay(): void {

        window.open("https://forms.office.com/r/kcmUnAHEuh", "_blank");

    }

    triggerFilter(): void {
        const allAssays = this.labAssays;

        // General Approach
        let filteredAssays = allAssays.filter(assay => DoArraysOverlap(assay.GeneralApproachFilter, $("#filter-dropdown-2").val()));

        // Visues(es)
        filteredAssays = filteredAssays.filter(assay => DoArraysOverlap(assay.VirusesFilter, $("#filter-dropdown-3").val()));

        // Species
        filteredAssays = filteredAssays.filter(assay => DoArraysOverlap(assay.SpeciesFilter, $("#filter-dropdown-4").val()));

        // Research Center
        filteredAssays = filteredAssays.filter(assay => DoArraysOverlap([assay.ResearchCenterFilter], $("#filter-dropdown-5").val()));

        // Site
        filteredAssays = filteredAssays.filter(assay => DoArraysOverlap([assay.SiteFilter], $("#filter-dropdown-6").val()));

        // Region
        filteredAssays = filteredAssays.filter(assay => DoArraysOverlap([assay.RegionFilter], $("#filter-dropdown-7").val()));

        this.updateTable(filteredAssays);
    }

    updateTable(matchingAssays: LabAssay[]): void {
        this.dataTable.clear();
        this.dataTable.rows.add(matchingAssays).draw();
        this.setAssaysFoundLabel(matchingAssays.length);
    }
}