import L, { Marker, Point } from "leaflet";
import { basemapLayer, featureLayer } from 'esri-leaflet';
import { SiteCapacity } from "./site-capacities.objects";


export class SiteCapacitiesMap {

    map: L.Map;
    basemapDetailedLayer: L.Layer;
    basemapGrayscaleLayer: L.Layer;
    markersLayerSite: L.LayerGroup;
    markerRenderer: L.Renderer;
    clusterLayer: any;

    constructor() { this.initializeMap(); }

    initializeMap(): void {
        $("#site-capacities-map").empty();

        this.map = L.map('site-capacities-map', {
            zoomSnap: 0.1,
            worldCopyJump: true
        });

        this.basemapDetailedLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {});
        this.basemapGrayscaleLayer = basemapLayer('Gray').addTo(this.map);

        this.markersLayerSite = L.layerGroup().addTo(this.map);

        this.setupMarkerCluster();
        this.clusterLayer = L.markerClusterGroup({
            maxClusterRadius: 10,
            //disableClusteringAtZoom: this.map.options.maxZoom + 1
            zoomToBoundsOnClick: false,
            iconCreateFunction: function (cluster) {
                return new L.DivIcon({ html: cluster.getChildCount() + '', className: 'site-marker-cluster', iconSize: L.point(18, 18) });
            },
            spiderfyShapePositions: function (count: number, centerPt: { y: number; x: number; }) {
                var distanceFromCenter = 25,
                    markerDistance = 25,
                    lineLength = markerDistance * (count - 1),
                    lineStart = centerPt.y - lineLength / 2,
                    res = [],
                    i;

                res.length = count;

                for (i = count - 1; i >= 0; i--) {
                    res[i] = new Point(centerPt.x - distanceFromCenter, lineStart + markerDistance * i);
                }

                return res;
            }
        } as any); //casted to 'any' as shapePositions not part of type

        this.clusterLayer.on('clusterclick', function (a) {
            //console.log(a);
            a.layer.spiderfy();
        });


        this.map.setView([28.5, 0], 2.05);

        this.markerRenderer = L.canvas({ padding: 0.5 });

        L.control.layers({
            "Detailed": this.basemapDetailedLayer,
            "Grayscale": this.basemapGrayscaleLayer
        }, {}).addTo(this.map);

        //$('#loading-indicator').hide();
        $('#site-capacities-map').show();
        this.map.invalidateSize();

        this.map.on("baselayerchange", (e: any) => this.setAttribution(e.name));
        this.setAttribution("Grayscale");

    }

    setAttribution(basemap: string): void {
        if (basemap == "Grayscale") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.esri.com">Esri</a>');
        }
        else if (basemap == "Detailed") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.openstreetmap.org/">OpenStreetMap</a>');
        }
    }

    setVisibleSites(sites: SiteCapacity[]) {
        if (this.map == undefined) { return; }

        if (this.map.hasLayer(this.markersLayerSite)) {
            this.markersLayerSite.clearLayers();
        }
        if (this.map.hasLayer(this.clusterLayer)) {
            this.clusterLayer.clearLayers();
        }

        sites.forEach((site: SiteCapacity) => {

            if (site.IsMapCluster) {
            //if (site.Country == "Senegal") {
                this.clusterLayer.addLayer((<any>L).shapeMarker([site.CoordLat, site.CoordLon], {
                    shape: 'triangle',
                    color: '#51344d',
                    radius: 5,
                    weight: 1,
                    fillOpacity: 0.5
                }).addTo(this.markersLayerSite).bindPopup(this.createSitePopupContent(site)));
            }
            else {
                (<any>L).shapeMarker([site.CoordLat, site.CoordLon], {
                    shape: 'triangle',
                    color: '#51344d',
                    radius: 5,
                    weight: 1,
                    fillOpacity: 0.5
                }).addTo(this.markersLayerSite).bindPopup(this.createSitePopupContent(site));
            }
        });

        this.map.addLayer(this.clusterLayer);
    }

    createSitePopupContent(site: SiteCapacity): string {

        let popupContent: string = "<div class='map-popup'>";
        popupContent += "<div class='title'>" + site.SiteName + " </div>";
        popupContent += "<div class='location font-italic'>" + site.ResearchCenter.RcNameDisplay + "</div>";

        popupContent += "<div class='location font-weight-bold mt-2 pt-2 poc-name'>" + site.SiteOverview?.PocName + "</div>";
        popupContent += "<div class='location'>" + site.SiteOverview?.PocTitle + "</div>";
        popupContent += "<div class='location'>" + site.SiteOverview?.PocEmail + "</div>";

        //popupContent += "<table><tr><th>Pathogens</th></tr>";
        //site.pathogens.forEach(pathogen => {
        //    popupContent += "<tr><td>" + pathogen + "</td></tr>";
        //});
        //popupContent += "</table>";
        popupContent += "</div>";
        return popupContent;
    }

    refresh(): void {

        //if (this.map == undefined) {
        //    this.initializeMap();
        //}
        //else {
            this.map.invalidateSize();
            this.map.setView([28.5, 0], 2.05);
        //}
    }


    setupMarkerCluster(): void {
        L.MarkerCluster.include({
            spiderfy: function () {
                if (this._group._spiderfied === this || this._group._inZoomAnimation) {
                    return;
                }

                var childMarkers = this.getAllChildMarkers(null, true),
                    group = this._group,
                    map = group._map,
                    center = map.latLngToLayerPoint(this._latlng),
                    positions;

                this._group._unspiderfy();
                this._group._spiderfied = this;

                //TODO Maybe: childMarkers order by distance to center

                if (this._group.options.spiderfyShapePositions) {
                    positions = this._group.options.spiderfyShapePositions(childMarkers.length, center);
                } else if (childMarkers.length >= this._circleSpiralSwitchover) {
                    positions = this._generatePointsSpiral(childMarkers.length, center);
                } else {
                    center.y += 10; // Otherwise circles look wrong => hack for standard blue icon, renders differently for other icons.
                    positions = this._generatePointsCircle(childMarkers.length, center);
                }

                this._animationSpiderfy(childMarkers, positions);
            }
        });
    }

}