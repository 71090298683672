import { getDisplayDate } from './../../../../shared/scripts/date-functions';
import { get } from 'jquery';
import { Path } from 'leaflet';
export class BiodirectoryResearchSite {
    Id: number;
    ResearchSiteId: number;
    LastModifiedDate: Date;
    LastReviewDate: Date;

    SpecimenPOCFirstName: string;
    SpecimenPOCLastName: string;
    SpecimenPOCEmail: string;

    ReagentPOCFirstName: string;
    ReagentPOCLastName: string;
    ReagentPOCEmail: string;

    SpecimenPathogenListIds: string;
    ReagentPathogenListIds: string;

    // Virtuals
    LastModifiedDateDisplay: string;
    LastReviewDateDisplay: string;
    MoreRecentDate: Date;
    MoreRecentDateDisplay: string;
    ShouldReview: boolean;

    constructor(site: BiodirectoryResearchSite) {
        this.Id = site.Id;
        this.ResearchSiteId = site.ResearchSiteId;

        this.LastModifiedDate = site.LastModifiedDate;
        this.LastModifiedDateDisplay = site.LastModifiedDateDisplay;
        this.LastReviewDate = site.LastReviewDate;
        this.LastReviewDateDisplay = site.LastReviewDateDisplay;

        this.SpecimenPOCFirstName = site.SpecimenPOCFirstName;
        this.SpecimenPOCLastName = site.SpecimenPOCLastName;
        this.SpecimenPOCEmail = site.SpecimenPOCEmail;

        this.ReagentPOCFirstName = site.ReagentPOCFirstName;
        this.ReagentPOCLastName = site.ReagentPOCLastName;
        this.ReagentPOCEmail = site.ReagentPOCEmail;

        this.SpecimenPathogenListIds = site.SpecimenPathogenListIds;
        this.ReagentPathogenListIds = site.ReagentPathogenListIds;
    }

    initialize(): void {
        this.LastModifiedDateDisplay = getDisplayDate(this.LastModifiedDate);
        this.LastReviewDateDisplay = getDisplayDate(this.LastReviewDate);

        if (this.LastModifiedDate.getTime() > this.LastReviewDate.getTime()) {
            this.MoreRecentDate = this.LastModifiedDate;
            this.MoreRecentDateDisplay = this.LastModifiedDateDisplay;
        }
        else {
            this.MoreRecentDate = this.LastReviewDate;
            this.MoreRecentDateDisplay = this.LastReviewDateDisplay;
        }

        let reviewCheckDate = new Date();

        // review/update every 3 months
        reviewCheckDate.setMonth(reviewCheckDate.getMonth() - 2);
        
        if (reviewCheckDate.getTime() > this.MoreRecentDate.getTime()) {
            this.ShouldReview = true;
        }
        else {
            this.ShouldReview = false;
        }
        
    }
}

export class BiodirectorySpecimen {
    Id: number;
    BiodirectorySiteId: number;
    CreateDate: Date;
    LastModifiedDate: Date;

    SpecimenSource: string;
    SpecimenType: string;
    PathogenIds: string;

    PathogenIdArray: string[] = [];

    constructor() {

    }

    initialize(): void {
        if (this.PathogenIds == null || this.PathogenIds == undefined || this.PathogenIds == "") {
            this.PathogenIdArray = [];
        }
        else {
            this.PathogenIdArray = this.PathogenIds.split("|");
        }
    }

    static createBiodirectorySpecimen(siteId: number, source: string, type: string): BiodirectorySpecimen {
        let obj = new BiodirectorySpecimen();
        obj.BiodirectorySiteId = siteId;
        obj.SpecimenSource = source;
        obj.SpecimenType = type;
        obj.PathogenIds = "";

        return obj;
    }

    static createBiodirectorySpecimenFromObj(specimen: BiodirectorySpecimen): BiodirectorySpecimen {
        let obj = new BiodirectorySpecimen();
        obj.Id = specimen.Id;
        obj.BiodirectorySiteId = specimen.BiodirectorySiteId;
        obj.CreateDate = specimen.CreateDate;
        obj.LastModifiedDate = specimen.LastModifiedDate;

        obj.SpecimenSource = specimen.SpecimenSource;
        obj.SpecimenType = specimen.SpecimenType;
        obj.PathogenIds = specimen.PathogenIds;

        return obj;
    }
}

export class BiodirectoryReagent {
    Id: number;
    BiodirectorySiteId: number;
    CreateDate: Date;
    LastModifiedDate: Date;

    ReagentSource: string;
    ReagentType: string;
    PathogenIds: string;

    PathogenIdArray: string[] = [];

    initialize(): void {
        if (this.PathogenIds == null || this.PathogenIds == undefined || this.PathogenIds == "") {
            this.PathogenIdArray = [];
        }
        else {
            this.PathogenIdArray = this.PathogenIds.split("|");
        }
    }

    static createBiodirectoryReagent(siteId: number, source: string, type: string): BiodirectoryReagent {
        let obj = new BiodirectoryReagent();
        obj.BiodirectorySiteId = siteId;
        obj.ReagentSource = source;
        obj.ReagentType = type;
        obj.PathogenIds = "";

        return obj;
    }

    static createBiodirectoryReagentFromObj(reagent: BiodirectoryReagent): BiodirectoryReagent {
        let obj = new BiodirectoryReagent();
        obj.Id = reagent.Id;
        obj.BiodirectorySiteId = reagent.BiodirectorySiteId;
        obj.CreateDate = reagent.CreateDate;
        obj.LastModifiedDate = reagent.LastModifiedDate;

        obj.ReagentSource = reagent.ReagentSource;
        obj.ReagentType = reagent.ReagentType;
        obj.PathogenIds = reagent.PathogenIds;

        return obj;
    }
}

export class ResearchCenter {
    //Id: number;
    ResearchCenterId: number;
    Name: string;
}

export class ResearchSite {
    //Id: number;
    ResearchSiteId: number;
    ResearchCenterId: number;
    Name: string;
    CountryId: number;
    Latitude: number;
    Longitude: number;
}

export class Country {
    //Id: number;
    CountryId: number;
    Name: string;
    Code: string;
    Region: string;
}

export class Pathogen {
    Id: number;
    PathogenId: number;
    VirusId: string;
    VirusName: string;
    Family: string;
    Disease: string;

    IsSelected: boolean = false;
    SelectorDisplay: string = `<div class="form-check"><input class="form-check-input" type="checkbox"></div>`;

    initialize() {
        this.PathogenId = this.Id;
    }

    updateSelect(selectStatus: boolean): void {
        this.IsSelected = selectStatus;

        if (this.IsSelected) {
            this.SelectorDisplay = `<div class="form-check"><input class="form-check-input" type="checkbox" checked></div>`
        }
        else {
            this.SelectorDisplay = `<div class="form-check"><input class="form-check-input" type="checkbox"></div>`
        }
    }
}

export class BiodirectoryCombinedEntity {
    Id: number;
    BiodirectoryResearchSite: BiodirectoryResearchSite;
    BiodirectorySpecimens: BiodirectorySpecimen[];
    BiodirectoryReagents: BiodirectoryReagent[];
    ResearchCenter: ResearchCenter;
    ResearchSite: ResearchSite;
    Country: Country;

    SpecimenSourceString: string = "";
    SpecimenTypeString: string = "";
    ReagentSourceString: string = "";
    ReagentTypeString: string = "";
    PathogenString: string = "";
    DisplaySiteName: string = "";

    initialize(): void {
        this.DisplaySiteName = `<a class='font-weight-bold' onclick='page.openSiteModal(${this.Id})'>${this.ResearchSite.Name}</a>`;

        // Set Up Biodirectory Research Site Dates
        this.BiodirectoryResearchSite.LastModifiedDateDisplay = getDisplayDate(this.BiodirectoryResearchSite.LastModifiedDate);
        this.BiodirectoryResearchSite.LastReviewDateDisplay = getDisplayDate(this.BiodirectoryResearchSite.LastReviewDate);

        if (new Date(this.BiodirectoryResearchSite.LastModifiedDate).getTime() > new Date(this.BiodirectoryResearchSite.LastReviewDate).getTime()) {
            this.BiodirectoryResearchSite.MoreRecentDate = this.BiodirectoryResearchSite.LastModifiedDate;
            this.BiodirectoryResearchSite.MoreRecentDateDisplay = this.BiodirectoryResearchSite.LastModifiedDateDisplay;
        }
        else {
            this.BiodirectoryResearchSite.MoreRecentDate = this.BiodirectoryResearchSite.LastReviewDate;
            this.BiodirectoryResearchSite.MoreRecentDateDisplay = this.BiodirectoryResearchSite.LastReviewDateDisplay;
        }


        // Get String Versions of Sources/Types
        let specimenSourceArray: string[] = [];
        let specimenTypeArray: string[] = [];
        let reagentSourceArray: string[] = [];
        let reagentTypeArray: string[] = [];

        this.BiodirectorySpecimens.forEach(specimen => {
            specimen.initialize();
            if (!specimenSourceArray.includes(specimen.SpecimenSource)) {
                specimenSourceArray.push(specimen.SpecimenSource);
            }
            if (!specimenTypeArray.includes(specimen.SpecimenType)) {
                specimenTypeArray.push(specimen.SpecimenType);
            }
        });
        this.BiodirectoryReagents.forEach(reagent => {
            reagent.initialize();
            if (!reagentSourceArray.includes(reagent.ReagentType)) {
                reagentSourceArray.push(reagent.ReagentType);
            }
            if (!reagentTypeArray.includes(reagent.ReagentSource)) {
                reagentTypeArray.push(reagent.ReagentSource);
            }
        });

        specimenSourceArray.sort();
        specimenTypeArray.sort();
        reagentSourceArray.sort();
        reagentTypeArray.sort();

        this.SpecimenSourceString = specimenSourceArray.join(", ");
        this.SpecimenTypeString = specimenTypeArray.join(", ");
        this.ReagentSourceString = reagentSourceArray.join(", ");
        this.ReagentTypeString = reagentTypeArray.join(", ");

        // Check if Item Needs to be reviewed

        let reviewCheckDate = new Date();

        // review/update every 3 months, also don't require review for sites with nothing
        reviewCheckDate.setMonth(reviewCheckDate.getMonth() - 3);

        if (reviewCheckDate.getTime() > new Date(this.BiodirectoryResearchSite.MoreRecentDate).getTime() && (specimenSourceArray.length > 0 || reagentSourceArray.length > 0)) {
            this.BiodirectoryResearchSite.ShouldReview = true;
        }
        else {
            this.BiodirectoryResearchSite.ShouldReview = false;
        }
    }

    setPathogenString(pathogenList: Pathogen[]) {
        let pathogenIdArray: string[] = [];
        this.BiodirectorySpecimens.forEach(specimen => {
            specimen.PathogenIdArray.forEach(pathogenId => {
                if (!pathogenIdArray.includes(pathogenId)) {
                    pathogenIdArray.push(pathogenId);
                }
            })
        });
        this.BiodirectoryReagents.forEach(reagent => {
            reagent.PathogenIdArray.forEach(pathogenId => {
                if (!pathogenIdArray.includes(pathogenId)) {
                    pathogenIdArray.push(pathogenId);
                }
            })
        });

        let pathogenNameArray: string[] = [];
        pathogenIdArray.forEach(pathogenId => {
            let numId = parseInt(pathogenId);
            let foundPathogen = pathogenList.find(pathogen => pathogen.PathogenId == numId);
            if (foundPathogen != undefined) {
                pathogenNameArray.push(foundPathogen.VirusName);
            }
        });

        pathogenNameArray.sort();
        this.PathogenString = pathogenNameArray.join(", ");
    }

    constructor() {

    }

    static createBiodirectoryCombinedEntity(combinedEntity: BiodirectoryCombinedEntity): BiodirectoryCombinedEntity {
        let obj = new BiodirectoryCombinedEntity();
        obj.Id = combinedEntity.Id;
        obj.BiodirectoryResearchSite = combinedEntity.BiodirectoryResearchSite;
        obj.BiodirectorySpecimens = combinedEntity.BiodirectorySpecimens;
        obj.BiodirectoryReagents = combinedEntity.BiodirectoryReagents;
        obj.ResearchCenter = combinedEntity.ResearchCenter;
        obj.ResearchSite = combinedEntity.ResearchSite;
        obj.Country = combinedEntity.Country;
 
        return obj;
    }
}

export class BiodirectoryContact {
    FirstName: string;
    LastName: string;
    Email: string;

    FullName: string;
    DropdownDisplay: string;

    initialize(): void {
        this.FullName = `${this.FirstName} ${this.LastName}`;
        this.DropdownDisplay = `${this.LastName}, ${this.FirstName}`;
    }
    constructor(firstName: string, lastName: string, email: string) {
        this.FirstName = firstName;
        this.LastName = lastName;
        this.Email = email;
        this.initialize();
    }
}

export class BiodirectorySourceType {
    Source: string;
    Type: string;

    DisplayName: string;
    SourceId: number;

    initialize(): void {
        this.DisplayName = `${this.Source} (${this.Type})`;
    }

    constructor(source: string, type: string, sourceId: number) {
        this.Source = source;
        this.Type = type;
        this.SourceId = sourceId;

        this.initialize();
    }
}

export class PathogenCardDisplay {
    Pathogen: Pathogen;
    Specimens: BiodirectorySpecimen[];
    Reagents: BiodirectoryReagent[];

    SpecimenSourceArray: string[] = [];
    SpecimenTypeArray: string[] = [];
    ReagentSourceArray: string[] = [];
    ReagentTypeArray: string[] = [];

    constructor(pathogen: Pathogen, specimens: BiodirectorySpecimen[], reagents: BiodirectoryReagent[]) {
        this.Pathogen = pathogen;
        this.Specimens = specimens;
        this.Reagents = reagents;

        this.initialize();
    }

    initialize(): void {
        let specimenSourceArray = [];
        let specimenTypeArray = [];
        this.Specimens.forEach(specimen => {
            specimen.initialize();
            if (!specimenSourceArray.includes(specimen.SpecimenSource)) {
                specimenSourceArray.push(specimen.SpecimenSource);
            }
            if (!this.SpecimenTypeArray.includes(specimen.SpecimenType)) {
                specimenTypeArray.push(specimen.SpecimenType);
            }
        });

        let reagentSourceArray = [];
        let reagentTypeArray = [];
        this.Reagents.forEach(reagent => {
            reagent.initialize();
            if (!reagentSourceArray.includes(reagent.ReagentType)) {
                reagentSourceArray.push(reagent.ReagentType);
            }
            if (!reagentTypeArray.includes(reagent.ReagentSource)) {
                reagentTypeArray.push(reagent.ReagentSource);
            }
        });

        specimenSourceArray.sort();
        specimenTypeArray.sort();
        reagentSourceArray.sort();
        reagentTypeArray.sort();

        this.SpecimenSourceArray = specimenSourceArray;
        this.SpecimenTypeArray = specimenTypeArray;
        this.ReagentSourceArray = reagentSourceArray;
        this.ReagentTypeArray = reagentTypeArray;
    }
}