import { plainToClass } from "class-transformer";
import { SplitAndJoin, FormatStringByWordLength, NoWordWrap, Split, Join, DoArraysOverlap, CheckArrayStartsWith } from "../../../../shared/scripts/array-functions";


export class SiteCapacity {

    SiteId: number;
    RcId: number;
    SiteName: string;
    CoordLat: number;
    CoordLon: number;
    IsMapCluster: boolean = false;
    SubRegion: string = "Unknown";
    Country: string = "Unknown";

    ResearchCenter: ResearchCenter;

    SiteOverviewJson: any;
    BiorepoJson: any;
    LabFacilityJson: any;
    OutbreakResearchJson: any;
    RegulatoryJson: any;
    ShippingProcurementJson: any;

    SiteOverview: SiteOverview;
    Biorepo: Biorepository;
    LabFacility: LabFacility;
    OutbreakResearch: OutbreakResearch;
    Regulatory: Regulatory;
    ShippingProcurement: ShippingProcurement;

    SiteNameDisplay: string;

    constructor() { }

    initialize(): void {
        this.SiteOverview = plainToClass(SiteOverview, <SiteOverview>this.SiteOverviewJson);
        this.Biorepo = plainToClass(Biorepository, <Biorepository>this.BiorepoJson);
        this.LabFacility = plainToClass(LabFacility, <LabFacility>this.LabFacilityJson);
        this.OutbreakResearch = plainToClass(OutbreakResearch, <OutbreakResearch>this.OutbreakResearchJson);
        this.Regulatory = plainToClass(Regulatory, <Regulatory>this.RegulatoryJson);
        this.ShippingProcurement = plainToClass(ShippingProcurement, <ShippingProcurement>this.ShippingProcurementJson);

        this.SiteOverview.initialize();

        this.Biorepo.initialize();
        this.LabFacility.initialize();
        this.OutbreakResearch.initialize();
        this.Regulatory.initialize();
        this.ShippingProcurement.initialize();
        this.ResearchCenter.initialize();

        this.SiteNameDisplay = FormatStringByWordLength(this.SiteName, 3);

        //if (this.LabFacility.HasLabFacilities == "No") {
        //    console.log(this);
        //}


        delete this.SiteOverviewJson;
        delete this.BiorepoJson;
        delete this.LabFacilityJson;
        delete this.OutbreakResearchJson;
        delete this.RegulatoryJson;
        delete this.ShippingProcurementJson;
    }

    matchesFilter(siteNames: any, subregions: any, countries: any): boolean {

        if (!siteNames.includes(this.SiteName)) return false;
        if (!subregions.includes(this.SubRegion)) return false;
        if (!countries.includes(this.Country)) return false;

        return true;

    }

    isCloseToPoint(x: number, y: number): boolean {
        //let radius: number = 0.25; //start with 1 degree
        //return (((x - this.CoordLat) * (x - this.CoordLat)) | ((y - this.CoordLon) * (y - this.CoordLon))) <= (radius * radius);

        return (this.CoordLat == x) && (this.CoordLon == y);
    }
}

export class Biorepository {
    HasBiorepository: string = "Unknown";
    SampleTypes: string = "Unknown";
    SpeciesStored: string = "Unknown";
    LiveIsolates: string = "Unknown";
    HasSurgeCapacityToCollectProcessStore: string = "Unknown";
    BiosafetyLevels: string = "Unknown";
    ApprovedForSelectAgents: string = "Unknown";
    //ShippingCapacity: string = "Unknown";

    HasSops: string = "";
    Temperature: string = "";
    HasExternalAudit: string = "";
    OperateUnderQualitySystems: string = "";
    HasSystemToTrackSamples: string = "";
    LevelOfExperienceDuringOutbreak: string = "";
    HasLiquidNitrogen: string = "";
    SelectAgents: string = "";
    BackupGenerator: string = "";
    StaffTraining: string = "";
    ShareArchivalSamples: string = "";

    SampleTypesDisplay: string = "";
    SampleTypesList: string[] = [];
    SpeciesStoredDisplay: string = "";
    SpeciesStoredList: string[] = [];
    LiveIsolatesDisplay: string = "";
    LiveIsolatesList: string[] = [];
    BiosafetyLevelsDisplay: string = "";
    BiosafetyLevelsList: string[] = [];
    //ShippingCapacityDisplay: string = "";
    //ShippingCapacityList: string[] = [];

    initialize(): void {
        if (this.HasBiorepository == "No") {
            this.SampleTypes = this.BiosafetyLevels = this.SpeciesStored = this.LiveIsolates = this.ApprovedForSelectAgents = this.HasSurgeCapacityToCollectProcessStore = "Not Applicable";
        }

        this.SampleTypesList = Split(this.SampleTypes, "|");
        this.SampleTypesDisplay = Join(this.SampleTypesList, ", ", true, true);
        this.SampleTypesList = CheckArrayStartsWith(this.SampleTypesList, "Other", "Other");
        this.BiosafetyLevelsList = Split(this.BiosafetyLevels, "|");
        this.BiosafetyLevelsDisplay = Join(this.BiosafetyLevelsList, ", ", true, true);
        this.SpeciesStoredList = Split(this.SpeciesStored, "|");
        this.SpeciesStoredDisplay = Join(this.SpeciesStoredList, ", ", true, true);
        this.SpeciesStoredList = CheckArrayStartsWith(this.SpeciesStoredList, "Other", "Other");

        //this.ShippingCapacityList = Split(this.ShippingCapacity, "|");
        //this.ShippingCapacityDisplay = Join(this.ShippingCapacityList, ", ", true, true);
        //this.SpeciesStoredList = CheckArrayStartsWith(this.SpeciesStoredList, "Other", "Other");
        this.LiveIsolatesList = Split(this.LiveIsolates, "|");
        this.LiveIsolatesDisplay = Join(this.LiveIsolatesList, ", ", true, true);
        this.LiveIsolatesList = CheckArrayStartsWith(this.LiveIsolatesList, "Other", "Other");

        this.Temperature = SplitAndJoin(this.Temperature, "|", ", ", true, true);
        this.Temperature = this.Temperature.split("?").join("&deg;");

        this.SelectAgents = SplitAndJoin(this.SelectAgents, "|", ", ", true, true);
    }

    matchesFilter(hasBiorepo: any, sampleTypes: any, speciesStored: any, biosafety: any, shipping: any, liveIsolates: any, selectAgents: any, surgeCapacity: any): boolean {

        if (!hasBiorepo.includes(this.HasBiorepository)) return false;
        if (!DoArraysOverlap(sampleTypes, this.SampleTypesList)) return false;
        if (!DoArraysOverlap(biosafety, this.BiosafetyLevelsList)) return false;
        if (!DoArraysOverlap(speciesStored, this.SpeciesStoredList)) return false;
        //if (!DoArraysOverlap(shipping, this.ShippingCapacityList)) return false;
        if (!DoArraysOverlap(liveIsolates, this.LiveIsolatesList)) return false;
        if (!selectAgents.includes(this.ApprovedForSelectAgents)) return false;
        if (!surgeCapacity.includes(this.HasSurgeCapacityToCollectProcessStore)) return false;
        //if (!DoArraysOverlap(surgeCapacity, this.HasSurgeCapacityToCollectProcessStore)) return false;

        return true;
    }
}

export class LabFacility {
    HasLabFacilities: string = "Unknown";
    PrimarySiteResearchFocus: string = "Unknown";
    ResearchPerformed: string = "Unknown";
    BiosafetyLevels: string = "Unknown";
    RegisteredWithSelectAgentProgram: string = "Unknown";

    ExternalSystemOversight: string = "";
    ParticipateInAccreditationProficiencyTesting: string = "";
    ExternalAuditReview: string = "";
    ExternalReviewers: string = "";
    ExternalProficiencyTesting: string = "";
    NameOfExternalProgram: string = "";
    SelectAgents: string = "";
    RequireAdditionalTraining: string = "";
    AdditionalTraining: string = "";
    HasNovelTechnologyPlatform: string = "";
    NovelTechnologyPlatform: string = "";
    ExperienceLevelRequired: string = "";
    LabAssistanceNeed: string = "";
    PreClinicalAnimalStudies: string = "";
    PreclinicalSpeciesAndModels: string = "";

    PrimarySiteResearchFocusDisplay: string = "";
    PrimarySiteResearchFocusList: string[] = [];
    ResearchPerformedDisplay: string = "";
    ResearchPerformedList: string[] = [];
    BiosafetyLevelsDisplay: string = "";
    BiosafetyLevelsList: string[] = [];

    initialize(): void {
        if (this.HasLabFacilities == "No") {
            this.PrimarySiteResearchFocus = this.ResearchPerformed = this.BiosafetyLevels = this.RegisteredWithSelectAgentProgram = "Not Applicable";
        }

        this.PrimarySiteResearchFocusList = Split(this.PrimarySiteResearchFocus, "|");
        this.PrimarySiteResearchFocusDisplay = Join(this.PrimarySiteResearchFocusList, ", ", true, true);
        this.ResearchPerformedList = Split(this.ResearchPerformed, "|");
        this.ResearchPerformedDisplay = Join(this.ResearchPerformedList, ", ", true, true);
        this.ResearchPerformedList = CheckArrayStartsWith(this.ResearchPerformedList, "Other", "Other");
        this.BiosafetyLevelsList = Split(this.BiosafetyLevels, "|");
        this.BiosafetyLevelsDisplay = Join(this.BiosafetyLevelsList, ", ", true, true);

        this.ParticipateInAccreditationProficiencyTesting = SplitAndJoin(this.ParticipateInAccreditationProficiencyTesting, "|", ", ", true, true);
        this.SelectAgents = SplitAndJoin(this.SelectAgents, "|", ", ", true, true);
        this.LabAssistanceNeed = SplitAndJoin(this.LabAssistanceNeed, "|", ", ", true, true);
    }

    matchesFilter(hasLabFacility: any, researchFocus: any, researchPerformed: any, biosafetyLevels: any): boolean {

        if (!hasLabFacility.includes(this.HasLabFacilities)) return false;
        if (!DoArraysOverlap(researchFocus, this.PrimarySiteResearchFocusList)) return false;
        if (!DoArraysOverlap(researchPerformed, this.ResearchPerformedList)) return false;
        if (!DoArraysOverlap(biosafetyLevels, this.BiosafetyLevelsList)) return false;

        return true;
    }

}

export class OutbreakResearch {
    OutbreakResponseAreas: string = "Unknown";
    AssociationWithNationalLabs: string = "Unknown";
    CollaborativeEntities: string = "Unknown";
    //OutbreakChallenge1: string = "";
    //OutbreakChallenge2: string = "";
    //OutbreakChallenge3: string = "";
    //RelationshipWithGovernment: string = "";
    //InCountryAuthorityDuringOutbreak: string = "";
    //RelationshipWithOneHealth: string = "";
    //InCountryInstitutions: string = "";
    //InCountryOrganizations: string = "";
    CurrentlyInvolvedInOneHealthInitiative: string = "";

    OutbreakResponseAreasDisplay: string = "";
    OutbreakResponseAreasList: string[] = [];
    AssociationWithNationalLabsDisplay: string = "";
    AssociationWithNationalLabsList: string[] = [];
    CollaborativeEntitiesDisplay: string = "";
    CollaborativeEntitiesList: string[] = [];

    initialize(): void {

        this.OutbreakResponseAreasList = Split(this.OutbreakResponseAreas, "|");
        this.OutbreakResponseAreasDisplay = Join(this.OutbreakResponseAreasList, ", ", true, true);
        this.OutbreakResponseAreasList = CheckArrayStartsWith(this.OutbreakResponseAreasList, "Other", "Other");
        this.AssociationWithNationalLabsList = Split(this.AssociationWithNationalLabs, "|");
        this.AssociationWithNationalLabsDisplay = Join(this.AssociationWithNationalLabsList, ", ", true, true);
        this.AssociationWithNationalLabsList = CheckArrayStartsWith(this.AssociationWithNationalLabsList, "Other", "Other");
        this.CollaborativeEntitiesList = Split(this.CollaborativeEntities, "|");
        this.CollaborativeEntitiesDisplay = Join(this.CollaborativeEntitiesList, ", ", true, true);
        this.CollaborativeEntitiesList = CheckArrayStartsWith(this.CollaborativeEntitiesList, "Other", "Other");

    //    this.RelationshipWithGovernment = SplitAndJoin(this.RelationshipWithGovernment, "|", ", ", true, true);
    //    this.RelationshipWithOneHealth = SplitAndJoin(this.RelationshipWithOneHealth, "|", ", ", true, true);
    //    this.InCountryInstitutions = SplitAndJoin(this.InCountryInstitutions, "|", ", ", true, true);
    //    this.InCountryOrganizations = SplitAndJoin(this.InCountryOrganizations, "|", ", ", true, true);
    }

    matchesFilter(areas: any, nationalLabs: any, collaborators: any): boolean {

        if (!DoArraysOverlap(areas, this.OutbreakResponseAreas)) return false;
        if (!DoArraysOverlap(nationalLabs, this.AssociationWithNationalLabs)) return false;
        if (!DoArraysOverlap(collaborators, this.CollaborativeEntities)) return false;

        return true;
    }
}

export class Regulatory {
    AnySharingRestrictions: string = "Unknown";
    RequireIndemnityClause: string = "Unknown";
    UtilizeCommonRule: string = "Unknown";
    //ApprovalRequirements: string = "Unknown";
    SharingRestrictions: string = "";
    CentralizedAssistanceNeed: string = "";
    IrbName: string = "";
    IrbMeetingFrequency: string = "";
    AverageReviewTimeline: string = "";
    MinimumExpeditedTimeline: string = "";
    //RequirePeriodicReview: string = "";
    //ContinuationDependOnPeriodicReview: string = "";
    //ImmediateReportingOfSAE: string = "";
    //ImmediateReportingOfProtocolViolations: string = "";
    //GreatestOutbreakIrbHurdles: string = "";

    initialize(): void {
        this.CentralizedAssistanceNeed = SplitAndJoin(this.CentralizedAssistanceNeed, "|", ", ", true, true);
    }

    //matchesFilter(siteCommonRule: any, irbApprovalRequirements: any, indemnity: any, sharingRestrictions: any): boolean {
    matchesFilter(siteCommonRule: any, indemnity: any, sharingRestrictions: any): boolean {

        if (!DoArraysOverlap(indemnity, this.RequireIndemnityClause)) return false;
        if (!DoArraysOverlap(sharingRestrictions, this.AnySharingRestrictions)) return false;
        if (!DoArraysOverlap(siteCommonRule, this.UtilizeCommonRule)) return false;
        //if (!DoArraysOverlap(irbApprovalRequirements, this.ApprovalRequirements)) return false;

        return true;
    }
}

export class ShippingProcurement {
    ShippingCapacity: string = "Unknown";
    TrainingAndCertification: string = "Unknown";
    AverageReceivingTime: string = "Unknown";
    PercentSuppliedWithinCountry: string = "Unknown";
    AbleToObtainImportExportPermits: string = "Unknown";
    InternationalShippingMethod: string = "Unknown";
    AccessToInternationalCouriers: string = "";
    AbleToObtainPermits: string = "";
    TypesOfTraining: string = "";
    AccessToLargerPortal: string = "";
    TimelineToObtainPermits: string = "";

    ShippingCapacityDisplay: string = "";
    ShippingCapacityList: string[] = [];
    InternationalShippingMethodDisplay: string = "";
    InternationalShippingMethodList: string[] = [];

    initialize(): void {
        //this.CentralizedAssistanceNeed = SplitAndJoin(this.CentralizedAssistanceNeed, "|", ", ", true, true);

        this.ShippingCapacityList = Split(this.ShippingCapacity, "|");
        this.ShippingCapacityDisplay = Join(this.ShippingCapacityList, ", ", true, true);
        this.InternationalShippingMethodList = Split(this.InternationalShippingMethod, "|");
        this.InternationalShippingMethodDisplay = Join(this.InternationalShippingMethodList, ", ", true, true);
    }

    matchesFilter(shipping: any, training: any, receivingTime: any, suppliedWithinCountry: any, permits: any, internationalShipping: any): boolean {

        if (!DoArraysOverlap(shipping, this.ShippingCapacityList)) return false;
        if (!DoArraysOverlap(training, this.TrainingAndCertification)) return false;
        if (!DoArraysOverlap(receivingTime, this.AverageReceivingTime)) return false;
        if (!DoArraysOverlap(suppliedWithinCountry, this.PercentSuppliedWithinCountry)) return false;
        if (!DoArraysOverlap(permits, this.AbleToObtainImportExportPermits)) return false;
        if (!DoArraysOverlap(internationalShipping, this.InternationalShippingMethodList)) return false;

        return true;
    }
}

export class SiteOverview {
    OrganizationType: string = "Unknown";
    PathogensLabAssays: string = "Unknown";
    Disciplines: string = "Unknown";
    FieldStudyTypeExperience: string = "Unknown";

    CapacityBuildingTraining: string = "";
    BarrierToCapacityTraining: string = "";
    PerformsResearch: string = "";
    PathogensActivelyStudied: string = "";
    PathogensStudiesInPast: string = "";
    TypesOfAnimalFieldStudies: string = "";
    TypesOfLivestockFieldStudies: string = "";
    TypesOfEntomologyFieldStudies: string = "";
    TypesOfHumanFieldStudies: string = "";
    PocFirstName: string = "";
    PocLastName: string = "";
    PocEmail: string = "";
    PocTitle: string = "";

    OrganizationTypesList: string[] = [];
    DisciplinesDisplay: string = "";
    DisciplinesList: string[] = [];
    FieldStudyTypeExperienceDisplay: string = "";
    FieldStudyTypeExperienceList: string[] = [];
    PathogensDisplay: string = "";
    PathogensList: string[] = [];
    PocName: string = "";

    initialize(): void {
        this.OrganizationTypesList = [this.OrganizationType ];
        this.OrganizationTypesList = CheckArrayStartsWith(this.OrganizationTypesList, "Other", "Other");

        this.DisciplinesList = Split(this.Disciplines, "|");
        this.DisciplinesDisplay = Join(this.DisciplinesList, ", ", true, true);
        this.DisciplinesList = CheckArrayStartsWith(this.DisciplinesList, "Other", "Other");

        this.FieldStudyTypeExperienceList = Split(this.FieldStudyTypeExperience, "|");
        this.FieldStudyTypeExperienceDisplay = Join(this.FieldStudyTypeExperienceList, ", ", true, true);
        this.FieldStudyTypeExperienceList = CheckArrayStartsWith(this.FieldStudyTypeExperienceList, "Other", "Other");

        this.PathogensList = Split(this.PathogensLabAssays, "|");
        this.PathogensDisplay = Join(this.PathogensList, ", ", true, true);
        this.PathogensList = CheckArrayStartsWith(this.PathogensList, "Other", "Other");

        this.BarrierToCapacityTraining = SplitAndJoin(this.BarrierToCapacityTraining, "|", ", ", true, true);
        this.TypesOfAnimalFieldStudies = SplitAndJoin(this.TypesOfAnimalFieldStudies, "|", ", ", true, true);
        this.TypesOfLivestockFieldStudies = SplitAndJoin(this.TypesOfLivestockFieldStudies, "|", ", ", true, true);
        this.TypesOfEntomologyFieldStudies = SplitAndJoin(this.TypesOfEntomologyFieldStudies, "|", ", ", true, true);
        this.TypesOfHumanFieldStudies = SplitAndJoin(this.TypesOfHumanFieldStudies, "|", ", ", true, true);

        this.PocName = (this.PocFirstName + " " + this.PocLastName).trim();
        this.PocEmail = NoWordWrap(this.PocEmail);
    }

    matchesFilter(orgTypes: any, disciplineList: any, fieldTypes: any, pathogenList: any, ): boolean {

        //if (!orgTypes.includes(this.OrganizationType)) return false;
        if (!DoArraysOverlap(orgTypes, this.OrganizationTypesList)) { /*console.log("OrgType");*/ return false; }
        if (!DoArraysOverlap(disciplineList, this.DisciplinesList)) { /*console.log("Disciplines");*/ return false; }
        if (!DoArraysOverlap(pathogenList, this.PathogensLabAssays)) { /*console.log("Pathogens");*/ return false; }
        if (!DoArraysOverlap(fieldTypes, this.FieldStudyTypeExperience)) { /*console.log("FieldStudy");*/ return false; }

        return true;
    }
}

export class ResearchCenter {
    RcId: number;
    RcName: string = "";
    HubIrbCommonRule: string = "";
    HubSinceWhenCommonRule: string = "";
    HubHowManyIrbs: string = "";
    HubIrbNames: string = "";
    HubHowOftenIrbMeet: string = "";
    HubIrbReviewTime: string = "";
    HubIrbExpeditedReviewTime: string = "";
    HubIrbHurdles: string = "";

    RcNameDisplay: string = "";

    initialize(): void {
        this.RcNameDisplay = NoWordWrap(this.RcName);

        this.HubIrbNames = SplitAndJoin(this.HubIrbNames, "|", ", ", true, true);
    }

    matchesFilter(rcNames: any, rcIrbCommonRule: any): boolean {

        if (!rcNames.includes(this.RcName)) return false;
        if (!rcIrbCommonRule.includes(this.HubIrbCommonRule)) return false;

        return true;

    }
}