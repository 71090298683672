import { InitializeFilterDropdown, GetSelectedDropdownValues, ResetDropdownFilters, IFilter } from '../../../../shared/scripts/filtering';
import { DoArraysOverlap, Unique } from '../../../../shared/scripts/array-functions';
import { BiodirectoryCombinedEntity, BiodirectoryReagent, BiodirectorySpecimen, Pathogen, PathogenCardDisplay } from '../../biodirectory-form/scripts/biodirectory-form.objects'
import { plainToClass } from 'class-transformer';
import { Path } from 'leaflet';
export class BiodirectoryModal implements IFilter {  
    currentSite: BiodirectoryCombinedEntity = null;
    pathogenList: Pathogen[] = [];

    constructor(pathogenList: Pathogen[], callbackFn: Function = () => { }) {
        this.pathogenList = pathogenList;
        this.initializeFilters();

        // optionally run a callback function after the selector is generated
        callbackFn();
       
    }

    initializeFilters(): void {
        InitializeFilterDropdown('#modal-pathogens-dropdown', [], this);
        InitializeFilterDropdown('#modal-specimen-source-dropdown', [], this);
        InitializeFilterDropdown('#modal-specimen-type-dropdown', [], this);
        InitializeFilterDropdown('#modal-reagent-type-dropdown', [], this);
        InitializeFilterDropdown('#modal-reagent-source-dropdown', [], this);
    }

    prepopulateModal(site: BiodirectoryCombinedEntity) {
        this.currentSite = site;
        this.updateGeneralContent();
        this.updateFilterOptions();
    }

    updateGeneralContent(): void {
        if (this.currentSite == null) return;

        $("#biodirectory-site-title").text(this.currentSite.ResearchSite.Name);
        $("#biodirectory-site-rc").text(this.currentSite.ResearchCenter.Name);
        $("#biodirectory-site-country").text(`${this.currentSite.Country.Name} (${this.currentSite.Country.Region})`);
        $("#biodirectory-specimen-poc").html(`${this.currentSite.BiodirectoryResearchSite.SpecimenPOCEmail == null ? "Not Specified" : `${this.currentSite.BiodirectoryResearchSite.SpecimenPOCFirstName} ${this.currentSite.BiodirectoryResearchSite.SpecimenPOCLastName} (<a href='mailto:${this.currentSite.BiodirectoryResearchSite.SpecimenPOCEmail}'>${this.currentSite.BiodirectoryResearchSite.SpecimenPOCEmail}</a>)`}`);
        $("#biodirectory-reagent-poc").html(`${this.currentSite.BiodirectoryResearchSite.ReagentPOCEmail == null ? "Not Specified" : `${this.currentSite.BiodirectoryResearchSite.ReagentPOCFirstName} ${this.currentSite.BiodirectoryResearchSite.ReagentPOCLastName} (<a href='mailto:${this.currentSite.BiodirectoryResearchSite.ReagentPOCEmail}'>${this.currentSite.BiodirectoryResearchSite.ReagentPOCEmail}</a>)`}`);
        $("#biodirectory-last-updated").text(this.currentSite.BiodirectoryResearchSite.MoreRecentDateDisplay);
    }

    updateFilterOptions(): void {
        if (this.currentSite == null) return;

        // Empty Dropdowns
        $("#modal-pathogens-dropdown").empty();
        $("#modal-specimen-source-dropdown").empty();
        $("#modal-specimen-type-dropdown").empty();
        $("#modal-reagent-type-dropdown").empty();
        $("#modal-reagent-source-dropdown").empty();

        // Update Options ------
        // Pathogens
        let pathogenIds: string[] = [];
        this.currentSite.BiodirectorySpecimens.forEach(specimen => {
            specimen.PathogenIdArray.forEach(pathogenId => {
                if (!pathogenIds.includes(pathogenId)) {
                    pathogenIds.push(pathogenId);
                }
            })
        });
        this.currentSite.BiodirectoryReagents.forEach(reagent => {
            reagent.PathogenIdArray.forEach(pathogenId => {
                if (!pathogenIds.includes(pathogenId)) {
                    pathogenIds.push(pathogenId);
                }
            })
        });
        let foundPathogens: Pathogen[] = [];
        pathogenIds.forEach(pathogenId => {
            let numId = parseInt(pathogenId);
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == numId);
            if (foundPathogen != undefined) {
                foundPathogens.push(foundPathogen);
            }
        });
        foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));

        let selectedPathogens = GetSelectedDropdownValues("#specimen-pathogen-dropdown");
        foundPathogens.forEach(pathogen => {
            let isSelected = selectedPathogens.includes(pathogen.PathogenId.toString());
            $("#modal-pathogens-dropdown").append(new Option(pathogen.VirusName, pathogen.PathogenId.toString(), false, isSelected))
        });

        // Specimens
        let specimenSourceArray = this.currentSite.SpecimenSourceString.split(", ");
        let selectedSpecimenSources = GetSelectedDropdownValues("#specimen-source-dropdown");
        specimenSourceArray.forEach(source => {
            let isSelected = selectedSpecimenSources.includes(source);
            $("#modal-specimen-source-dropdown").append(new Option(source, source, false, isSelected));
        })
        let specimenTypeArray = this.currentSite.SpecimenTypeString.split(", ");
        let selectedSpecimenTypes = GetSelectedDropdownValues("#specimen-type-dropdown");
        specimenTypeArray.forEach(type => {
            let isSelected = selectedSpecimenTypes.includes(type);
            $("#modal-specimen-type-dropdown").append(new Option(type, type, false, isSelected));
        });

        // Reagents
        let reagentSourceArray = this.currentSite.ReagentSourceString.split(", ");
        let selectedReagentSources = GetSelectedDropdownValues("#reagent-source-dropdown");
        reagentSourceArray.forEach(source => {
            let isSelected = selectedReagentSources.includes(source);
            $("#modal-reagent-source-dropdown").append(new Option(source, source, false, isSelected));
        });
        let reagentTypeArray = this.currentSite.ReagentTypeString.split(", ");
        let selectedReagentTypes = GetSelectedDropdownValues("#reagent-type-dropdown");
        reagentTypeArray.forEach(type => {
            let isSelected = selectedReagentTypes.includes(type);
            $("#modal-reagent-type-dropdown").append(new Option(type, type, false, isSelected));
        })
        

        // Update Dropdowns
        $("#modal-pathogens-dropdown").selectpicker('refresh');
        $("#modal-specimen-source-dropdown").selectpicker('refresh');
        $("#modal-specimen-type-dropdown").selectpicker('refresh');
        $("#modal-reagent-type-dropdown").selectpicker('refresh');
        $("#modal-reagent-source-dropdown").selectpicker('refresh');

        this.triggerFilter();
     
    }

    updateCardDisplay(filteredPathogenCards: PathogenCardDisplay[]): void {
        $(".biodirectory-pathogen-section").empty();

        // For each pathogen, create a card
        let site = this.currentSite;
        let pathogenContent = `<div class='row' id='biodirectory-card-collection-${site.Id}'>`
        if (filteredPathogenCards.length == 0) {
            pathogenContent += `<div class='col-12 black-text font-size16 text-center font-italic my-3'>`;
            pathogenContent += "No Found Pathogens.";
        }
        else {
            for (let i = 0; i < filteredPathogenCards.length; i++) {
                let pathogenCard = filteredPathogenCards[i];
                let pathogen = pathogenCard.Pathogen;
                let foundSpecimens = pathogenCard.Specimens;
                let foundReagents = pathogenCard.Reagents;

                let specimenContent = "";
                if (foundSpecimens.length == 0) {
                    specimenContent = `<div class='font-italic font-size12'>No Associated Specimens</div>`;
                }
                else {
                    specimenContent = `<ul class="ml-3 mb-0 black-text">`
                    foundSpecimens.forEach(specimen => {
                        specimenContent += `<li class='font-size12'>${specimen.SpecimenSource} (${specimen.SpecimenType})</li>`
                    })
                    specimenContent += `</ul>`
                }

                let reagentContent = "";
                if (foundReagents.length == 0) {
                    reagentContent = `<div class='font-italic font-size12'>No Associated Reagents</div>`;
                }
                else {
                    reagentContent = `<ul class="ml-3 mb-0 black-text">`
                    foundReagents.forEach(reagent => {
                        reagentContent += `<li class='font-size12'>${reagent.ReagentSource} (${reagent.ReagentType})</li>`
                    })
                    reagentContent += `</ul>`
                }

                let cardContent = `
                <div class="card source-type-card mb-3">
                    <div class="card-header collapsed card-collapse" data-toggle="collapse" data-target="#site-pathogen-${i}">
                        <div class="source-header d-flex">
                            <div class="expand-section pr-2">
                                <i class="fa fa-plus-circle fa-lg source-expand-icon" aria-hidden="true"></i>
                            </div>
                            <div class="source-type-section">
                                <span class='font-weight-bold'>${pathogen.VirusName}</span><br/>
                            </div>
                        </div>
                    </div>
                    <div id="site-pathogen-${i}" class="collapse">
                        <div class="card-body">
                            <span class='black-text'><u>Specimens</u></span>                        
                            ${specimenContent}
                            <hr />
                            <span class='black-text'><u>Reagents</u></span>
                            ${reagentContent}
                        </div>
                    </div>
                </div>`

                let cardContainer = `
                <div class="col-md-3 col-12">
                    ${cardContent}
                </div>
                `

                pathogenContent += cardContainer;
            }
        }
        
        pathogenContent += `</div>`
        $(".biodirectory-pathogen-section").html(pathogenContent);

        // Custom Collapse Toggle
        $(".card-collapse").on('click', function () {
            
            let targetId = $(this).data("target");
            if ($(targetId).hasClass("show")) {
                $(this).removeClass("active");
            }
            else {
                $(this).addClass("active");
            }
        })
        
    }


    triggerFilter(): void {
        let filteredSpecimens = this.currentSite.BiodirectorySpecimens;
        let filteredReagents = this.currentSite.BiodirectoryReagents;

        // Get List of Pathogens
        let targetedPathogenIds: string[] = [];
        filteredSpecimens.forEach(specimen => {
            specimen.PathogenIdArray.forEach(pathogenId => {
                if (!targetedPathogenIds.includes(pathogenId)) {
                    targetedPathogenIds.push(pathogenId);
                }
            })
        });
        filteredReagents.forEach(reagent => {
            reagent.PathogenIdArray.forEach(pathogenId => {
                if (!targetedPathogenIds.includes(pathogenId)) {
                    targetedPathogenIds.push(pathogenId);
                }
            })
        })
        let selectedPathogenIds: string[] = GetSelectedDropdownValues("#modal-pathogens-dropdown");
        let foundPathogens: Pathogen[] = [];
        selectedPathogenIds.forEach(pathogenId => {
            let numId = parseInt(pathogenId);
            let foundPathogen = this.pathogenList.find(pathogen => pathogen.PathogenId == numId);
            if (foundPathogen != undefined) {
                if (targetedPathogenIds.includes(foundPathogen.PathogenId.toString())) {
                    foundPathogens.push(foundPathogen);
                }
            }
        });
        foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));

        let pathogenCards: PathogenCardDisplay[] = [];
        foundPathogens.forEach(pathogen => {
            let assocatiedSpecimens = filteredSpecimens.filter(specimen => specimen.PathogenIdArray.includes(pathogen.PathogenId.toString()));
            let associatedReagents = filteredReagents.filter(reagent => reagent.PathogenIdArray.includes(pathogen.PathogenId.toString()));

            pathogenCards.push(new PathogenCardDisplay(pathogen, assocatiedSpecimens, associatedReagents));
        })

        pathogenCards.sort((x1, x2) => x1.Pathogen.VirusName.localeCompare(x2.Pathogen.VirusName));

        let selectedSpecimenSources: string[] = GetSelectedDropdownValues("#modal-specimen-source-dropdown");
        if (selectedSpecimenSources.length > 0) {
            pathogenCards = pathogenCards.filter(pathogenCard => DoArraysOverlap(pathogenCard.SpecimenSourceArray, GetSelectedDropdownValues("#modal-specimen-source-dropdown")));
        }
        let selectedSpecimenTypes: string[] = GetSelectedDropdownValues("#modal-specimen-type-dropdown");
        if (selectedSpecimenTypes.length > 0) {
            pathogenCards = pathogenCards.filter(pathogenCard => DoArraysOverlap(pathogenCard.SpecimenTypeArray, GetSelectedDropdownValues("#modal-specimen-type-dropdown")));
        }
        let selectedReagentTypes: string[] = GetSelectedDropdownValues("#modal-reagent-type-dropdown");
        if (selectedReagentTypes.length > 0) {
            pathogenCards = pathogenCards.filter(pathogenCard => DoArraysOverlap(pathogenCard.ReagentTypeArray, GetSelectedDropdownValues("#modal-reagent-type-dropdown")));
        }
        let selectedReagentSources: string[] = GetSelectedDropdownValues("#modal-reagent-source-dropdown");
        if (selectedReagentSources.length > 0) {
            pathogenCards = pathogenCards.filter(pathogenCard => DoArraysOverlap(pathogenCard.ReagentSourceArray, GetSelectedDropdownValues("#modal-reagent-source-dropdown")))
        }


        

        this.updateCardDisplay(pathogenCards);
    }

    

}
