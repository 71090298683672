import { FormatDate2 } from './../../../shared/scripts/date-functions';

export class OrrEvent {

    ID: string;
    OutbreakId: string;
    Pathogens: Pathogen[];
    DisplayPathogens: string;
    Countries: Country[];
    DisplayCountries: string;
    ReportDate: string;
    DisplayReportDate: string;
    ClosedDate: string;
    DisplayClosedDate: string;
    LastUpdated: string;
    ResearchCenters: ResearchCenter[];
    DisplayResearchCenters: string;
    AccessInfo: string;
    Status: string;
    CollaborationOpportunities: string;
    SitutationReport: string;
    OrrKeyPersonnel: OrrKeyPerson[];
    OrrTigerTeam: OrrTigerTeam[];
    OrrPartners: OrrPartner[];
    OrrFinancials: OrrFinancial[];
    OrrExternalResources: OrrExternalResource[];

    ViewDetailsBtn: string;

    constructor() { }

    initialize(): void {
        this.DisplayPathogens = "";
        for (let i = 0; i < this.Pathogens.length; i++) {
            
            if (this.Pathogens[i] == null || this.Pathogens[i] == undefined) {
                this.Pathogens = [];
            }
            else {
                if (i == 0) this.DisplayPathogens += this.Pathogens[i].Virus;
                else this.DisplayPathogens += ", " + this.Pathogens[i].Virus;
            }         
        }

        this.DisplayCountries = "";
        for (let i = 0; i < this.Countries.length; i++) {
            if (this.Countries[i] == null || this.Countries[i] == undefined) {
                this.Countries = [];
            }
            else { 
                if (i == 0) this.DisplayCountries += this.Countries[i].CountryName;
                else this.DisplayCountries += ", " + this.Countries[i].CountryName;
            }
        }

        this.DisplayResearchCenters = "";
        for (let i = 0; i < this.ResearchCenters.length; i++) {
            if (this.ResearchCenters[i] == null || this.ResearchCenters[i] == undefined) {
                this.ResearchCenters = [];
            }
            else {
                if (i == 0) this.DisplayResearchCenters += this.ResearchCenters[i].Name;
                else this.DisplayResearchCenters += ", " + this.ResearchCenters[i].Name;
            }
        }

        this.OrrKeyPersonnel.forEach((keyPerson) => {
            keyPerson.DisplayFullName = `${keyPerson.FirstName} ${keyPerson.LastName}`;
            if (keyPerson.ResearchCenter) {
                keyPerson.DisplayOrganization = `${keyPerson.DisplayFullName} (${keyPerson.ResearchCenter.Name})`;
            }
            else {
                keyPerson.DisplayOrganization = keyPerson.DisplayFullName;
            }
        });
        this.OrrTigerTeam.forEach((person) => person.DisplayFullName = `${person.FirstName} ${person.LastName}`);
        this.OrrPartners.forEach((person) => {
            person.DisplayFullName = `${person.FirstName} ${person.LastName}`;
            if (person.DisplayFullName != " ") {
                person.DisplayOrganization = `${person.Organization} - <b class="text-nowrap">${person.DisplayFullName}</b>`;
            }
            else {
                person.DisplayOrganization = person.Organization;
            }
        });

        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.OrrFinancials.forEach((financial) => {
            if (parseInt(financial.Amount) != NaN) {
                financial.DisplayAmount = USDollar.format(parseInt(financial.Amount));
            }

            if (financial.SubFundingSource != "") {
                financial.DisplayName = `${financial.FundingSource} (${financial.SubFundingSource})`;
            }
            else {
                financial.DisplayName = financial.FundingSource;
            }
        })

        this.ViewDetailsBtn = "<a class='link-primary'>Record</a>"
        this.DisplayReportDate = FormatDate2(this.ReportDate);
        this.DisplayClosedDate = FormatDate2(this.ClosedDate);
    }

    matchesFilter(pathogens: any, countries: any, stages: any, researchCenters: any, reportedBefore: any, reportedAfter: any): boolean {

        let pathogenBoolVal = false;
        this.Pathogens.forEach((pathogen) => { if (pathogens.includes(pathogen.Virus)) pathogenBoolVal = true; })
        if (!pathogenBoolVal) return false;

        let countryBoolVal = false;
        this.Countries.forEach((country) => { if (countries.includes(country.CountryName)) countryBoolVal = true })
        if (!countryBoolVal) return false;

        let rcBoolVal = false;
        this.ResearchCenters.forEach((center) => { if (researchCenters.includes(center.Name)) rcBoolVal = true; })
        if (!rcBoolVal) return false;

        let beforeDate = new Date(reportedBefore);
        let afterDate = new Date(reportedAfter);

        if (new Date(this.ReportDate) > beforeDate || new Date(this.ReportDate) < afterDate) {

            return false;
        }

        if (!stages.includes(this.Status)) return false;

        return true;

    }
}

class Pathogen {
    ID: string;
    VirusId: string;
    Family: string;
    Virus: string;
    Disease: string;
}

class Country {
    ID: string;
    CountryName: string;
    CountryAbbreviation: string;
}

class ResearchCenter {
    ID: string;
    Name: string;
}

export class OrrKeyPerson {
    ID: string;
    OutbreakId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Organization: string;
    ResearchCenter: ResearchCenter;
    DisplayFullName: string;
    DisplayOrganization: string;
}

export class OrrTigerTeam {
    ID: string;
    OutbreakId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Organization: string;
    RCAffiliation: string;
    DisplayFullName: string;
}

export class OrrPartner {
    ID: string;
    OutbreakId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Organization: string;
    DisplayFullName: string;
    DisplayOrganization: string;
}

export class OrrFinancial {
    ID: string;
    OutbreakId: string;
    ResearchCenter: ResearchCenter;
    FundingSource: string;
    SubFundingSource: string;
    Amount: string;
    DisplayAmount: string;
    DisplayName: string;
}

class OrrExternalResource {
    ID: string;
    OutbreakId: string;
    DisplayText: string;
    Url: string;
}

export class OrrKeyAsset {
    id: string;
    icon: string;
    link: string;
    parent: string;
    state: {
        disabled: boolean;
        opened: boolean;
        selected: boolean;
    }
    text: string;
    type: number;

    constructor() {

    }

    initialize(file: OrrKeyAsset, outbreakId: string, existingRootFolders: OrrKeyAssetMapping[] = []) {
        try {
            this.id = file.id;
            this.icon = file.icon;
            this.link = file.link;
            this.parent = file.parent;
            this.state = file.state;
            this.text = file.text;
            this.type = file.type;

            this.updateFilePathing(outbreakId, existingRootFolders);
        }
        catch {
            console.log("Error initializing the file");
            console.log(file);
            console.log(outbreakId);
            console.log(existingRootFolders);
        }
        

        return this;
    }

    updateFilePathing(outbreakId: string, existingRootFolders: OrrKeyAssetMapping[] = []) {
        if (this.parent == `documents/${outbreakId}`) {
            this.parent = "#";
        }

        let parentRegex = /documents\/_country-documents\/[\w]+\/[\s\w\d]+$/;
        let rootRegex = /documents\/_country-documents\/[\w]+$/;

        if (this.parent.match(parentRegex)) {
            let folderName = this.parent.split("/")[3];

            if (existingRootFolders.find(folder => folder.folderName == folderName)) {
                //this.parent = `documents/${outbreakId}/${folderName}`;
                this.parent = existingRootFolders.find(folder => folder.folderName == folderName).folderPath;
            }
        }

        if (this.id.match(parentRegex) && this.type == 0) {
            let folderName = this.id.split("/")[3];
            if (!existingRootFolders.find(folder => folder.folderName == folderName)) {
                this.parent = "#";
            }
        }

        if (this.parent.match(rootRegex) && this.type != 0) {
            this.parent = "#";
        }
    }

}

export class OrrKeyAssetMapping {
    folderName: string;
    folderPath: string;
    constructor(name: string, path: string) {
        this.folderName = name;
        this.folderPath = path;
    }
}