import { SplitAndJoin } from "../../../shared/scripts/array-functions";


export class LabAssay {

    ResearchCenter: string = "";
    SiteId: string;
    Site: string = "";
    Region: string = "";
    Country: string;
    Name: string;
    Purpose: string;
    Source: string;
    DevelopmentStage: string;
    //OneOrManyPathogens: string = "Unknown";
    //Pathogens: string = "";
    Viruses: string = "Unknown";
    Diseases: string = "";
    Families: string = "";
    GeneralApproach: string = "";
    AdaptedOutbreak: string;
    SpecimenTypes: string;
    SpecimenSources: string = "";
    BiosafetyLevel: string;
    //CdcCompliant: string = "Unknown";
    //CdcAgents: string = "Unknown";
    //RequireTraining: string;
    //TrainingDescription: string = "Unknown";
    RequireEquipment: string;
    EquipmentDescription: string;
    RequireReagents: string;
    ReagentsDescription: string;
    ReagentSource: string;
    Repositories: string;
    //ProvideMaterial: string = "Unknown";
    ProvideTraining: string;
    ExperienceNeeded: string;
    ContactName: string;
    ContactEmail: string;
    Publications: string;
    PublicationsList: string[];
    //AvgSamplesPerWeek: string = "Unknown";
    //MaxSamplesPerWeek: string = "Unknown";
    SurgeCapacity: string = "Unknown";
    //HowMuchMaterial: string = "Unknown";
    //HowToMakeMaterialAvailable: string = "Unknown";
    //ContributeDocuments: string = "Unknown";
    //ResultsFileFormat: string = "Unknown";
    //SizeOfReportPackage: string = "Unknown";
    UploadedDocument: string;

    GeneralApproachDisplay: string = "";
    //GeneralApproachFilter: string = "";
    SpecimenSourcesDisplay: string = "";
    SpecimenSourcesFilter: string = "";
    SpecimenTypesDisplay: string = "";
    VirusesDisplay: string = "";
    //VirusesFilter: string = "";

    HiddenFilter: string = "";

    GeneralApproachFilter: string[] = [];
    VirusesFilter: string[] = [];
    SpeciesFilter: string[] = [];
    ResearchCenterFilter: string = "";
    SiteFilter: string = "";
    RegionFilter: string = "";

    constructor() { }

    initialize(): void {

        //console.log(this);

        if (this.Region == "") this.Region = "Unknown";

        if (this.GeneralApproach == "") { this.GeneralApproach = "Unknown"; this.GeneralApproachFilter = this.GeneralApproach.split("|"); this.GeneralApproachDisplay = "Unknown"}
        else {
            //this.GeneralApproachFilter = this.GeneralApproach.split("(").join("").split(")").join("");
            this.GeneralApproachFilter = this.GeneralApproach.split("|");
            this.GeneralApproachDisplay = SplitAndJoin(this.GeneralApproach, "|", ", ", false);
        }
        //this.GeneralApproachFilter = this.GeneralApproach.split("(").join("").split(")").join("");

        if (this.SpecimenSources == "") {
            this.SpecimenSources = "Unknown";
        }
        this.SpecimenSourcesDisplay = SplitAndJoin(this.SpecimenSources, "|", ", ", false);
        this.SpecimenSourcesFilter = this.SpecimenSources.split("(").join("").split(")").join("");
        this.SpeciesFilter = this.SpecimenSources.split("|");

        this.SpecimenTypesDisplay = SplitAndJoin(this.SpecimenTypes, "|", ", ", false);

        if (this.Viruses == "") this.Viruses = "Unknown";

        //this.VirusesFilter = this.Viruses.split("(").join("").split(")").join("");
        this.VirusesFilter = this.Viruses.split("|");

        let pathogenList: string[] = this.Viruses.split('|').sort();
        this.VirusesDisplay = "";
        pathogenList.forEach((pathogen: string, index: number) => {
            if (index > 0) this.VirusesDisplay += ", ";
            if (pathogen == "SARS-CoV-2" || pathogen == "SARS-CoV" || pathogen == "MERS-CoV")
                this.VirusesDisplay += "<span style='white-space: nowrap'>" + pathogen + "</span>";
            else
                this.VirusesDisplay += pathogen;
        });
        //this.Pathogens = " " + pathogenList.join(" | ") + " ";
        //console.log(this.PathogensDisplay);

        //foreign character encoding triggers the parser to be weird
        if (this.ResearchCenter.endsWith("TBD")) this.ResearchCenter = this.ResearchCenter.replace(' TBD', '');

        this.PublicationsList = this.Publications.split("|");
        if (this.PublicationsList[0] == "") this.PublicationsList = [];
        //console.log(this.PublicationsList);

        let detailRows: string[] = ["Purpose", "Source", "AdaptedOutbreak", "SpecimenTypes", "BiosafetyLevel", "RequireEquipment", "EquipmentDescription", "RequireReagents", "ReagentSource", "Repositories", "ReagentsDescription", "ProvideTraining", "ExperienceNeeded", "SurgeCapacity", "ContactName", "ContactEmail", "Country" ];
        detailRows.forEach((field: string) => {
            if (this[field] == undefined || this[field] == "") {
                this[field] = "<i>Unknown</i>";
            }
        });

        this.ResearchCenterFilter = this.ResearchCenter;
        this.RegionFilter = this.Region;
        this.SiteFilter = this.Site;

        //console.log(this.ContactEmail);
    }



}