import { Split, Join, DoArraysOverlap, SplitAndJoin } from "../../../shared/scripts/array-functions";



export class Stakeholder {

    StakeholderName: string;
    StakeholderType: string = "Unknown";
    OrganizationType: string = "Unknown";
    GeographicFocus: string = "Not Defined";
    About: string = "";
    //PathogenFocus: string = "Unknown";
    Viruses: string = "Not Defined";
    Diseases: string = "";
    Families: string = "";
    URL: string = "";
    StakeholderContactName: string = "";
    StakeholderContactEmail: string = "";
    POC: string = "";
    PresentatScientificSeries: string = "";
    Notes: string = "";
    StakeholderOffering: string = "";
    AgreementInPlace: string = "";
    SCAgreedDate: string = "";
    StakeholderAgreedDate: string = "";
    BenefitsForm: string = "";
    ReceiveQuarterlyUpdates: string = "";
    AttendAnnualMeeting: string = "";
    AttendScientificSeries: string = "";
    PresentatWGMeetings: string = "";
    PresenttoSC: string = "";
    AttendStakeholderForum: string = "";
    MemberofWG: string = "";
    InvitedGuesttoWG: string = "";

    GeographicFocusList: string[];
    GeographicFocusDisplay: string = "";
    VirusesList: string[];
    VirusesDisplay: string = "";

    DiseasesDisplay: string = "";

    URLDisplay: string = "";
    StakeholderContactEmailDisplay: string = "";

    initialize(): void {

        if (this.GeographicFocus == null || this.GeographicFocus.trim().length == 0) { this.GeographicFocus = "Not Defined"; }
        if (this.Viruses == null || this.Viruses.trim().length == 0) { this.Viruses = "Not Defined"; }

        this.DiseasesDisplay = SplitAndJoin(this.Diseases, "|", ", ", true);

        this.GeographicFocusList = Split(this.GeographicFocus, "|");
        this.GeographicFocusDisplay = Join(this.GeographicFocusList, ", ", true, true);

        this.VirusesList = Split(this.Viruses, "|");
        this.VirusesDisplay = Join(this.VirusesList, ", ", true, true);

        if (this.URL != null && this.URL.length > 0) {
            this.URLDisplay = "<a href='" + this.URL + "' target='_blank'>" + this.URL + "</a>";
        }

        if (this.StakeholderContactEmail != null && this.StakeholderContactEmail.length > 0) {
            this.StakeholderContactEmailDisplay = "<a href='mailto:" + this.StakeholderContactEmail + "'>" + this.StakeholderContactEmail + "</a>";
        }

        if (this.BenefitsForm != "Yes") { this.BenefitsForm = "No"; }
    }

    matchesFilter(stakeholderTypes: any, organizationTypes: any, geoFocus: any, viruses: any): boolean {

        if (!stakeholderTypes.includes(this.StakeholderType)) return false;
        if (!organizationTypes.includes(this.OrganizationType)) return false;
        if (!DoArraysOverlap(geoFocus, this.GeographicFocusList)) return false;
        if (!DoArraysOverlap(viruses, this.Viruses)) return false;

        return true;
    }


}