import { SplitAndJoin, Split, Join, DoArraysOverlap } from "../../shared/scripts/array-functions";

export class Resource {
    Name: string;
    Category: string = "Not Defined";
    Topic: string = "Not Defined";
    Format: string = "Not Defined";
    Description: string;
    Source: string = "Not Defined";
    CourseStructure: string;
    TrainingDates: string;
    PrimaryContributorName: string;
    PrimaryContributorAffiliation: string;
    PrimaryContributorEmail: string;
    Language: string;
    Filename: string;
    Url: string;
    OtherSource: string;
    ExpertiseLevel: string;
    Viruses: string;
    Families: string;
    Diseases: string;
    ModifiedDate: string;

    VirusList: string[];
    VirusDisplay: string;
    FamilyDisplay: string = "";
    DiseaseDisplay: string = "";
    UrlDisplay: string = "";
    FilenameDisplay: string = "";
    PrimaryContributorEmailDisplay: string = "";

    LanguageList: string[];
    LanguageDisplay: string = "";

    ResourceAccess: string = "";

    initialize(): void {

        if (this.Topic == null || this.Topic.trim().length == 0) { this.Topic = "Not Defined"; }
        if (this.ExpertiseLevel == null || this.ExpertiseLevel.trim().length == 0) { this.ExpertiseLevel = "Not Defined"; }
        if (this.Language == null || this.Language.trim().length == 0) { this.Language = "Not Defined"; }

        //console.log(this);

        if (this.Viruses == null || this.Viruses.trim().length == 0) { this.VirusDisplay = "Not Defined"; this.VirusList = [this.VirusDisplay]; }
        else {
            this.VirusList = Split(this.Viruses, "|");
            this.VirusDisplay = Join(this.VirusList, ", ", true, true);

            this.VirusDisplay = SplitAndJoin(this.Viruses, "|", ", ", false);
            this.DiseaseDisplay = SplitAndJoin(this.Diseases, "|", ", ", false);
            this.FamilyDisplay = SplitAndJoin(this.Families, "|", ", ", false);
        }

        if (this.PrimaryContributorEmail != undefined && this.PrimaryContributorEmail.length > 0) {
            this.PrimaryContributorEmailDisplay = "<a href='mailto:" + this.PrimaryContributorEmail + "'><span class='nowrap'>" + this.PrimaryContributorEmail.toLowerCase() + "<i class='fa fa-envelope link-icon' aria-hidden='true'></i></span></a>";
        }

        if (this.Language != undefined && this.Language.length > 0) {
            this.LanguageList = Split(this.Language, "|");
            this.LanguageDisplay = Join(this.LanguageList, ", ", true, true);
        }

        this.ResourceAccess = "";

        if (this.Url != undefined && this.Url.length > 0) {
            this.UrlDisplay = "<a href='" + this.Url.trim() + "' target='_blank'><span class='nowrap'>Link<i class='fa fa-external-link-alt link-icon' aria-hidden='true'></i></span></a>";

            this.ResourceAccess = this.UrlDisplay;
        }

        if (this.Filename != undefined && this.Filename.length > 0) {
            this.FilenameDisplay = "<a href='" + this.Filename + "' target='_blank'><span class='nowrap'>" + "File<i class='fa fa-file link-icon' aria-hidden='true'></i></span></a>";

            if (this.ResourceAccess.length > 0) this.ResourceAccess += " | ";
            this.ResourceAccess += this.FilenameDisplay;
        }
    }

    matchesFilter(categories: any, topics: any, formats: any, sources: any, languages: any, viruses: any, expertiseLevels: any): boolean {

        //if (this.Name == "ImmPort") {
        //    console.log(categories, this.Category, categories.includes(this.Category));
        //    console.log(topics, this.Topic, topics.includes(this.Topic));
        //    console.log(formats, this.Format, formats.includes(this.Format));
        //    console.log(sources, this.Source, sources.includes(this.Source));
        //    console.log(expertiseLevels, this.ExpertiseLevel, expertiseLevels.includes(this.ExpertiseLevel));
        //    console.log(languages, this.LanguageList, DoArraysOverlap(languages, this.LanguageList));
        //    console.log(viruses, this.VirusList, DoArraysOverlap(viruses, this.VirusList));
        //}

        if (!categories.includes(this.Category)) return false;
        if (!topics.includes(this.Topic)) return false;
        if (!formats.includes(this.Format)) return false;
        if (!sources.includes(this.Source)) return false;
        if (!expertiseLevels.includes(this.ExpertiseLevel)) return false;

        if (!DoArraysOverlap(languages, this.LanguageList)) return false;
        if (!DoArraysOverlap(viruses, this.VirusList)) return false;

        return true;
    }
}