import { SiteCapacity, ResearchCenter } from "./site-capacities.objects";
import { plainToClass } from "class-transformer";
import { Unique, Split } from "../../../../shared/scripts/array-functions";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../../shared/scripts/table-column-visibility";
import { SiteCapacitiesMap } from "./site-capacities.map";




export class SiteCapacitiesPage {

    map: SiteCapacitiesMap;

    siteCapacities: SiteCapacity[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    filterDropdownIds: string[];

    constructor(data: any) {
        $(() => {

            //console.log(data);

            let researchCenters: ResearchCenter[] = plainToClass(ResearchCenter, <ResearchCenter[]>data.ResearchCenters);

            this.siteCapacities = plainToClass(SiteCapacity, <SiteCapacity[]>data.SiteCapacities);
            this.siteCapacities.map((site: SiteCapacity, index: number) => {
                site.ResearchCenter = researchCenters.find(rc => rc.RcId == site.RcId);
                site.initialize();

                for (let i = index + 1; i < this.siteCapacities.length; ++i) {
                    if (site.isCloseToPoint(this.siteCapacities[i].CoordLat, this.siteCapacities[i].CoordLon)) {
                        site.IsMapCluster = true;
                        this.siteCapacities[i].IsMapCluster = true;
                        //console.log(site, this.siteCapacities[i]);
                    }
                }

                return site;
            });

            //console.log(this.siteCapacities);

            this.filterDropdownIds = ['#site-rc-dropdown', '#site-site-dropdown', '#site-subregion-dropdown', '#site-country-dropdown', '#site-orgtype-dropdown', '#site-disciplines-dropdown', '#site-collectsfrom-dropdown', '#site-pathogens-dropdown',
                '#biorepo-isbiorepo-dropdown', '#biorepo-typesfromhosts-dropdown', '#biorepo-typesfromvectors-dropdown', '#biorepo-species-dropdown', '#biorepo-nonhuman-dropdown', '#biorepo-liveisolates-dropdown', '#biorepo-providetraining-dropdown', '#biorepo-surge-dropdown',
                '#lab-haslab-dropdown', '#lab-researchfocus-dropdown', '#lab-researchperformed-dropdown', '#lab-biosafety-dropdown', '#lab-selectagent-dropdown',
                '#outbreak-areas-dropdown', '#outbreak-nationalassociation-dropdown', '#outbreak-collaborations-dropdown',
                '#regulatory-rccommonrule-dropdown', '#regulatory-sitecommonrule-dropdown', '#regulatory-irbapprovalrequirements-dropdown', '#regulatory-sharingrestrictions-dropdown', '#regulatory-indemnity-dropdown',
                '#shipping-procurement-receivingtime-dropdown', '#shipping-procurement-sourcedwithincountry-dropdown', '#shipping-procurement-internationalshipping-dropdown', '#shipping-procurement-bioreposhipping-dropdown', '#shipping-procurement-permits-dropdown', '#shipping-procurement-training-dropdown'
            ];


            //console.log(this.siteCapacities);

            this.initializeFilters();

            this.setupTabWatcher();

            this.initializeTable();
            this.setSitesFoundLabel(this.siteCapacities.length);

            this.map = new SiteCapacitiesMap();
            this.map.setVisibleSites(this.siteCapacities);
        });
    }

    initializeFilters(): void {
        this.initializeDropdown('#site-rc-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ResearchCenter.RcName), true));
        this.initializeDropdown('#site-site-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.SiteName), true));
        this.initializeDropdown('#site-subregion-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.SubRegion), true, ["Unknown"]));
        this.initializeDropdown('#site-country-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Country), true, ["Unknown"]));
        this.initializeDropdown('#site-orgtype-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.SiteOverview.OrganizationType), true));
        this.initializeDropdown('#biorepo-isbiorepo-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Biorepo.HasBiorepository), true, ["Unknown"]));
        this.initializeDropdown('#biorepo-selectagent-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Biorepo.ApprovedForSelectAgents), true, ["Unknown", "Not Applicable"]));
        this.initializeDropdown('#biorepo-surge-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Biorepo.HasSurgeCapacityToCollectProcessStore), true, ["Unknown", "Not Applicable"]));
        this.initializeDropdown('#lab-haslab-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.LabFacility.HasLabFacilities), true, ["Unknown"]));
        this.initializeDropdown('#lab-selectagent-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.LabFacility.RegisteredWithSelectAgentProgram), true, ["Unknown", "Not Applicable"]));
        this.initializeDropdown('#regulatory-rccommonrule-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ResearchCenter.HubIrbCommonRule), true, ["I do not know"]));
        this.initializeDropdown('#regulatory-sitecommonrule-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Regulatory.UtilizeCommonRule), true, ["Unknown"]));
        this.initializeDropdown('#regulatory-indemnity-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Regulatory.RequireIndemnityClause), true, ["Unknown"]));
        //this.initializeDropdown('#regulatory-irbapprovalrequirements-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Regulatory.ApprovalRequirements), true, ["Unknown"]));
        this.initializeDropdown('#regulatory-sharingrestrictions-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.Regulatory.AnySharingRestrictions), true, ["Unknown"]));
        this.initializeDropdown('#shipping-procurement-receivingtime-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ShippingProcurement.AverageReceivingTime), true, ["Unknown"]));
        this.initializeDropdown('#shipping-procurement-sourcedwithincountry-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ShippingProcurement.PercentSuppliedWithinCountry), true, ["Unknown"]));
        this.initializeDropdown('#shipping-procurement-permits-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ShippingProcurement.AbleToObtainImportExportPermits), true, ["Unknown"]));
        this.initializeDropdown('#shipping-procurement-training-dropdown', Unique(this.siteCapacities.map((site: SiteCapacity) => site.ShippingProcurement.TrainingAndCertification), true, ["Unknown"]));

        let values: string[] = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.SiteOverview.Disciplines, "|")); });
        this.initializeDropdown('#site-disciplines-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.SiteOverview.FieldStudyTypeExperience, "|")); });
        this.initializeDropdown('#site-collectsfrom-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.SiteOverview.PathogensLabAssays, "|")); });
        this.initializeDropdown('#site-pathogens-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.Biorepo.SampleTypes, "|")); });
        this.initializeDropdown('#biorepo-sampletypes-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.Biorepo.BiosafetyLevels, "|")); });
        this.initializeDropdown('#biorepo-biosafety-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.Biorepo.SpeciesStored, "|")); });
        this.initializeDropdown('#biorepo-sourceorganisms-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        //values = [];
        //this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.Biorepo.ShippingCapacity, "|")); });
        //this.initializeDropdown('#biorepo-shipping-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.Biorepo.LiveIsolates, "|")); });
        this.initializeDropdown('#biorepo-liveisolates-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.LabFacility.PrimarySiteResearchFocus, "|")); });
        this.initializeDropdown('#lab-researchfocus-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        //values = [];
        //this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.LabFacility.PrimarySiteResearchFocus, "|")); });
        //this.initializeDropdown('#lab-researchfocus-dropdown', Unique(values, true, "Unknown"));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.LabFacility.ResearchPerformed, "|")); });
        this.initializeDropdown('#lab-researchperformed-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.LabFacility.BiosafetyLevels, "|")); });
        this.initializeDropdown('#lab-biosafety-dropdown', Unique(values, true, ["Unknown", "Not Applicable"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.OutbreakResearch.OutbreakResponseAreas, "|")); });
        this.initializeDropdown('#outbreak-areas-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.OutbreakResearch.AssociationWithNationalLabs, "|")); });
        this.initializeDropdown('#outbreak-nationalassociation-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.OutbreakResearch.CollaborativeEntities, "|")); });
        this.initializeDropdown('#outbreak-collaborations-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.ShippingProcurement.ShippingCapacity, "|")); });
        this.initializeDropdown('#shipping-procurement-bioreposhipping-dropdown', Unique(values, true, ["Unknown"]));
        values = [];
        this.siteCapacities.forEach((site: SiteCapacity) => { values.push(...Split(site.ShippingProcurement.InternationalShippingMethod, "|")); });
        this.initializeDropdown('#shipping-procurement-internationalshipping-dropdown', Unique(values, true, ["Unknown"]));
    }

    setupTabWatcher(): void {
        $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
            //$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
            if (e.currentTarget.id == "site-overview-tab") {
                ['#site-rc-dropdown', '#site-site-dropdown', '#site-subregion-dropdown', '#site-country-dropdown', '#site-orgtype-dropdown', '#site-disciplines-dropdown', '#site-collectsfrom-dropdown', '#site-pathogens-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "biorepo-tab") {
                ['#biorepo-isbiorepo-dropdown', '#biorepo-sampletypes-dropdown', '#biorepo-sourceorganisms-dropdown', '#biorepo-liveisolates-dropdown', '#biorepo-biosafety-dropdown', '#biorepo-selectagent-dropdown', '#biorepo-surge-dropdown', '#biorepo-shipping-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "lab-facility-tab") {
                ['#lab-haslab-dropdown', '#lab-researchfocus-dropdown', '#lab-selectagent-dropdown', '#lab-researchperformed-dropdown', '#lab-biosafety-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "outbreak-research-tab") {
                ['#outbreak-areas-dropdown', '#outbreak-nationalassociation-dropdown', '#outbreak-collaborations-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
            else if (e.currentTarget.id == "regulatory-tab") {
                ['#shipping-procurement-receivingtime-dropdown', '#shipping-procurement-sourcedwithincountry-dropdown', '#shipping-procurement-permits-dropdown', '#shipping-procurement-training-dropdown', '#shipping-procurement-bioreposhipping-dropdown', '#shipping-procurement-internationalshipping-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));
            }
        });

        $('a[data-toggle="pill"]').on('shown.bs.tab', (e) => {
            if (e.currentTarget.id == "content-datatable-tab") {
                this.dataTable.columns.adjust();
                this.tableColumnController.refresh();
            }
            else if (e.currentTarget.id == "content-map-tab") {
                this.map.refresh();
            }
        });
    }

    initializeDropdown(selectId: string, values: string[]): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, true));
        });
        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.filterSiteCapacities();
        });
    }


    initializeTable(): void {

        this.dataTable = $('#site-capacities-table').DataTable({
            "dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            //"dom": '<"top-controls"<"left-section"<"search-bar"f>><"right-section"<"column-select">B>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            //responsive: false,
            scrollY: '60vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 2
            },
            language:
            {
                search: "Search",
            },
            columns: [
                { data: { _: "SiteNameDisplay", sort: "SiteName" }, title: "Site Name", className: "text-left font-size12" },
                { data: { _: "ResearchCenter.RcNameDisplay", sort: "ResearchCenter.RcName" }, title: "Research Center", className: "text-left font-size12" },
                { data: "SubRegion", title: "Region", className: "location text-left font-size12" },
                { data: "Country", title: "Country", className: "location text-left font-size12" },
                { data: { _: "SiteOverview.PocName", sort: "SiteOverview.PocLastName" }, title: "POC Name", className: "poc text-left font-size12" },
                { data: "SiteOverview.PocTitle", title: "POC Title", className: "poc text-left font-size12" },
                { data: "SiteOverview.PocEmail", title: "POC Email", className: "poc text-left font-size12" },
                { data: "SiteOverview.PerformsResearch", title: "Performs Research", className: "siteoverview text-left font-size12" },
                { data: "SiteOverview.OrganizationType", title: "Organization Type", className: "siteoverview text-left font-size12" },
                { data: "SiteOverview.DisciplinesDisplay", title: "Disciplines", className: "siteoverview text-left font-size12" },                
                { data: "Biorepo.HasBiorepository", title: "Has Biorepository", className: "siteoverview text-left font-size12" },
                { data: "LabFacility.HasLabFacilities", title: "Has Lab Facilities", className: "siteoverview text-left font-size12" },
                { data: "SiteOverview.CapacityBuildingTraining", title: "Capacity Building Training", className: "capacity-building text-left font-size12" },
                { data: "SiteOverview.BarrierToCapacityTraining", title: "Barriers to Capacity Building Training", className: "capacity-building text-left font-size12" },
                { data: "SiteOverview.PathogensDisplay", title: "Pathogens (Lab Assays)", className: "pathogens text-left font-size12" },
                { data: "SiteOverview.PathogensActivelyStudied", title: "Non-CREID Pathogens Actively Studied", className: "pathogens text-left font-size12" },
                { data: "SiteOverview.PathogensStudiesInPast", title: "Pathogens Studied in the Past", className: "pathogens text-left font-size12" },
                { data: "SiteOverview.FieldStudyTypeExperienceDisplay", title: "Collects Samples From", className: "field-study text-left font-size12" },
                { data: "SiteOverview.TypesOfAnimalFieldStudies", title: "Types of Animal Field Studies", className: "field-study text-left font-size12" },
                { data: "SiteOverview.TypesOfLivestockFieldStudies", title: "Types of Livestock Field Studies", className: "field-study text-left font-size12" },
                { data: "SiteOverview.TypesOfEntomologyFieldStudies", title: "Types of Entomology Field Studies", className: "field-study text-left font-size12" },
                { data: "SiteOverview.TypesOfHumanFieldStudies", title: "Types of Human Field Studies", className: "field-study text-left font-size12" },                
                { data: "Biorepo.SampleTypesDisplay", title: "Sample Types", className: "bio-samples text-left font-size12" },
                { data: "Biorepo.SpeciesStoredDisplay", title: "Source Organisms", className: "bio-samples text-left font-size12" },
                { data: "Biorepo.LiveIsolatesDisplay", title: "Live Isolates", className: "bio-samples text-left font-size12" },
                { data: "Biorepo.SelectAgents", title: "Select Agents Approved to Store", className: "bio-samples text-left font-size12" },
                { data: "Biorepo.Temperature", title: "Storage Temperatures", className: "bio-facilities text-left font-size12" },
                { data: "Biorepo.BiosafetyLevelsDisplay", title: "Biosafety Levels", className: "bio-facilities text-left font-size12" },
                { data: "Biorepo.HasLiquidNitrogen", title: "Liquid Nitrogen Storage", className: "bio-facilities text-left font-size12" },
                { data: "Biorepo.BackupGenerator", title: "Backup Generators", className: "bio-facilities text-left font-size12" },
                //{ data: "Biorepo.ShippingCapacityDisplay", title: "Shipping Capacity", className: "bio-facilities text-left font-size12" },
                { data: "Biorepo.HasSurgeCapacityToCollectProcessStore", title: "Surge Capacity", className: "bio-facilities text-left font-size12 nowrap" },
                { data: "Biorepo.HasSops", title: "Sample Collection, Processing, and Storage SOPs", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.LevelOfExperienceDuringOutbreak", title: "Experience Level to Support Rapid Outbreak Response", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.HasSystemToTrackSamples", title: "System for Tracking Samples", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.HasExternalAudit", title: "Regular External Review", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.OperateUnderQualitySystems", title: "Quality System", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.StaffTraining", title: "Staff Training", className: "bio-practices text-left font-size12" },
                { data: "Biorepo.ShareArchivalSamples", title: "Can Share Archival Samples", className: "bio-practices text-left font-size12" },
                //{ data: "Biorepo.HasSurgeCapacityToCollectProcessStore", title: "Has Surge Capacity to Collect/Process", className: "bio-facilities text-left font-size12" },
                //{ data: "Biorepo.HasSurgeCapacitySpace", title: "Has Surge Capacity Space/Equipment", className: "bio-facilities text-left font-size12" },
                
                //{ data: "Biorepo.WhyEquipmentOutsideLab", title: "Equipment Stored Outside Lab", className: "bio-facilities text-left font-size12" },
                //{ data: "Biorepo.DoesStoreLiveIsolates", title: "Stores Live Isolates", className: "bio-viral-isolates text-left font-size12" },
                //{ data: "Biorepo.ApprovedForSelectAgents", title: "Stores Select Agents", className: "bio-viral-isolates text-left font-size12" },
                { data: "LabFacility.PrimarySiteResearchFocusDisplay", title: "Primary Focus of Lab Site", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.ResearchPerformedDisplay", title: "Research Performed at Lab Site", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.BiosafetyLevelsDisplay", title: "Biosafety Levels", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.HasNovelTechnologyPlatform", title: "Has Novel Technology Platform", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.NovelTechnologyPlatform", title: "Novel Technology Platform", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.ExperienceLevelRequired", title: "Experience Level Required (1 to 5)", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.LabAssistanceNeed", title: "Lab Assistance Needed", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.PreClinicalAnimalStudies", title: "Performs Pre-Clinical Animal Studies", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.PreclinicalSpeciesAndModels", title: "Pre-Clinical Species and Models", className: "lab-overview text-left font-size12" },
                { data: "LabFacility.ExternalSystemOversight", title: "Operates Under External Quality System/Program Oversight", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.ParticipateInAccreditationProficiencyTesting", title: "Programs for Proficiency Training", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.ExternalAuditReview", title: "External Audit of Lab Procedures", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.ExternalReviewers", title: "Types of External Audits", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.ExternalProficiencyTesting", title: "Use External Proficiency Testing", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.NameOfExternalProgram", title: "Name of External Proficiency Program", className: "lab-oversight text-left font-size12" },
                { data: "LabFacility.RegisteredWithSelectAgentProgram", title: "Registered with Select Agent Program", className: "lab-training text-left font-size12" },
                { data: "LabFacility.SelectAgents", title: "Select Agents", className: "lab-training text-left font-size12" },
                { data: "LabFacility.RequireAdditionalTraining", title: "Require Additional Training", className: "lab-training text-left font-size12" },
                { data: "LabFacility.AdditionalTraining", title: "Additional Training", className: "lab-training text-left font-size12" },
                { data: "OutbreakResearch.OutbreakResponseAreasDisplay", title: "Outbreak Response Area", className: "outbreak text-left font-size12" },
                { data: "OutbreakResearch.CurrentlyInvolvedInOneHealthInitiative", title: "Involved with OneHealth Initiative", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.RelationshipWithGovernment", title: "Relationship with Government", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.InCountryAuthorityDuringOutbreak", title: "In-Country Authority During Outbreak", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.RelationshipWithOneHealth", title: "Relationship with OneHealth", className: "outbreak text-left font-size12" },
                { data: "OutbreakResearch.AssociationWithNationalLabsDisplay", title: "National Lab Association", className: "outbreak text-left font-size12" },
                { data: "OutbreakResearch.CollaborativeEntitiesDisplay", title: "Collaborative Entities", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.InCountryInstitutions", title: "In-Country Institutions", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.InCountryOrganizations", title: "In-Country Organizations", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.OutbreakChallenge1", title: "Outbreak Challenge 1", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.OutbreakChallenge2", title: "Outbreak Challenge 2", className: "outbreak text-left font-size12" },
                //{ data: "OutbreakResearch.OutbreakChallenge3", title: "Outbreak Challenge 3", className: "outbreak text-left font-size12" },
                { data: "ResearchCenter.HubIrbCommonRule", title: "RC IRB Follows Common Rule", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubSinceWhenCommonRule", title: "RC IRB Common Rule Since", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubHowManyIrbs.", title: "RC How Many IRBs", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubIrbNames", title: "RC IRB Name", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubHowOftenIrbMeet", title: "RC How Often IRB Meets", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubIrbReviewTime", title: "RC IRB Review Time", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubIrbExpeditedReviewTime", title: "RC IRB Expedited Review Time", className: "regulatory-rc-irb text-left font-size12" },
                { data: "ResearchCenter.HubIrbHurdles", title: "RC IRB Hurdles", className: "regulatory-rc-irb text-left font-size12" },
                { data: "Regulatory.AnySharingRestrictions", title: "Biorepo: Restrictions to Sharing", className: "regulatory-site-biorepo text-left font-size12" },
                { data: "Regulatory.SharingRestrictions", title: "Biorepo: Sharing Restrictions", className: "regulatory-site-biorepo text-left font-size12" },
                { data: "Regulatory.RequireIndemnityClause", title: "Biorepo: Require Indemnity Clause", className: "regulatory-site-biorepo text-left font-size12" },
                { data: "Regulatory.CentralizedAssistanceNeed", title: "Biorepo: Centralized Assistance Need", className: "regulatory-site-biorepo text-left font-size12" },
                { data: "Regulatory.UtilizeCommonRule", title: "Site IRB Follows Common Rule", className: "regulatory-site-irb text-left font-size12" },
                { data: "Regulatory.IrbName", title: "Site IRB Name", className: "regulatory-site-irb text-left font-size12" },
                { data: "Regulatory.IrbMeetingFrequency", title: "Site IRB Meeting Frequency", className: "regulatory-site-irb text-left font-size12" },
                { data: "Regulatory.AverageReviewTimeline", title: "Site IRB Average Review Time", className: "regulatory-site-irb text-left font-size12" },
                { data: "Regulatory.MinimumExpeditedTimeline", title: "Site IRB Expedited Review Time", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.ApprovalRequirements", title: "Site IRB Approval Requirements", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.RequirePeriodicReview", title: "Site IRB Requires Periodic Review", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.ContinuationDependOnPeriodicReview.", title: "Site IRB Continuation Depends on Periodic Review", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.ImmediateReportingOfSAE", title: "Site IRB Immediate Reporting of SAE", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.ImmediateReportingOfProtocolViolations", title: "Site IRB Immedite Reporting of Protocol Violations", className: "regulatory-site-irb text-left font-size12" },
                //{ data: "Regulatory.GreatestOutbreakIrbHurdles", title: "Site IRB Greatest Outbreak Hurdles", className: "regulatory-site-irb text-left font-size12" },
                { data: "ShippingProcurement.AverageReceivingTime", title: "Average Receiving Time for Critical Materials", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.PercentSuppliedWithinCountry", title: "Amount of Materials Sourced within Country", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.AccessToLargerPortal", title: "Can Acquire Material Through Larger Portal", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.AbleToObtainImportExportPermits", title: "Able to Obtain Import/Export Permits", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.TimelineToObtainPermits", title: "Timeline to Obtain Permits", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.InternationalShippingMethodDisplay", title: "International Shipping Method", className: "shipping-site-info text-left font-size12" },
                { data: "ShippingProcurement.ShippingCapacityDisplay", title: "Biorepo: Shipping Capacity", className: "shipping-biorep text-left font-size12" },
                { data: "ShippingProcurement.AccessToInternationalCouriers", title: "Biorepo: Access to International Couriers", className: "shipping-biorep text-left font-size12" },
                { data: "ShippingProcurement.AbleToObtainPermits", title: "Biorepo: Able to Obtain Permits", className: "shipping-biorep text-left font-size12" },
                { data: "ShippingProcurement.TrainingAndCertification", title: "Biorepo: Have Shipping Training and Certification", className: "shipping-biorep text-left font-size12" },
                { data: "ShippingProcurement.TypesOfTraining", title: "Biorepo: Trainings Completed", className: "shipping-biorep text-left font-size12" },

            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                }
            ],
            data: this.siteCapacities,
            //order: [[5, 'desc'], [1, 'asc']],
            initComplete: (settings, json) => {
                //$("#hub-details-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3');

        $(".spacer").addClass('col-12 col-md-3');0
        $(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle mt-2');
        //$(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"sites-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        this.tableColumnController = new TableColumnVisibilityController('#site-capacities-table', '#column-select', [
            new TableColumnGroup("Site Location", false, [], "location"),
            new TableColumnGroup("Point of Contact (POC)", false, [], "poc"),
            new TableColumnGroup("Site Overview", true, [], "siteoverview"),
            new TableColumnGroup("Pathogens", false, [], "pathogens"),
            new TableColumnGroup("Capacity Building", false, [], "capacity-building"),
            new TableColumnGroup("Field Study Information", false, [], "field-study"),
            new TableColumnGroup("Biorepository - Samples", false, [], "bio-samples"),
            new TableColumnGroup("Biorepository - Facilities", false, [], "bio-facilities"),
            new TableColumnGroup("Biorepository - Practices", false, [], "bio-practices"),
            new TableColumnGroup("Lab Facilities - Overview", false, [], "lab-overview"),
            new TableColumnGroup("Lab Facilities - Oversight & Proficiency", false, [], "lab-oversight"),
            new TableColumnGroup("Lab Facilities - Select Agent & Training", false, [], "lab-training"),
            new TableColumnGroup("Outbreak Response", false, [], "outbreak"),
            new TableColumnGroup("Regulatory - Research Center IRB", false, [], "regulatory-rc-irb"),
            new TableColumnGroup("Regulatory - Site IRB", false, [], "regulatory-site-irb"),
            new TableColumnGroup("Regulatory - Biorepository", false, [], "regulatory-site-biorepo"),
            new TableColumnGroup("Shipping & Procurement - Site Info", false, [], "shipping-site-info"),
            new TableColumnGroup("Shipping & Procurement - Biorepository", false, [], "shipping-biorep"),
        ]);

        $('#site-capacities-table').on('search.dt', (e, settings) => {
            this.setSitesFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
    }

    filterSiteCapacities(): void {

        let matchingSites: SiteCapacity[] = [];

        //if (this.isFilterApplied('#site-rc-dropdown')) {
            
        //}

        let siteMatches: boolean;
        this.siteCapacities.forEach((site: SiteCapacity) => {

            siteMatches = true; //must match all filters

            if (!site.ResearchCenter.matchesFilter(this.getSelectedDropdownValues('#site-rc-dropdown'), this.getSelectedDropdownValues('#regulatory-rccommonrule-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("RC");
            }
            else if (!site.matchesFilter(this.getSelectedDropdownValues('#site-site-dropdown'), this.getSelectedDropdownValues('#site-subregion-dropdown'), this.getSelectedDropdownValues('#site-country-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Site");
            }
            else if (!site.SiteOverview.matchesFilter(this.getSelectedDropdownValues('#site-orgtype-dropdown'), this.getSelectedDropdownValues('#site-disciplines-dropdown'), this.getSelectedDropdownValues('#site-collectsfrom-dropdown'), this.getSelectedDropdownValues('#site-pathogens-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Overview");
            }
            else if (!site.Biorepo.matchesFilter(this.getSelectedDropdownValues('#biorepo-isbiorepo-dropdown'), this.getSelectedDropdownValues('#biorepo-sampletypes-dropdown'), this.getSelectedDropdownValues('#biorepo-sourceorganisms-dropdown'), this.getSelectedDropdownValues('#biorepo-biosafety-dropdown'), this.getSelectedDropdownValues('#biorepo-shipping-dropdown'), this.getSelectedDropdownValues('#biorepo-liveisolates-dropdown'), this.getSelectedDropdownValues('#biorepo-selectagent-dropdown'), this.getSelectedDropdownValues('#biorepo-surge-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Biorepo");
            }
            else if (!site.LabFacility.matchesFilter(this.getSelectedDropdownValues('#lab-haslab-dropdown'), this.getSelectedDropdownValues('#lab-researchfocus-dropdown'), this.getSelectedDropdownValues('#lab-researchperformed-dropdown'), this.getSelectedDropdownValues('#lab-biosafety-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("LabFacility");
            }
            else if (!site.OutbreakResearch.matchesFilter(this.getSelectedDropdownValues('#outbreak-areas-dropdown'), this.getSelectedDropdownValues('#outbreak-nationalassociation-dropdown'), this.getSelectedDropdownValues('#outbreak-collaborations-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Outbreak");
            }
            //else if (!site.OutbreakResearch.matchesFilter(this.getSelectedDropdownValues('#outbreak-areas-dropdown'), this.getSelectedDropdownValues('#outbreak-nationalassociation-dropdown'), this.getSelectedDropdownValues('#outbreak-collaborations-dropdown'))) {
            //    siteMatches = false;
            //}
            //else if (!site.Regulatory.matchesFilter(this.getSelectedDropdownValues('#regulatory-sitecommonrule-dropdown'), this.getSelectedDropdownValues('#regulatory-irbapprovalrequirements-dropdown'), this.getSelectedDropdownValues('#regulatory-indemnity-dropdown'), this.getSelectedDropdownValues('#regulatory-sharingrestrictions-dropdown'))) {
            else if (!site.Regulatory.matchesFilter(this.getSelectedDropdownValues('#regulatory-sitecommonrule-dropdown'), this.getSelectedDropdownValues('#regulatory-indemnity-dropdown'), this.getSelectedDropdownValues('#regulatory-sharingrestrictions-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Regulatory");
            }
            else if (!site.ShippingProcurement.matchesFilter(this.getSelectedDropdownValues('#shipping-procurement-bioreposhipping-dropdown'), this.getSelectedDropdownValues('#shipping-procurement-training-dropdown'), this.getSelectedDropdownValues('#shipping-procurement-receivingtime-dropdown'), this.getSelectedDropdownValues('#shipping-procurement-sourcedwithincountry-dropdown'), this.getSelectedDropdownValues('#shipping-procurement-permits-dropdown'), this.getSelectedDropdownValues('#shipping-procurement-internationalshipping-dropdown'))) {
                siteMatches = false;
                //if (site.SiteId == 24) console.log("Shipping");
            }

            if (siteMatches) { matchingSites.push(site); }

        });

        this.updateTable(matchingSites);
        this.map.setVisibleSites(matchingSites);
    }

    getSelectedDropdownValues(selectId: string): any {
        //return $(selectId).selectpicker('val');
        return $(selectId).val();
    }

    isFilterApplied(selectId: string): boolean {
        //var selected = $(selectId).selectpicker('val');
        var selected = $(selectId).val();
        var count: number = $(selectId + ' option').length;

        //console.log(selected.length, count, selected.length == count);

        return (<any>selected).length != count;
    }

    updateTable(matchingSites: SiteCapacity[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingSites).draw();
        this.setSitesFoundLabel(matchingSites.length);
    }

    resetFilters(): void {

        this.filterDropdownIds.forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        //$('#site-capacities-table').
        this.dataTable.search('').draw();

        this.updateTable(this.siteCapacities);
    }

    setSitesFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#sites-found-count').text("No Sites Found"); break;
            case 1: $('#sites-found-count').text("1 Site Found"); break;
            default: $('#sites-found-count').text(count + " Sites Found");
        }
    }

    showHelpModal(): void {
        $('#site-help-modal').modal('show');
    }
}