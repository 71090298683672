

export class DataHubPage {
    constructor() {
        $(() => {
            $(document).ready(function () {
                function switchTab(tabId, addToHistory = true) {
                    $('#dataHubTabs .nav-link').removeClass('active');
                    $(`#dataHubTabs button[data-target="${tabId}"]`).addClass('active');
                    $('#tabContent .tab-pane').removeClass('show active');
                    $(`#tabContent ${tabId}`).addClass('show active');
                    window.scrollTo(0, 0);

                    if (addToHistory) {
                        history.pushState(null, null, tabId);
                    }
                }

               /* $('a[href="#overview"]').on('click', function (e) {
                    e.preventDefault();
                    $('#dataHubTabs .nav-link').removeClass('active');
                    $('#dataHubTabs button#overview-tab').addClass('active');
                    $('#tabContent div.tab-pane').removeClass('show active');
                    $('#tabContent #overview').addClass('show active');
                    window.scrollTo(0, 0);
                });
                $('a[href="#repo"]').on('click', function (e) {
                    e.preventDefault(); 
                    $('#dataHubTabs .nav-link').removeClass('active'); 
                    $('#dataHubTabs button#repo-tab').addClass('active');
                    $('#tabContent div.tab-pane').removeClass('show active');
                    $('#tabContent #repo').addClass('show active');
                    window.scrollTo(0, 0);
                });

                $('a[href="#faq"]').on('click', function (e) {
                    e.preventDefault();
                    $('#dataHubTabs .nav-link').removeClass('active');
                    $('#dataHubTabs button#faq-tab').addClass('active');
                    $('#tabContent div.tab-pane').removeClass('show active');
                    $('#tabContent #faq').addClass('show active');
                    window.scrollTo(0, 0);
                });

                $('a[href="#schema"]').on('click', function (e) {
                    e.preventDefault();
                    $('#dataHubTabs .nav-link').removeClass('active');
                    $('#dataHubTabs button#schema-tab').addClass('active');
                    $('#tabContent div.tab-pane').removeClass('show active');
                    $('#tabContent #schema').addClass('show active');
                    window.scrollTo(0, 0);
                });*/

                $('a[href^="#"]').on('click', function (e) {
                    const targetId = $(this).attr('href');
                    if ($(this).data('tab-target')) {
                        e.preventDefault();
                        switchTab(targetId);
                    }
                    else if ($(this).parents('#faqAccordion').length) {
                        e.preventDefault();
                        $('html, body').animate({
                            scrollTop: $(targetId).offset().top
                        }, 500);
                    }
                });

                window.addEventListener('popstate', function () {
                    const activeTab = location.hash || '#overview';
                    switchTab(activeTab);
                });

                const initialTab = location.hash || '#overview';
                switchTab(initialTab, false);

            });
        })
    }
}