import { DocumentTree } from '../../shared/components/document-tree/document-tree';
import { UserDirectoryEntity } from '../communications/directory/directory.objects';
import { plainToClass } from 'class-transformer';

export class CommitteePage {

    documentTree: DocumentTree;

    constructor(nodes: any, userJson: any, tableId: string, primaryId: string, secondaryId: string, observerId: string, communicationsId: string) {
        $(() => {

            //console.log("Init");
            //console.log(nodes);
            if (nodes) {
                this.documentTree = new DocumentTree(nodes);
            }

            if (tableId != "") {
                this.initializeParticipantTable(tableId, userJson, primaryId, secondaryId, observerId, communicationsId);
            }
        });
    }

    initializeParticipantTable(tableId: string, userJson: any, primaryId: string, secondaryId: string, observerId: string, communicationsId: string): void {

        let users: UserDirectoryEntity[] = plainToClass(UserDirectoryEntity, <UserDirectoryEntity[]>userJson);

        users = users.map((user: UserDirectoryEntity) => {
            user.initialize();
            user.setMembershipForGroupDirectory(primaryId, secondaryId, observerId, communicationsId);
            return user;
        });

        //console.log(users);

        let table = $('#' + tableId).DataTable({
            "dom": '<"top-controls"f<"top-right-section"<"filtering-section">B>>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            orderCellsTop: true,
            columns: [
                { data: "DisplayName", className: "text-left" },
                { data: "Network", className: "text-left", width: '15%' },
                { data: "Organization", className: "text-left", width: '25%' },
                { data: "Email", className: "text-left", width: '25%' },
                { data: "Membership", visible: false },
            ],
            columnDefs: [{ //to sort by last name first
                targets: 0,
                data: "DisplayName",
                render: function (data, type, full, meta) {
                    if (type == 'sort') {
                        data = full.SortName;
                    }
                    return data;
                }
            }],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                }
            ],
            data: users
        });

        let filterSectionDiv: string =
            "<div class=\"d-flex\">" +
            "<div class=\"form-inline\"><label for=\"membership\" class=\"col-form-label\">Membership:</label><select id=\"membershipOptions\" class=\"custom-select custom-select-sm mr-2\"></select></div>" +
            "</div>"
            ;
        $(".filtering-section").empty().html(filterSectionDiv);
        $(".filtering-section").css('display', 'inline-block');
        //$(".filtering-section").css('float', 'right');
        $(".top-right-section").css('float', 'right');


        for (let index = 0; index <= 5; ++index) {
            if (index != 4) continue; //only filtering on membership

            var select = $("#membershipOptions");
            select.empty();

            select.append($("<option></option>").attr("value", 'All').text('All'));

            let choices: string[] = [];
            if (primaryId != "SKIP") { choices.push("Primary Members"); }
            if (secondaryId != "SKIP") { choices.push("Secondary Members"); }
            if (observerId != "SKIP") { choices.push("Observers"); }
            if (communicationsId != "SKIP") { choices.push("Communications"); }
            choices.forEach((val: string) => {
                select.append($("<option></option>").attr("value", val.split(' ').join('-')).text(val));
            });

            select.on('change', (event) => {
                let selected: string = <string>$(event.currentTarget).val();
                //console.log(selected);
                if (selected == 'All') selected = '';
                table.column(index)
                    .search(selected)
                    .draw();
            });
        }
    }
}
