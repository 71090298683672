import { UserExperience } from "../../../auth/pages/profile/scripts/expertise.objects";
import { DoArraysOverlap } from "../../../shared/scripts/array-functions";



export class UserDirectoryEntity {

    FirstName: string;
    LastName: string;
    ProjectRole: string;
    Email: string;
    Network: string;
    Expertise: UserExperience;
    Organization: string;
    Country: string = "Not Available";
    Degrees: string;

    DisplayName: string;
    SortName: string;
    Membership: string;
    MembershipArray: string[];
    NetworkArray: string[];
    HiddenFilter: string = "";

    SC_Primary: boolean; SC_Secondary: boolean; SC_Observer: boolean; SC_Comms: boolean;
    NLead_Primary: boolean; NLead_Secondary: boolean; NLead_Observer: boolean; NLead_Comms: boolean;
    WGroup_Primary: boolean; WGroup_Secondary: boolean; WGroup_Observer: boolean; WGroup_Comms: boolean;
    DCap_Primary: boolean; DCap_Secondary: boolean; DCap_Observer: boolean; DCap_Comms: boolean;
    BRepo_Primary: boolean; BRepo_Secondary: boolean; BRepo_Observer: boolean; BRepo_Comms: boolean;
    LAssay_Primary: boolean; LAssay_Secondary: boolean; LAssay_Observer: boolean; LAssay_Comms: boolean;
    ORes_Primary: boolean; ORes_Secondary: boolean; ORes_Observer: boolean; ORes_Comms: boolean;
    CBuild_Primary: boolean; CBuild_Secondary: boolean; CBuild_Observer: boolean; CBuild_Comms: boolean;
    RAD_Primary: boolean; RAD_Secondary: boolean; RAD_Observer: boolean; RAD_Comms: boolean;
    PM_Primary: boolean; PM_Secondary: boolean; PM_Observer: boolean; PM_Comms: boolean;
    PilotRP_Primary: boolean; PilotRP_Secondary: boolean; PilotRP_Observer: boolean; PilotRP_Comms: boolean;

    NetworkCompound: boolean = false;

    constructor() { }

    initialize(): void {
        if (this.Degrees == undefined) this.Degrees = "";

        this.DisplayName = this.FirstName + " " + this.LastName;
        if (this.Degrees.length > 0) { this.DisplayName += (", " + this.Degrees); }
        this.SortName = this.LastName + " " + this.FirstName;

        this.NetworkArray = this.Network.split(";");
        this.Network = this.Network.replace(";", ";<br>");
        this.NetworkCompound = this.Network.includes(";");
    }

    setMembershipForOverallDirectory(): void {

        this.Membership = "";
        this.MembershipArray = [];

        if (this.SC_Primary == true || this.SC_Secondary == true) { this.Membership += "Steering-Committee-(Members) | "; this.MembershipArray.push("Steering Committee (Members)"); }
        if (this.SC_Comms == true || this.SC_Observer) { this.Membership += "Steering-Committee-(Others) | "; this.MembershipArray.push("Steering Committee (Others)"); }

        if (this.NLead_Primary == true || this.NLead_Secondary == true || this.NLead_Comms == true || this.NLead_Observer == true) { this.Membership += "Network-Leadership | "; this.MembershipArray.push("Network Leadership"); }

        if (this.WGroup_Primary == true || this.WGroup_Secondary == true) { this.Membership += "Working-Group-Facilitator-(Members) | "; this.MembershipArray.push("Working Group Facilitator (Members)"); }
        if (this.WGroup_Comms == true || this.WGroup_Observer) { this.Membership += "Working-Group-Facilitator-(Others) | "; this.MembershipArray.push("Working Group Facilitator (Others)"); }

        if (this.DCap_Primary == true || this.DCap_Secondary == true) { this.Membership += "Data-Capture-CoP-(Members) | "; this.MembershipArray.push("Data Capture CoP (Members)"); }
        if (this.DCap_Comms == true || this.DCap_Observer) { this.Membership += "Data-Capture-CoP-(Others) | "; this.MembershipArray.push("Data Capture CoP (Others)"); }

        if (this.BRepo_Primary == true || this.BRepo_Secondary == true) { this.Membership += "Biorepository-CoP-(Members) | "; this.MembershipArray.push("Biorepository CoP (Members)"); }
        if (this.BRepo_Comms == true || this.BRepo_Observer) { this.Membership += "Biorepository-CoP-(Others) | "; this.MembershipArray.push("Biorepository CoP (Others)"); }

        if (this.LAssay_Primary == true || this.LAssay_Secondary == true) { this.Membership += "Lab-Assays-CoP-(Members) | "; this.MembershipArray.push("Lab Assays CoP (Members)"); }
        if (this.LAssay_Comms == true || this.LAssay_Observer) { this.Membership += "Lab-Assays-CoP-(Others) | "; this.MembershipArray.push("Lab Assays CoP (Others)"); }

        if (this.ORes_Primary == true || this.ORes_Secondary == true) { this.Membership += "Outbreak-Research-Response-WG-(Members) | "; this.MembershipArray.push("Outbreak Research Response WG (Members)"); }
        if (this.ORes_Comms == true || this.ORes_Observer) { this.Membership += "Outbreak-Research-Response-WG-(Others) | "; this.MembershipArray.push("Outbreak Research Response WG (Others)"); }

        if (this.CBuild_Primary == true || this.CBuild_Secondary == true) { this.Membership += "Capacity-Building-WG-(Members) | "; this.MembershipArray.push("Capacity Building WG (Members)"); }
        if (this.CBuild_Comms == true || this.CBuild_Observer) { this.Membership += "Capacity-Building-WG-(Others) | "; this.MembershipArray.push("Capacity Building WG (Others)"); }

        if (this.RAD_Primary == true || this.RAD_Secondary == true) { this.Membership += "RAD-CoP-(Members) | "; this.MembershipArray.push("RAD CoP (Members)"); }
        if (this.RAD_Comms == true || this.RAD_Observer) { this.Membership += "RAD-CoP-(Others) | "; this.MembershipArray.push("RAD CoP (Others)"); }

        if (this.PM_Primary == true || this.PM_Secondary == true) { this.Membership += "Project-Management-CoP-(Members) | "; this.MembershipArray.push("Project Management CoP (Members)"); }
        if (this.PM_Comms == true || this.PM_Observer) { this.Membership += "Project-Management-CoP-(Others) | "; this.MembershipArray.push("Project Management CoP (Others)"); }

        if (this.PilotRP_Primary == true || this.PilotRP_Secondary == true) { this.Membership += "Pilot-Research-Program-(Members) | "; this.MembershipArray.push("Pilot Research Program (Members)"); }
        if (this.PilotRP_Comms == true || this.PilotRP_Observer) { this.Membership += "Pilot-Research-Program-(Others) | "; this.MembershipArray.push("Pilot Research Program (Others)"); }
    }

    setMembershipForGroupDirectory(primaryId: string, secondaryId: string, observerId: string, communicationsId: string): void {

        this.Membership = "";

        if (this[primaryId] == true) this.Membership += "Primary-Members ";
        if (this[secondaryId] == true) this.Membership += "Secondary-Members ";
        if (this[observerId] == true) this.Membership += "Observers ";
        if (this[communicationsId] == true) this.Membership += "Communications";
    }

    matchesFilter(networkList: any, workingGroup: any, countries: any, trainedAs: any, workAs: any, pathogens: any, otherPathogens: any, animalTypes: any, dataScience: any,
                    minion: any, rawShort: any, sequenceAnalysis: any, assayDev: any, containment: any, regulations: any): boolean {

        //console.log(trainedAs, this.Expertise?.TrainingDegrees);

        if (!DoArraysOverlap(networkList, this.NetworkArray)) { return false; }
        if (!countries.includes(this.Country)) { return false; }
        if (workingGroup.length > 0 && !DoArraysOverlap(workingGroup, this.MembershipArray)) { return false; }
        if (trainedAs.length > 0 && !DoArraysOverlap(trainedAs, this.Expertise?.TrainingDegrees)) { return false; }
        if (workAs.length > 0 && !DoArraysOverlap(workAs, this.Expertise?.TrainingExperience)) { return false; }
        if (pathogens.length > 0 && !DoArraysOverlap(pathogens, this.Expertise?.PathogenFamilies)) { return false; }
        if (otherPathogens.length > 0 && !DoArraysOverlap(otherPathogens, this.Expertise?.OtherPathogens)) { return false; }
        if (animalTypes.length > 0 && !DoArraysOverlap(animalTypes, this.Expertise?.StudyTypes)) { return false; }
        if (dataScience.length > 0 && !DoArraysOverlap(dataScience, this.Expertise?.DataScience)) { return false; }
        if (minion.length > 0 && !DoArraysOverlap(minion, this.Expertise?.MinionAnalysis)) { return false; }
        if (rawShort.length > 0 && !DoArraysOverlap(rawShort, this.Expertise?.RawShortReadAnalysis)) { return false; }
        if (sequenceAnalysis.length > 0 && !DoArraysOverlap(sequenceAnalysis, this.Expertise?.StatisticalSequenceAnalysis)) { return false; }
        if (assayDev.length > 0 && !DoArraysOverlap(assayDev, this.Expertise?.AssayDevelopment)) { return false; }
        if (containment.length > 0 && !DoArraysOverlap(containment, this.Expertise?.ContainmentLab)) { return false; }
        if (regulations.length > 0 && !DoArraysOverlap(regulations, this.Expertise?.UsRegulations)) { return false; }
       
        return true;
    }
}