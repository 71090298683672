import { BiodirectoryResearchSite, ResearchCenter, ResearchSite, Country, Pathogen, BiodirectoryReagent, BiodirectorySpecimen, BiodirectoryCombinedEntity, BiodirectoryContact, BiodirectorySourceType} from "./biodirectory-form.objects";
import { InitializeFilterDropdown } from '../../../../shared/scripts/dropdown-functions';
import { plainToClass } from "class-transformer";
import { Unique } from "../../../../shared/scripts/array-functions";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../../shared/scripts/table-column-visibility";
import { FormatDate2 } from './../../../../shared/scripts/date-functions';
import { ContactSelect } from "./contact-select";
import { BiodirectoryUpdateTool } from '../scripts/biodirectory-update-tool';

declare var $: any;
export class BiodirectoryFormPage {
    biodirectoryResearchSites: BiodirectoryCombinedEntity[];
    pathogenList: Pathogen[];
    currentBiodirectorySite: BiodirectoryCombinedEntity = null;
    userDirectory: BiodirectoryContact[];

    specimenContactSelect: ContactSelect;
    reagentContactSelect: ContactSelect;

    specimenUpdateTool: BiodirectoryUpdateTool;
    reagentUpdateTool: BiodirectoryUpdateTool;

    constructor(biodirectorySites: any, pathogenList: any, userDirectory: any) {
        $(() => {
            this.biodirectoryResearchSites = plainToClass(BiodirectoryCombinedEntity, <BiodirectoryCombinedEntity[]>biodirectorySites);

            this.biodirectoryResearchSites.forEach(site => {
                let siteSpecimens: BiodirectorySpecimen[] = [];
                site.BiodirectorySpecimens.forEach(specimen => {
                    let newSpecimen = BiodirectorySpecimen.createBiodirectorySpecimenFromObj(specimen);
                    siteSpecimens.push(newSpecimen);
                });
                site.BiodirectorySpecimens = siteSpecimens;

                let siteReagents: BiodirectoryReagent[] = [];
                site.BiodirectoryReagents.forEach(reagent => {
                    let newReagent = BiodirectoryReagent.createBiodirectoryReagentFromObj(reagent);
                    siteReagents.push(newReagent);
                });
                site.BiodirectoryReagents = siteReagents;
            })

            this.biodirectoryResearchSites.forEach(site => site.initialize());

            this.pathogenList = plainToClass(Pathogen, <Pathogen[]>pathogenList);
            this.pathogenList.forEach(pathogen => pathogen.initialize());

            this.userDirectory = plainToClass(BiodirectoryContact, <BiodirectoryContact[]>userDirectory);
            this.userDirectory.forEach(user => user.initialize());
            this.userDirectory.sort((x1, x2) => x1.LastName.localeCompare(x2.LastName));

            this.initializeResearchSitesList();
            this.initializePOCModal();
            this.initializeSpecimenModal();
            this.initializeReagentModal();

            // For Multiple Modals
            $('body').on('hidden.bs.modal', function () {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                }
            });
        });
    }

    initializeResearchSitesList(): void {

        let uniqueRCs: string[] = [];
        this.biodirectoryResearchSites.forEach(site => {
            if (!uniqueRCs.includes(site.ResearchCenter.Name)) uniqueRCs.push(site.ResearchCenter.Name);
        })
        uniqueRCs.sort();
        let rcList = "";

        for (let i = 0; i < uniqueRCs.length; i++) {
            let foundResearchSites = this.biodirectoryResearchSites.filter(site => site.ResearchCenter.Name == uniqueRCs[i]);
            if (foundResearchSites.length <= 0) continue;

            foundResearchSites.sort((x1, x2) => {
                return x1.ResearchSite.Name.localeCompare(x2.ResearchSite.Name);
            });

            let rcBlock = `<h5 class='mb-2 font-size20'>${uniqueRCs[i]}:  ${foundResearchSites.length}</h5>`;
            rcBlock += `<div class='accordion mb-3 creid-collapse-table' id='rc-${i}'>`

            let sitesBlock = "";

            for (let j = 0; j < foundResearchSites.length; j++) {

                let specimenContent = this.getSpecimenCardDisplay(foundResearchSites[j]);

                let reagentContent = this.getReagentCardDisplay(foundResearchSites[j]);

                let unreviewedIcon = `<div class="review-block-wrapper pl-3 pb-1 ${!foundResearchSites[j].BiodirectoryResearchSite.ShouldReview ? 'd-none' : 'd-inline-block'}"><span class="review-indicator">Needs Review <i class="fas fa-exclamation-circle pl-1 pt-2"></i></span></div>`;

                let unreviewedBanner = `
                    <div class='col-12 site-review-banner mb-4 ${!foundResearchSites[j].BiodirectoryResearchSite.ShouldReview ? 'd-none' : ''}'>
                        <div class="text-center"><i class="fas fa-exclamation-circle fa-lg creid-color-blue"></i></div>
                        <p class="mb-0">It has been more than 3 months since this site's BioDirectory information has been confirmed or updated. If there have been any changes to the site's collection of specimens or reagents, please make the appropriate adjustments using the 'Add/Edit' tools below. If there have not been any changes and the existing data are still accurate, please use the 'Confirm BioDirectory Info' button below to confirm this site's BioDirectory information and remove this warning.</p>                       
                    </div>
                `;


                let reviewBtn = `
                <div class="text-right my-2">
                    <button class="review-btn-standard btn btn-small creid-white-btn site-btn-width" onclick='page.openReviewModal(${foundResearchSites[j].Id})'>Confirm BioDirectory Info</button>
                </div>
                `;


                let card = `
                <div class="card">
                    <div class="card-header" id="rc-${i}-site-${j}">
                        <div class="row justify-content-between">
                            <div class="col">
                                <div class="header-text font-size16 font-weight-bold">
                                    <a class="collapsed text-decoration-none d-flex align-items-center" data-toggle="collapse" data-target="#rc-${i}-site-${j}-collapse" aria-expanded="false" aria-controls="rc-${i}-site-${j}-collapse">
                                        <i class="fa fa-plus-circle expand-btn" aria-hidden="true"></i>
                                        <span class="expand-text d-block pl-3">${foundResearchSites[j].ResearchSite.Name}</span>
                                        ${unreviewedIcon}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="rc-${i}-site-${j}-collapse" class="collapse" aria-labelledby="rc-${i}-site-${j}" data-parent="#rc-${i}">
                        <div class="card-body" id="site-${foundResearchSites[j].Id}">
                            ${unreviewedBanner}
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <div><span class='font-weight-bold'>Research Center</span>: ${foundResearchSites[j].ResearchCenter.Name}</div>
                                    <div><span class='font-weight-bold'>Country</span>: ${foundResearchSites[j].Country.Name} (${foundResearchSites[j].Country.Region})</div>
                                    <div><span class='font-weight-bold'>Specimens POC</span>: <span class='display-specimen-poc'>${foundResearchSites[j].BiodirectoryResearchSite.SpecimenPOCEmail != null ? foundResearchSites[j].BiodirectoryResearchSite.SpecimenPOCFirstName + " " + foundResearchSites[j].BiodirectoryResearchSite.SpecimenPOCLastName + " (" + foundResearchSites[j].BiodirectoryResearchSite.SpecimenPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>"}</span></div>
                                    <div><span class='font-weight-bold'>Reagents POC</span>: <span class='display-reagent-poc'>${foundResearchSites[j].BiodirectoryResearchSite.ReagentPOCEmail != null ? foundResearchSites[j].BiodirectoryResearchSite.ReagentPOCFirstName + " " + foundResearchSites[j].BiodirectoryResearchSite.ReagentPOCLastName + " (" + foundResearchSites[j].BiodirectoryResearchSite.ReagentPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>"}</span></div>
                                </div>
                                <div class="col-md-4 col-12 d-flex flex-column align-items-end">
                                    <div class='text-right'>
                                        <span class='font-weight-bold'><u>Last Updated/Reviewed</u>:</span><br/>
                                        <span class='display-last-updated'>${foundResearchSites[j].BiodirectoryResearchSite.MoreRecentDateDisplay}</span>
                                    </div>
                                    ${reviewBtn}
                                    <div class="text-right mt-auto">
                                        <button class='btn btn-small creid-blue-btn site-btn-width' onclick='page.openPOCmodal(${foundResearchSites[j].Id})'>Edit POC Information</button>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div class='col-12 px-0'>
                                <div class='row justify-content-between align-items-center mb-3 px-3'>
                                    <div class='biodirectory-secondary-heading'>
                                        Specimens
                                    </div>
                                    <div>
                                        <button class='btn btn-small creid-purple-btn site-btn-width' onclick='page.openSpecimenModal(${foundResearchSites[j].Id})'>Add/Edit Specimens</button>
                                    </div>
                                </div>
                                <div class='specimen-section'>
                                    ${specimenContent}
                                </div>

                            </div>

                            <hr/>

                            <div class='col-12 px-0'>
                                <div class='row justify-content-between align-items-center mb-3 px-3'>
                                    <div class='biodirectory-secondary-heading'>
                                        Reagents / Virus Isolates
                                    </div>
                                    <div>
                                        <button class='btn btn-small creid-purple-btn site-btn-width' onclick='page.openReagentModal(${foundResearchSites[j].Id})'>Add/Edit Reagents</button>
                                    </div>
                                </div>
                                <div class='reagent-section'>
                                    ${reagentContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `
                sitesBlock += card;
            }

            rcBlock += sitesBlock;
            rcBlock += `</div>`
            rcList += rcBlock;
        }

        $("#research-centers").html(rcList);

        // Custom functionality for show/hide items ($(this) = card-body)
        $("#research-centers").find(".collapse").on("show.bs.collapse", function () {
            $(this).siblings(".card-header").addClass("active");
        });

        $("#research-centers").find(".collapse").on("hide.bs.collapse", function () {
            $(this).siblings(".card-header").removeClass("active");
        });

        // Custom Collapse Toggle
        $("#research-centers").find(".specimen-section").find(".specimen-collapse").on('click', function () {
            let targetId = $(this).data("target");

            if ($(targetId).hasClass("show")) {
                $(targetId).removeClass("show");
                $(this).removeClass("active");
            }
            else {
                $(targetId).addClass("show");
                $(this).addClass("active");
            }
        });

        $("#research-centers").find(".reagent-section").find(".reagent-collapse").on('click', function () {
            let targetId = $(this).data("target");

            if ($(targetId).hasClass("show")) {
                $(targetId).removeClass("show");
                $(this).removeClass("active");
            }
            else {
                $(targetId).addClass("show");
                $(this).addClass("active");
            }
        })

    }

    initializePOCModal(): void {
        this.specimenContactSelect = new ContactSelect("#specimen-poc-search", this.userDirectory);
        this.reagentContactSelect = new ContactSelect("#reagent-poc-search", this.userDirectory);
    }

    initializeSpecimenModal(): void {
        let specimenSources: string[] = ["Human", "Animal", "Arthropod", "Environmental"];
        let specimenTypes: string[][] = [
            ["Whole Blood", "Isolated Cells", "Serum/Plasma", "Host Swabs", "Nucleic Acids", "Tissue Specimens/Extracts", "Feces", "Other Specimens"],
            ["Whole Blood", "Isolated Cells", "Serum/Plasma", "Host Swabs", "Nucleic Acids", "Tissue Specimens/Extracts", "Feces", "Other Specimens"],
            ["Nucleic Acids", "Tissue Specimens/Extracts", "Other Specimens"],
            ["Nucleic Acids", "Air Samples", "Environmental Swabs/Solids", "Water Samples", "Other Specimens"]
        ]

        let specimenSourceTypes: BiodirectorySourceType[] = [];
        let sourceId = 0;
        for (let i = 0; i < specimenSources.length; i++) {
            let sourceName: string = specimenSources[i];
            let types: string[] = specimenTypes[i];

            for (let j = 0; j < types.length; j++) {
                specimenSourceTypes.push(new BiodirectorySourceType(sourceName, types[j], sourceId));
                sourceId++;
            }
        }

        this.specimenUpdateTool = new BiodirectoryUpdateTool("specimen","#specimen-source-select", "#specimen-pathogen-search", "#specimen-pathogen-family-select", "#specimen-pathogen-name-select", "#specimen-pathogen-table", "#specimen-mapping-table", specimenSourceTypes, this.pathogenList);
    }

    initializeReagentModal(): void {
        let reagentTypes: string[] = ["Monoclonal Antibody", "Polyclonal Antibody/Sera", "Recombinant Protein", "Cell Line", "Virus Isolate"];
        let reagentSources: string[][] = [
            ["Human Derived", "Animal Derived"],
            ["Human Derived", "Animal Derived"],
            ["Human Derived", "Animal Derived", "Virus Derived"],
            ["Human Derived", "Animal Derived", "Arthropod Derived"],
            ["Natural Derived", "Synthetic"],
        ]

        let reagentSourceTypes: BiodirectorySourceType[] = [];
        let sourceId = 0;
        for (let i = 0; i < reagentTypes.length; i++) {
            let typeName: string = reagentTypes[i];
            let sources: string[] = reagentSources[i];

            for (let j = 0; j < sources.length; j++) {
                reagentSourceTypes.push(new BiodirectorySourceType(typeName, sources[j], sourceId));
                sourceId++;
            }
        }

        this.reagentUpdateTool = new BiodirectoryUpdateTool("reagent", "#reagent-source-select", "#reagent-pathogen-search", "#reagent-pathogen-family-select", "#reagent-pathogen-name-select", "#reagent-pathogen-table", "#reagent-mapping-table", reagentSourceTypes, this.pathogenList);
    }

    openPOCmodal(siteId: number): void {
        // Scroll to Top
        $("#pocModal").find(".modal-body").scrollTop(0);

        // Clear Existing Data
        this.specimenContactSelect.resetValues();

        // Get Research Site
        let site: BiodirectoryCombinedEntity = this.biodirectoryResearchSites.find(entity => entity.Id == siteId);

        if (site == null || site == undefined) return;

        this.currentBiodirectorySite = site;

        $("#current-specimen-poc").html(site.BiodirectoryResearchSite.SpecimenPOCEmail != null ? site.BiodirectoryResearchSite.SpecimenPOCFirstName + " " + site.BiodirectoryResearchSite.SpecimenPOCLastName + " (" + site.BiodirectoryResearchSite.SpecimenPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>");
        $("#current-reagent-poc").html(site.BiodirectoryResearchSite.ReagentPOCEmail != null ? site.BiodirectoryResearchSite.ReagentPOCFirstName + " " + site.BiodirectoryResearchSite.ReagentPOCLastName + " (" + site.BiodirectoryResearchSite.ReagentPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>")

        $("#pocModal").modal('show');

        setTimeout(() => {
            this.specimenContactSelect.resetSearchBar();
            // Prepopulate Fields
            if (site.BiodirectoryResearchSite.SpecimenPOCEmail != null && site.BiodirectoryResearchSite.SpecimenPOCEmail != "") {
                let specimenContact = new BiodirectoryContact(site.BiodirectoryResearchSite.SpecimenPOCFirstName, site.BiodirectoryResearchSite.SpecimenPOCLastName, site.BiodirectoryResearchSite.SpecimenPOCEmail)
                this.specimenContactSelect.setValues(specimenContact);
            }

            this.reagentContactSelect.resetSearchBar();
            // Prepopulate Fields
            if (site.BiodirectoryResearchSite.ReagentPOCEmail != null && site.BiodirectoryResearchSite.ReagentPOCEmail != "") {
                let reagentContact = new BiodirectoryContact(site.BiodirectoryResearchSite.ReagentPOCFirstName, site.BiodirectoryResearchSite.ReagentPOCLastName, site.BiodirectoryResearchSite.ReagentPOCEmail)
                this.reagentContactSelect.setValues(reagentContact);
            }

            $("#pocModal").find(".modal-body").animate({ scrollTop: 0 }, 'fast');
        }, 250);

    }

    openSpecimenModal(siteId: number): void {
        $("#specimenModal").find(".modal-body").scrollTop(0);
        this.specimenUpdateTool.resetAll();
        let foundBiodirectorySite = this.biodirectoryResearchSites.find(site => site.Id == siteId);
        if (foundBiodirectorySite != undefined) {
            foundBiodirectorySite.initialize();
            this.specimenUpdateTool.prepopulateBiodirectoryInformation(foundBiodirectorySite);
        }
        $("#specimenModal").modal('show');

        setTimeout(() => {
            $("#specimenModal").find(".modal-body").animate({ scrollTop: 0 }, 'fast');
        }, 250)
    }

    openReagentModal(siteId: number): void {
        this.reagentUpdateTool.resetAll();
        let foundBiodirectorySite = this.biodirectoryResearchSites.find(site => site.Id == siteId);
        if (foundBiodirectorySite != undefined) {
            foundBiodirectorySite.initialize();
            this.reagentUpdateTool.prepopulateBiodirectoryInformation(foundBiodirectorySite);
        }
        $("#reagentModal").modal('show');

        setTimeout(() => {
            $("#reagentModal").find(".modal-body").animate({ scrollTop: 0 }, 'fast');
        }, 250)
    }

    openReviewModal(siteId: number): void {
        let foundBioDirectorySite = this.biodirectoryResearchSites.find(site => site.Id == siteId);
        if (foundBioDirectorySite != undefined) {
            foundBioDirectorySite.initialize();
            $("#reviewInfoModal").find(".review-site-name").text(foundBioDirectorySite.ResearchSite.Name);
            $("#reviewInfoModal").find(".review-center-name").text(foundBioDirectorySite.ResearchCenter.Name);
            $("#reviewInfoModal").find(".review-country-name").text(foundBioDirectorySite.Country.Name);
            $("#reviewInfoModal").find(".review-region-name").text(foundBioDirectorySite.Country.Region);
            $("#reviewInfoModal").find(".review-last-reviewed").text(foundBioDirectorySite.BiodirectoryResearchSite.MoreRecentDateDisplay);
            this.currentBiodirectorySite = foundBioDirectorySite;
        }
        $("#reviewInfoModal").modal('show');
    }

    saveSpecimenPoc(): void {

        // Validate Form
        if (!this.specimenContactSelect.contactValidation()) {
            return;
        }

        // Valid Form
        let specimenContact: BiodirectoryContact = this.specimenContactSelect.getAddedContact();

        let updatedSite = new BiodirectoryResearchSite(this.currentBiodirectorySite.BiodirectoryResearchSite);
        updatedSite.SpecimenPOCFirstName = specimenContact.FirstName;
        updatedSite.SpecimenPOCLastName = specimenContact.LastName;
        updatedSite.SpecimenPOCEmail = specimenContact.Email;
        updatedSite.LastModifiedDate = new Date();

        // Show Loading
        $("#current-specimen-poc").html(`<i class="fa-solid fa-spinner spin"></i></span>`);

        // Function to run after database is updated
        let onSuccess = (response: any) => {
            // Response is the updated Biodirectory Object
            let updatedSite: BiodirectoryResearchSite = response;

            // Update array of Sites
            let foundIndex: number = this.biodirectoryResearchSites.findIndex(entry => entry.Id == updatedSite.Id);
            if (foundIndex >= 0) {

                let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
                updatedEntity.BiodirectoryResearchSite = updatedSite;
                updatedEntity.initialize();

                this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);

                // Update Research Site List
                this.updateBiodirectorySiteDisplay(updatedEntity)
            }

            // Update the POC Modal with new information
            $("#current-specimen-poc").html(updatedSite.SpecimenPOCEmail != null ? updatedSite.SpecimenPOCFirstName + " " + updatedSite.SpecimenPOCLastName + " (" + updatedSite.SpecimenPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>")


        }

        let onError = (response: any) => {
            console.log(response);
            alert("An unexpected error occurred. Please try again.");
        }

        // Update Database
        this.updateBiodirectorySite(updatedSite, onSuccess, onError)

    }

    saveReagentPoc(): void {

        // Validate Form
        if (!this.reagentContactSelect.contactValidation()) {
            return;
        }

        // Valid Form
        let reagentContact: BiodirectoryContact = this.reagentContactSelect.getAddedContact();

        let updatedSite = new BiodirectoryResearchSite(this.currentBiodirectorySite.BiodirectoryResearchSite);
        updatedSite.ReagentPOCFirstName = reagentContact.FirstName;
        updatedSite.ReagentPOCLastName = reagentContact.LastName;
        updatedSite.ReagentPOCEmail = reagentContact.Email;
        updatedSite.LastModifiedDate = new Date();

        // Show Loading
        $("#current-reagent-poc").html(`<i class="fa-solid fa-spinner spin"></i></span>`);

        // Function to run after database is updated
        let onSuccess = (response: any) => {
            // Response is the updated Biodirectory Object
            let updatedSite: BiodirectoryResearchSite = response;

            // Update array of Sites
            let foundIndex: number = this.biodirectoryResearchSites.findIndex(entry => entry.Id == updatedSite.Id);
            if (foundIndex >= 0) {

                let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
                updatedEntity.BiodirectoryResearchSite = updatedSite;
                updatedEntity.initialize();

                this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);

                // Update Research Site List
                this.updateBiodirectorySiteDisplay(updatedEntity)
            }

            // Update the POC Modal with new information           
            $("#current-reagent-poc").html(updatedSite.ReagentPOCEmail != null ? updatedSite.ReagentPOCFirstName + " " + updatedSite.ReagentPOCLastName + " (" + updatedSite.ReagentPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>")

        }

        let onError = (response: any) => {
            console.log(response);
            alert("An unexpected error occurred. Please try again.");
        }

        // Update Database
        this.updateBiodirectorySite(updatedSite, onSuccess, onError)

    }

    savePocInfo(): void {
        // Check if we need to save specimen/reagent Poc
        let saveSpecimens: boolean = false;
        if (this.specimenContactSelect.checkIfData()) {
            if (!this.specimenContactSelect.contactValidation()) {
                return;
            }
            saveSpecimens = true;
        }

        let saveReagents: boolean = false;
        if (this.reagentContactSelect.checkIfData()) {
            if (!this.reagentContactSelect.contactValidation()) {
                return;
            }
            saveReagents = true;
        }

        // Need to save information
        if (saveSpecimens || saveReagents) {
            let updatedSite = new BiodirectoryResearchSite(this.currentBiodirectorySite.BiodirectoryResearchSite);
            updatedSite.LastModifiedDate = new Date();

            if (saveSpecimens) {
                let specimenContact: BiodirectoryContact = this.specimenContactSelect.getAddedContact();
                updatedSite.SpecimenPOCFirstName = specimenContact.FirstName;
                updatedSite.SpecimenPOCLastName = specimenContact.LastName;
                updatedSite.SpecimenPOCEmail = specimenContact.Email;
                $("#current-specimen-poc").html(`<i class="fa-solid fa-spinner spin"></i></span>`);
            }

            if (saveReagents) {
                let reagentContact: BiodirectoryContact = this.reagentContactSelect.getAddedContact();
                updatedSite.ReagentPOCFirstName = reagentContact.FirstName;
                updatedSite.ReagentPOCLastName = reagentContact.LastName;
                updatedSite.ReagentPOCEmail = reagentContact.Email;
                $("#current-reagent-poc").html(`<i class="fa-solid fa-spinner spin"></i></span>`);
            }

            let onSuccess = (response: any) => {
                // Response is the updated Biodirectory Object
                let updatedSite: BiodirectoryResearchSite = response;

                // Update array of Sites
                let foundIndex: number = this.biodirectoryResearchSites.findIndex(entry => entry.Id == updatedSite.Id);
                if (foundIndex >= 0) {

                    let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
                    updatedEntity.BiodirectoryResearchSite = updatedSite;
                    updatedEntity.initialize();

                    this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);

                    // Update Research Site List
                    this.updateBiodirectorySiteDisplay(updatedEntity)
                }

                // Update the POC Modal with new information
                $("#current-reagent-poc").html(updatedSite.ReagentPOCEmail != null ? updatedSite.ReagentPOCFirstName + " " + updatedSite.ReagentPOCLastName + " (" + updatedSite.ReagentPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>")
                $("#current-specimen-poc").html(updatedSite.SpecimenPOCEmail != null ? updatedSite.SpecimenPOCFirstName + " " + updatedSite.SpecimenPOCLastName + " (" + updatedSite.SpecimenPOCEmail + ")" : "<span class='error-msg'>Not Specified</span>")

            }

            let onError = (response: any) => {
                console.log(response);
                alert("An unexpected error occurred. Please try again.");
            }

            // Update Database
            this.updateBiodirectorySite(updatedSite, onSuccess, onError)
        }

        // Close Modal
        $("#pocModal").modal('hide');

    }

    saveReviewModal(): void {
        let updatedSite = new BiodirectoryResearchSite(this.currentBiodirectorySite.BiodirectoryResearchSite);
        updatedSite.LastReviewDate = new Date();

        let onSuccess = (response: any) => {
            // Response is the updated Biodirectory Object
            let updatedSite: BiodirectoryResearchSite = response;

            // Update array of Sites
            let foundIndex: number = this.biodirectoryResearchSites.findIndex(entry => entry.Id == updatedSite.Id);
            if (foundIndex >= 0) {

                let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
                updatedEntity.BiodirectoryResearchSite = updatedSite;
                updatedEntity.initialize();

                this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);

                // Update Research Site List
                this.updateBiodirectorySiteDisplay(updatedEntity)
            }
        }

        let onError = (response: any) => {
            console.log(response);
            alert("An unexpected error occurred. Please try again.");
        }

        // Update Database
        this.updateBiodirectorySite(updatedSite, onSuccess, onError);

        // Close Modal
        $("#reviewInfoModal").modal('hide');
    }

    saveAndCloseSpecimenModal(): void {
        this.saveSpecimenInformation();
        $("#specimenModal").modal('hide');
    }

    saveAndCloseReagentModal(): void {
        this.saveReagentInformation();
        $("#reagentModal").modal('hide');
    }

    saveSpecimenInformation(): void {
        // get the modified site information
        let targetBiodirectorySite = this.specimenUpdateTool.getTargetBiodirectorySite();
        // save information to database
        this.specimenUpdateTool.saveSiteAndSpecimens();

        // update listing of sites
        let foundIndex = this.biodirectoryResearchSites.findIndex(site => site.Id == targetBiodirectorySite.Id);
        if (foundIndex >= 0){
            let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
            updatedEntity.initialize();

            this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);
        }    

        // Update Research Site List
        targetBiodirectorySite.initialize();
        this.updateBiodirectorySiteDisplay(targetBiodirectorySite);
    }

    saveReagentInformation(): void {
        // get the modified site information
        let targetBiodirectorySite = this.reagentUpdateTool.getTargetBiodirectorySite();
        // save information to database
        this.reagentUpdateTool.saveSiteAndReagents();

        // update listing of sites
        let foundIndex = this.biodirectoryResearchSites.findIndex(site => site.Id == targetBiodirectorySite.Id);
        if (foundIndex >= 0) {
            let updatedEntity: BiodirectoryCombinedEntity = BiodirectoryCombinedEntity.createBiodirectoryCombinedEntity(this.biodirectoryResearchSites[foundIndex]);
            updatedEntity.initialize();

            this.biodirectoryResearchSites.splice(foundIndex, 1, updatedEntity);
        }

        // Update Research Site List
        targetBiodirectorySite.initialize();
        this.updateBiodirectorySiteDisplay(targetBiodirectorySite);
    }

    updateBiodirectorySite(siteInfo: BiodirectoryResearchSite, onSuccessFn: Function = (response: any) => { }, onErrorFn: Function = (response: any) => { }): void {
        $.ajax({
            url: '/secure/biodirectory/biodirectory-form/biodirectory-form?handler=SaveBiodirectoryInformation',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(siteInfo)},
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {     
                onSuccessFn(response);
            },
            error: (response) => {
                onErrorFn(response);
            }
        });
    }

    updateBiodirectorySiteDisplay(site: BiodirectoryCombinedEntity): void {
        // get block id 
        let blockId: string = `#site-${site.Id}`;

        // Last Updated
        $(blockId).find(".display-last-updated").text(site.BiodirectoryResearchSite.MoreRecentDateDisplay);

        // Update POC Information
        if (site.BiodirectoryResearchSite.SpecimenPOCEmail != null) {
            $(blockId).find(".display-specimen-poc").text(`${site.BiodirectoryResearchSite.SpecimenPOCFirstName + " " + site.BiodirectoryResearchSite.SpecimenPOCLastName + " (" + site.BiodirectoryResearchSite.SpecimenPOCEmail + ")"}`);
        }
        if (site.BiodirectoryResearchSite.ReagentPOCEmail != null) {
            $(blockId).find(".display-reagent-poc").text(`${site.BiodirectoryResearchSite.ReagentPOCFirstName + " " + site.BiodirectoryResearchSite.ReagentPOCLastName + " (" + site.BiodirectoryResearchSite.ReagentPOCEmail + ")"}`)
        }

        // Update Specimen Information
        this.displaySiteSpecimens(site);

        // Update Reagent Information
        this.displaySiteReagents(site);

        // Update Reivew Display
        this.displayReviewInformation(site);

        // Set current Research Site
        this.currentBiodirectorySite = site;
    }

    displaySiteSpecimens(site: BiodirectoryCombinedEntity): void {
        let blockId: string = `#site-${site.Id}`;

        $(blockId).find(".specimen-section").empty();

        let specimenContent = this.getSpecimenCardDisplay(site);

        $(blockId).find(".specimen-section").html(specimenContent);

        // Custom Collapse Toggle
        $(blockId).find(".specimen-section").find(".specimen-collapse").on('click', function() {
            let targetId = $(this).data("target");

            if ($(targetId).hasClass("show")) {
                $(targetId).removeClass("show");
                $(this).removeClass("active");
            }
            else {
                $(targetId).addClass("show");
                $(this).addClass("active");
            }
        })
    }

    displaySiteReagents(site: BiodirectoryCombinedEntity): void {
        let blockId: string = `#site-${site.Id}`;

        $(blockId).find(".reagent-section").empty();

        let reagentContent = this.getReagentCardDisplay(site);

        $(blockId).find(".reagent-section").html(reagentContent);

        // Custom Collapse Toggle
        $(blockId).find(".reagent-section").find(".reagent-collapse").on('click', function () {
            let targetId = $(this).data("target");

            if ($(targetId).hasClass("show")) {
                $(targetId).removeClass("show");
                $(this).removeClass("active");
            }
            else {
                $(targetId).addClass("show");
                $(this).addClass("active");
            }
        })
    }

    displayReviewInformation(site: BiodirectoryCombinedEntity): void {
        let blockId: string = `#site-${site.Id}`;
        let parentBlock = $(blockId).closest(".card");

        // Show/Hide the Review Options
        if (site.BiodirectoryResearchSite.ShouldReview) {

            $(parentBlock).find(".review-block-wrapper").removeClass("d-none").addClass("d-inline-block");
            $(blockId).find(".site-review-banner").removeClass("d-none");
        }
        else {
            $(parentBlock).find(".review-block-wrapper").addClass("d-none").removeClass("d-inline-block");
            $(blockId).find(".site-review-banner").addClass("d-none");
        }
    }

    getSpecimenCardDisplay(site: BiodirectoryCombinedEntity): string {
        let specimenContent = ""

        if (site.BiodirectorySpecimens.length == 0) {
            specimenContent = `
                <div class='row'>
                    <p class="px-3">There are currently no specimens associated with this research site. Use the 'Add/Edit Specimens' button to add specimens.</p>
                </div>`
        }
        else {
            specimenContent = `<div class='row' id='specimen-card-collection-${site.Id}'>`
            site.BiodirectorySpecimens.sort((x1, x2) => x1.SpecimenSource.localeCompare(x2.SpecimenSource));

            for (let i = 0; i < site.BiodirectorySpecimens.length; i++) {
                let specimen = site.BiodirectorySpecimens[i];

                let pathogenContent = "";

                
                if (site.BiodirectorySpecimens[i].PathogenIds == "" || site.BiodirectorySpecimens[i].PathogenIds == undefined) {
                    pathogenContent = `<div class='font-italic font-size12'>No Associated Pathogens</div>`;
                }
                else {
                    let pathogenIds: string[] = site.BiodirectorySpecimens[i].PathogenIds.split("|");
                    let foundPathogens: Pathogen[] = [];
                    pathogenContent = `<ul class="ml-3 mb-0">`
                    pathogenIds.forEach(id => {
                        let foundPathogen: Pathogen = this.pathogenList.find(pathogen => pathogen.PathogenId.toString() == id);
                        if (foundPathogen != undefined) {
                            foundPathogens.push(foundPathogen);
                        }
                    })
                    foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));
                    foundPathogens.forEach(pathogen => {
                        pathogenContent += `<li class='font-size12'>${pathogen.VirusName}</li>`
                    })
                    pathogenContent += `</ul>`
                }
                


                let cardContent = `
                <div class="card source-type-card mb-3">
                    <div class="card-header collapsed specimen-collapse" data-target="#site-specimen-${specimen.BiodirectorySiteId}-${i}">
                        <div class="source-header d-flex">
                            <div class="expand-section pr-2">
                                <i class="fa fa-plus-circle fa-lg source-expand-icon" aria-hidden="true"></i>
                            </div>
                            <div class="source-type-section">
                                <span class='font-weight-bold'>${specimen.SpecimenSource}</span><br/>
                                <span class='font-italic'>${specimen.SpecimenType}</span>
                            </div>
                        </div>
                    </div>
                    <div id="site-specimen-${specimen.BiodirectorySiteId}-${i}" class="collapse" data-parent="#specimen-card-collection-${site.Id}">
                        <div class="card-body">
                            <span><u>Pathogens</u></span>                        
                            ${pathogenContent}
                        </div>
                    </div>
                </div>`

                let cardContainer = `
                <div class="col-md-3 col-12">
                    ${cardContent}
                </div>
                `

                specimenContent += cardContainer;
            }

            specimenContent += `</div>`

        }

        return specimenContent
    }

    getReagentCardDisplay(site: BiodirectoryCombinedEntity): string {
        let reagentContent = ""

        if (site.BiodirectoryReagents.length == 0) {
            reagentContent = `
                <div class='row'>
                    <p class="px-3">There are currently no reagents associated with this research site. Use the 'Add/Edit Reagents' button to add reagents.</p>
                </div>`
        }
        else {
            reagentContent = `<div class='row' id='reagent-card-collection-${site.Id}'>`
            site.BiodirectoryReagents.sort((x1, x2) => x1.ReagentSource.localeCompare(x2.ReagentSource));

            for (let i = 0; i < site.BiodirectoryReagents.length; i++) {
                let reagent = site.BiodirectoryReagents[i];

                let pathogenContent = "";


                if (site.BiodirectoryReagents[i].PathogenIds == "" || site.BiodirectoryReagents[i].PathogenIds == undefined) {
                    pathogenContent = `<div class='font-italic font-size12'>No Associated Pathogens</div>`;
                }
                else {
                    let pathogenIds: string[] = site.BiodirectoryReagents[i].PathogenIds.split("|");
                    let foundPathogens: Pathogen[] = [];
                    pathogenContent = `<ul class="ml-3 mb-0">`
                    pathogenIds.forEach(id => {
                        let foundPathogen: Pathogen = this.pathogenList.find(pathogen => pathogen.PathogenId.toString() == id);
                        if (foundPathogen != undefined) {
                            foundPathogens.push(foundPathogen);
                        }
                    });
                    foundPathogens.sort((x1, x2) => x1.VirusName.localeCompare(x2.VirusName));
                    foundPathogens.forEach(pathogen => {
                        pathogenContent += `<li class='font-size12'>${pathogen.VirusName}</li>`
                    })
                    pathogenContent += `</ul>`
                }



                let cardContent = `
                <div class="card source-type-card mb-3">
                    <div class="card-header collapsed reagent-collapse" data-target="#site-reagent-${reagent.BiodirectorySiteId}-${i}">
                        <div class="source-header d-flex">
                            <div class="expand-section pr-2">
                                <i class="fa fa-plus-circle fa-lg source-expand-icon" aria-hidden="true"></i>
                            </div>
                            <div class="source-type-section">
                                <span class='font-weight-bold'>${reagent.ReagentSource}</span><br/>
                                <span class='font-italic'>${reagent.ReagentType}</span>
                            </div>
                        </div>
                    </div>
                    <div id="site-reagent-${reagent.BiodirectorySiteId}-${i}" class="collapse" data-parent="#reagent-card-collection-${site.Id}">
                        <div class="card-body">
                            <span><u>Pathogens</u></span>                        
                            ${pathogenContent}
                        </div>
                    </div>
                </div>`

                let cardContainer = `
                <div class="col-md-3 col-12">
                    ${cardContent}
                </div>
                `

                reagentContent += cardContainer;
            }

            reagentContent += `</div>`

        }

        return reagentContent
    }
    
}