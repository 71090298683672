import { DocumentTree } from "../../shared/components/document-tree/document-tree";


export class OutbreakResponseResourcesPage {

    //cdeDocumentTree: DocumentTree;

    constructor(resources: any, pathogens: any, roadmaps: any, mappings: any) {
        $(() => {

            //console.log(resources, pathogens, roadmaps);

            new DocumentTree(resources, "resources-tree");
            new DocumentTree(pathogens, "pathogens-tree");
            new DocumentTree(roadmaps, "roadmaps-tree");
            new DocumentTree(mappings, "mappings-tree");

        });
    }

}