


//to make discoverable from cshtml
import { CommitteePage } from './committees/committees'; export { CommitteePage };
import { DirectoryPage } from './communications/directory/directory.page'; export { DirectoryPage };
import { LabAssaysPage } from './inventory/lab-assays/lab-assays.page'; export { LabAssaysPage };
import { SiteCapacitiesPage } from './inventory/site-capacities/scripts/site-capacities.page'; export { SiteCapacitiesPage };
import { EventsPage } from './communications/events/events.page'; export { EventsPage };
import { OutbreakResponseResourcesPage } from './outbreak-response/resources.page'; export { OutbreakResponseResourcesPage };
import { StakeholdersPage } from './communications/stakeholders/stakeholders.page'; export { StakeholdersPage };
import { ResourceLibraryPage } from './resource-library/resource-library.page'; export { ResourceLibraryPage };
import { OrrTrackerPage } from './orr-tracker/scripts/orr-tracker.page'; export { OrrTrackerPage };
import { BiodirectoryFormPage } from './biodirectory/biodirectory-form/scripts/biodirectory-form.page'; export { BiodirectoryFormPage };
import { BiodirectoryDashboardPage } from './biodirectory/biodirectory-dashboard/scripts/biodirectory-dashboard.page'; export { BiodirectoryDashboardPage };
import { DataHubPage } from './dataHub/dataHub.page'; export { DataHubPage }


// STYLES
import './communications/directory/directory.scss';
import './inventory/lab-assays/lab-assays.scss';
import './inventory/site-capacities/site-capacities.scss';
import './outbreak-response/resources.scss';
import './communications/stakeholders/stakeholders.scss';
import './resource-library/resource-library.scss';
import './orr-tracker/orr-tracker.scss'
import './biodirectory/biodirectory-form/biodirectory-form.scss';
import './biodirectory/biodirectory-dashboard/biodirectory-dashboard.scss';
import './dataHub/dataHub.scss';

